// https://digitaldesign.vattenfall.com/designers/components/selection/buttons

import classNames from 'classnames';
import { forwardRef } from 'react';

import { css } from '../stitches.config';
import { HTMLTag } from '../types';
import { Box, BoxProps } from './Box';

// TODO replace all colors with tokens

export type ButtonProps<C extends HTMLTag = 'button'> = {
  arrow?: boolean;
  size?: 'md' | 'lg';
  variant?: 'primary' | 'secondary' | 'tertiaryBlack' | 'tertiaryBlue';
} & BoxProps<C>;

export const Button = forwardRef<HTMLElement, ButtonProps<HTMLTag>>(function Button(
  { arrow = true, as = 'button', className, size = 'md', variant = 'primary', ...props },
  ref,
) {
  return (
    <Box
      as={as}
      className={classNames(
        css({
          borderColor: 'transparent',
          borderStyle: 'solid',
          borderWidth: '2px',
          color: '#222222',
          fontFamily: '$default',
          fontWeight: '500',
          paddingX: '48px',
          '&:disabled': {
            backgroundColor: '#eee',
            color: '$tertiaryMediumDarkGray',
          },
          '&:not(:disabled)': {
            cursor: 'pointer',
          },
          variants: {
            arrow: {
              true: {
                '&:not(:disabled)::before': {
                  content: '→',
                  opacity: '0',
                  fontSize: '1.5em',
                  fontWeight: 'normal',
                  position: 'absolute',
                  transform: 'translate(-220%, 0)',
                  transformOrigin: 'top left',
                  transitionProperty: 'opacity, transform, -webkit-transform',
                  '-webkit-transition-duration': '300ms',
                  transitionDuration: '300ms',
                },
                '&:not(:disabled):hover::before': {
                  opacity: '1',
                  transitionDelay: '150ms',
                  transform: 'translate(-150%, 0)',
                },
              },
            },
            size: {
              md: {
                borderRadius: '20px',
                fontSize: '$sm',
                lineHeight: '18px',
                minWidth: '100px',
                paddingY: '9px',
              },
              lg: {
                borderRadius: '26px',
                fontSize: '$lg',
                lineHeight: '24px',
                minWidth: '240px',
                paddingY: '12px',
              },
            },
            variant: {
              primary: {
                backgroundColor: '$primary',
                '&:hover:enabled:not(:active)': {
                  backgroundColor: '#ffe85b',
                },
                '&:active': {
                  backgroundColor: '#f1c50b',
                },
              },
              secondary: {
                backgroundColor: '#1964a3',
                color: '$primaryAuraWhite',
                '&:hover:enabled:not(:active)': {
                  backgroundColor: '$secondary',
                },
                '&:active': {
                  backgroundColor: '$secondaryDarkBlue',
                },
              },
              tertiaryBlack: {
                backgroundColor: 'transparent',
                '&:enabled:not(:active)': {
                  borderColor: '#222222',
                },
                '&:hover:enabled:not(:active)': {
                  backgroundColor: '#222222',
                  color: '$primaryAuraWhite',
                },
                '&:active': {
                  backgroundColor: '$primaryMagneticGray',
                  color: '$primaryAuraWhite',
                },
              },
              tertiaryBlue: {
                backgroundColor: 'transparent',
                '&:enabled:not(:active)': {
                  borderColor: '#1964a3',
                },
                '&:hover:enabled:not(:active)': {
                  backgroundColor: '#1964a3',
                  color: '$primaryAuraWhite',
                },
                '&:active': {
                  backgroundColor: '$secondaryDarkBlue',
                  color: '$primaryAuraWhite',
                },
              },
            },
          },
        })({ arrow, size, variant }),
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
