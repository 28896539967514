import { useEffect, useState } from 'react';

export function useDebounce<T>(value: T, delayMs: number) {
  const [debounce, setDebounce] = useState({ value, isDebouncing: false });

  useEffect(() => {
    setDebounce({ value, isDebouncing: true });
    const handler = setTimeout(() => {
      setDebounce({ value, isDebouncing: false });
    }, delayMs);

    return () => {
      setDebounce({ value, isDebouncing: false });
      clearTimeout(handler);
    };
  }, [value, delayMs]);

  return debounce;
}
