import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

import { config } from './config';

export const history = createBrowserHistory();
export const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: config.INSIGHTS,
    extensions: [reactPlugin as unknown as ITelemetryPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
  },
});

config.INSIGHTS && appInsights.loadAppInsights();
