import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { Park } from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

interface ExtraParameters {
  contractId: string;
}

export const parksApi = createApi({
  reducerPath: 'parks',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
  }),
  tagTypes: ['Parks'],
  endpoints: (builder) => ({
    upsert: builder.mutation<Park & ExtraParameters, Park>({
      query: (payload) => ({
        url: '/api/parks',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Parks'],
    }),
    getAll: builder.query<Park[], void>({
      query: () => '/api/parks',
      providesTags: ['Parks'],
    }),
    getById: builder.query<Park, Park['id']>({
      query: (id) => `/api/parks/${id}`,
      providesTags: ['Parks'],
    }),
    remove: builder.mutation<Park[], Park['id']>({
      query: (id) => ({
        url: `/api/parks/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Parks'],
    }),
  }),
});

export const {
  endpoints,
  middleware,
  reducer,
  reducerPath,
  useUpsertMutation,
  useRemoveMutation,
  useGetAllQuery,
  useGetByIdQuery,
} = parksApi;
