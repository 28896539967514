import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BalanceAreaListModel,
  BalanceGroupListModel,
  BTRListModel,
  DSOListModel,
  EIVListModel,
  ListModel,
  ListModelType,
  TSOListModel,
  WLPartnerListModel,
} from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

const BASE_PATH = '/api/listsData';

export const listsDataApi = createApi({
  reducerPath: 'listsData',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
    credentials: 'include',
  }),
  tagTypes: ['ListsData'],
  endpoints: (builder) => ({
    getBalanceAreas: builder.query<BalanceAreaListModel[], void>({
      query: () => `${BASE_PATH}?type=${ListModelType.enum.BALANCE_AREA}`,
      providesTags: ['ListsData'],
    }),
    getBalanceGroups: builder.query<BalanceGroupListModel[], void>({
      query: () => `${BASE_PATH}?type=${ListModelType.enum.BALANCE_GROUP}`,
      providesTags: ['ListsData'],
    }),
    getBTRs: builder.query<BTRListModel[], void>({
      query: () => `${BASE_PATH}?type=${ListModelType.enum.BTR}`,
      providesTags: ['ListsData'],
    }),
    getTSOs: builder.query<TSOListModel[], void>({
      query: () => `${BASE_PATH}?type=${ListModelType.enum.TSO}`,
      providesTags: ['ListsData'],
    }),
    getDSOs: builder.query<DSOListModel[], void>({
      query: () => `${BASE_PATH}?type=${ListModelType.enum.DSO}`,
      providesTags: ['ListsData'],
    }),
    getWLPartners: builder.query<WLPartnerListModel[], void>({
      query: () => `${BASE_PATH}?type=${ListModelType.enum.WL_PARTNER}`,
      providesTags: ['ListsData'],
    }),
    getEIVs: builder.query<EIVListModel[], void>({
      query: () => `${BASE_PATH}?type=${ListModelType.enum.EIV}`,
      providesTags: ['ListsData'],
    }),
    getById: builder.query<ListModel, ListModel['id']>({
      query: (id) => `${BASE_PATH}/${id}`,
      providesTags: ['ListsData'],
    }),
    upsert: builder.mutation<ListModel, ListModel>({
      query: (payload) => ({
        url: BASE_PATH,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['ListsData'],
    }),
    remove: builder.mutation<ListModel, ListModel['id']>({
      query: (id) => ({
        url: `${BASE_PATH}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ListsData'],
    }),
  }),
});

export const {
  endpoints,
  middleware,
  reducer,
  reducerPath,
  useGetBalanceAreasQuery,
  useGetBalanceGroupsQuery,
  useGetBTRsQuery,
  useGetByIdQuery,
  useGetDSOsQuery,
  useGetEIVsQuery,
  useGetTSOsQuery,
  useGetWLPartnersQuery,
  useRemoveMutation,
  useUpsertMutation,
} = listsDataApi;
