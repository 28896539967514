// https://digitaldesign.vattenfall.com/designers/components/selection/buttons
import { CSS } from '@stitches/react';
import classNames from 'classnames';
import _ from 'lodash';
import { forwardRef } from 'react';

import { css } from '../stitches.config';
import { HTMLTag } from '../types';
import { Box, BoxProps } from './Box';

type Color = 'yellow' | 'blue' | 'green' | 'orange' | 'red' | 'pink' | 'purple';

export type ChipProps<C extends HTMLTag = 'span'> = {
  centered?: boolean;
  clickable?: boolean;
  disabled?: boolean;
  disableMinWidth?: boolean;
  size?: 'md' | 'lg';
  variant?: 'filled' | 'outlined';
  color?: Color;
} & BoxProps<C>;

const NEEDS_CONTRAST = ['red', 'pink', 'purple', 'blue'];

export const Chip = forwardRef<HTMLElement, ChipProps<HTMLTag>>(function Chip(
  {
    as = 'span',
    className,
    centered = true,
    clickable = false,
    disabled = false,
    disableMinWidth = false,
    color = 'yellow',
    size = 'md',
    variant = 'filled',
    ...props
  },
  ref,
) {
  return (
    <Box
      as={as}
      className={classNames(
        css({
          backgroundColor: `$${color}`,
          borderColor: `$${color}`,
          borderStyle: 'solid',
          borderWidth: '2px',
          display: 'block',
          color: '$hoverBorder',
          fontFamily: '$default',
          fontSize: '12px',
          fontWeight: '$light',
          padding: '4px 8px',

          variants: {
            color: _.zipObject(
              ['yellow', 'blue', 'green', 'orange', 'red', 'pink', 'purple'],
              _.map<Color, CSS>(['yellow', 'blue', 'green', 'orange', 'red', 'pink', 'purple'], (color) => {
                if (NEEDS_CONTRAST.includes(color)) {
                  return { color: '$primaryAuraWhite' };
                }

                return {};
              }),
            ),

            variant: {
              filled: {},
              outlined: {
                backgroundColor: '$primaryAuraWhite',
                color: `$${color}`,
              },
            },

            size: {
              md: {
                borderRadius: '20px',
                fontSize: '12px',
                lineHeight: '12px',
              },

              lg: {
                borderRadius: '26px',
                fontSize: '16px',
                padding: '6px 12px',
                lineHeight: '16px',
              },
            },

            disableMinWidth: {
              true: {},
              false: {},
            },

            centered: {
              true: {
                textAlign: 'center',
              },
            },

            clickable: {
              true: {
                cursor: 'pointer',

                '&:hover': {
                  filter: 'brightness(0.9)',
                },

                '&:active': {
                  filter: 'brightness(0.75)',
                },

                outlined: {
                  '&:hover': {
                    color: '$primaryAuraWhite',
                    backgroundColor: `$${color}`,
                  },
                  '&:active': {
                    color: '$primaryAuraWhite',
                    backgroundColor: `$${color}`,
                  },
                },
              },
            },

            disabled: {
              true: {
                backgroundColor: '$tertiaryLighterGray',
                color: '$tertiaryMediumDarkGray',
                borderColor: '$darkBorderGrey',

                '&:hover': {
                  color: '$tertiaryMediumDarkGray',
                  backgroundColor: '$tertiaryLighterGray',
                },
                '&:active': {
                  color: '$tertiaryMediumDarkGray',
                  backgroundColor: '$tertiaryLighterGray',
                },
              },
            },
          },
          compoundVariants: [
            {
              disableMinWidth: false,
              size: 'md',
              css: {
                minWidth: '100px',
              },
            },
            {
              disableMinWidth: false,
              size: 'lg',
              css: {
                minWidth: '160px',
              },
            },
          ],
        })({ centered, clickable, color, disabled, disableMinWidth, size, variant }),
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
