import { ContractFormTabsNames } from '../contractTabs';

export interface ContractReviewTab {
  id: ContractFormTabsNames;
  isDisabled: boolean;
}

export const contractReviewTabsConfig: ContractReviewTab[] = [
  {
    id: ContractFormTabsNames.CUSTOMER_INFO,
    isDisabled: false,
  },
  {
    id: ContractFormTabsNames.BILLING,
    isDisabled: false,
  },
  {
    id: ContractFormTabsNames.PARK_BASE_DATA,
    isDisabled: false,
  },
  {
    id: ContractFormTabsNames.PARK_DETAILS,
    isDisabled: false,
  },
];
