import { ReactElement } from 'react';

import { DefaultProps } from '../stitches.config';
import { mergeCss } from '../util';
import { Box } from './Box';
import { Overlay } from './Overlay';
import { Fluid, Stack } from './Stack';

const HeaderCover = ({ children }: { children?: ReactElement }) => (
  <Box css={{ fill: true }}>
    <Box css={{ center: true, fill: true }}>
      <Box
        css={{
          height: 'calc(100% - 20px)',
          width: '100%',
        }}
      >
        {children}
      </Box>
    </Box>
  </Box>
);

export const Header = ({
  css,
  logo,
  widgetsLeft,
  widgetsRight,
  ...props
}: { logo?: ReactElement; widgetsLeft?: ReactElement; widgetsRight?: ReactElement } & DefaultProps<'nav'>) => {
  return (
    <Overlay
      as='nav'
      cover={
        <Box css={{ fill: true }}>
          <Stack
            css={{ height: '100%', paddingX: '20px', svg: { size: '20px' } }}
            align='center'
            justify='end'
            gap='20px'
          >
            {widgetsLeft}
            <Fluid />
            {widgetsRight}
          </Stack>
        </Box>
      }
      css={mergeCss({ background: '$background', height: '70px', width: '100%' }, css)}
      on
      {...props}
    >
      {logo && <HeaderCover>{logo}</HeaderCover>}
    </Overlay>
  );
};
