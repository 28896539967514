"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateMailingListDTO = exports.StagingAggregator = exports.StagingAggregatorDTOWithPark = exports.StagingAggregatorDTO = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
var contractStaging_1 = require("./contractStaging");
var park_1 = require("./park");
exports.StagingAggregatorDTO = zod_1.z.object({
    id: util_1.uuid.optional(),
    park: util_1.uuid.optional().nullable(),
    name: zod_1.z.string().nonempty('commonErrors.empty'),
    contracts: zod_1.z.array(contractStaging_1.ContractStagingDTO).optional().nullable(),
    sharedWith: zod_1.z.string().regex(new RegExp(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})((,| ,|, | , |){1}[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/), // https://regex101.com/r/6l030N/1
    'customerForm.errors.email'),
    isSubmitted: zod_1.z.boolean(),
});
exports.StagingAggregatorDTOWithPark = exports.StagingAggregatorDTO.omit({ park: true }).merge(zod_1.z.object({
    park: park_1.Park.optional().nullable(),
}));
exports.StagingAggregator = zod_1.z.object({
    id: util_1.uuid.optional(),
    park: util_1.uuid.optional().nullable(),
    name: zod_1.z.string().nonempty(),
    contracts: zod_1.z.array(contractStaging_1.ContractStaging).optional().nullable(),
    sharedWith: zod_1.z.string().regex(new RegExp(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})((,| ,|, | , |){1}[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/), // https://regex101.com/r/6l030N/1
    'customerForm.errors.email'),
    isSubmitted: zod_1.z.boolean(),
});
exports.UpdateMailingListDTO = zod_1.z.object({
    sharedWith: zod_1.z.string().regex(new RegExp(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})((,| ,|, | , |){1}[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/), // https://regex101.com/r/6l030N/1
    'customerForm.errors.email'),
});
