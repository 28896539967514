export const isProd = process.env.NODE_ENV === 'production';

export const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

interface InstanceConfig {
  ADMINS: string[];
  API_URL: string;
  AZURE_MAPS: string;
  CLIENT_APP_URL: string;
  INSIGHTS: string;
  MSAL_CLIENT_ID: string;
  MSAL_SCOPES: string[];
  MSAL_TENANT: string;
  SHOULD_PREFILL_FORM: string;
  [key: string]: string | string[] | unknown[];
}

const defaultConfig: InstanceConfig = {
  ADMINS: [],
  API_URL: '',
  AZURE_MAPS: '',
  CLIENT_APP_URL: '',
  INSIGHTS: '',
  MSAL_CLIENT_ID: '',
  MSAL_SCOPES: [],
  MSAL_TENANT: '',
  SHOULD_PREFILL_FORM: '',
};

const parseProcessEnv = (env: NodeJS.ProcessEnv, replaceKeys: boolean): InstanceConfig => {
  const config = defaultConfig;

  Object.entries(env)
    .filter(([id]) => id.startsWith('REACT_APP_CONFIG_'))
    .forEach(([key, value]) => {
      const newKey: string = key.replace('REACT_APP_CONFIG_', '');

      config[replaceKeys ? newKey : key] = value?.includes('[') ? JSON.parse(value) : value;
    });

  return config;
};

const getRuntimeConfig = (): InstanceConfig => {
  const htmlElement = document.getElementById('js-bundle-runtime-config');

  if (isProd) {
    return htmlElement ? parseProcessEnv(JSON.parse(htmlElement.innerHTML), true) : defaultConfig;
  }

  return parseProcessEnv(process.env, true);
};

export const config = getRuntimeConfig();
