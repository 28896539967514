"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionStagingDTO = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
var section_1 = require("./section");
exports.SectionStagingDTO = zod_1.z.object({
    id: util_1.uuid.optional(),
    unitName: zod_1.z.string().nonempty('commonErrors.empty'),
    commissioningDate: util_1.zodDate,
    dateOfReceivedAward: util_1.zodDate,
    eegSystemKey: zod_1.z
        .string()
        .regex(/^E[a-zA-Z0-9]{32}$/, 'shareForm.errors.eegSystemKey')
        .optional()
        .or(util_1.emptyStringToNull)
        .nullable(),
    controllableResourceId: zod_1.z
        .string()
        .regex(/^C[a-zA-Z0-9]{10}$/, 'shareForm.errors.controllableResourceId')
        .optional()
        .or(util_1.emptyStringToNull)
        .nullable(),
    srEanCode: zod_1.z.string().optional().nullable(),
    technicalResourceId: zod_1.z
        .string()
        .regex(/^D[a-zA-Z0-9]{10}$/, 'shareForm.errors.technicalResourceId')
        .optional()
        .or(util_1.emptyStringToNull)
        .nullable(),
    marketMasterDataRegisterNumberPerUnit: zod_1.z
        .string()
        .regex(/^SEE[a-zA-Z0-9]{12}$/, 'shareForm.errors.marketMasterDataRegisterNumberPerUnit')
        .optional()
        .or(util_1.emptyStringToNull)
        .nullable(),
    currentValueToBeApplied: zod_1.z.number().optional().nullable(),
    futureValueToBeApplied: zod_1.z.number().optional().nullable(),
    beginningOfValidityOfTheNewAW: zod_1.z
        .preprocess(function (arg) {
        if (typeof arg == 'string' || arg instanceof Date) {
            return new Date(arg);
        }
    }, zod_1.z.date({ required_error: 'commonErrors.selectDate' }))
        .optional()
        .nullable(),
    manufacturer: zod_1.z.string().nonempty('commonErrors.empty'),
    modelSeries: zod_1.z.string().nonempty('commonErrors.empty'),
    inclination: zod_1.z
        .number({ required_error: 'commonErrors.empty' })
        .nonnegative('commonErrors.nonnegative')
        .max(180, 'Max 180°'),
    orientation: zod_1.z
        .number({ required_error: 'commonErrors.empty' })
        .nonnegative('commonErrors.nonnegative')
        .max(360, 'Max 360°'),
    totalPower: zod_1.z.number().nonnegative('commonErrors.nonnegative'),
    inverterManufacturer: zod_1.z.string().nonempty('commonErrors.empty'),
    inverterTypeSeries: zod_1.z.string().nonempty('commonErrors.empty'),
    powerPerInverter: zod_1.z
        .number({ required_error: 'commonErrors.empty' })
        .nonnegative('commonErrors.nonnegative')
        .max(999999, 'Max 999.999 kW'),
    numberOfInverters: zod_1.z
        .number({ required_error: 'commonErrors.empty' })
        .nonnegative('commonErrors.nonnegative')
        .max(999, 'Max 999'),
    latitude: zod_1.z
        .number({ required_error: 'commonErrors.empty' })
        .nonnegative('commonErrors.nonnegative')
        .max(99.999999, 'Max 99,999999'),
    longitude: zod_1.z
        .number({ required_error: 'commonErrors.empty' })
        .nonnegative('commonErrors.nonnegative')
        .max(99.999999, 'Max 99,999999'),
    section: section_1.SectionDTO.optional().nullable(),
    locationId: util_1.uuid.optional().nullable(),
    parkId: util_1.uuid.optional().nullable(),
});
