import { ReactElement, useCallback, useState } from 'react';

import { DefaultProps } from '../stitches.config';
import { mergeCss } from '../util';
import { Box } from './Box';
import { Header } from './Header';
import { IconMenu } from './icons';
import { Logo } from './Logo';
import { Navbar } from './Navbar';
import { Overlay } from './Overlay';
import { Fluid, Stack } from './Stack';

type AppShellProps = {
  navbarItems: ReactElement;
  headerWidgets?: ReactElement;
} & DefaultProps;

export const AppShell = ({ children, css, headerWidgets, navbarItems, ...props }: AppShellProps) => {
  const [navbarWideVisible, setNavbarWideVisible] = useState(false);
  const toggleNavbarWideVisibility = useCallback(
    () => setNavbarWideVisible((currentNavbarWideVisible) => !currentNavbarWideVisible),
    [setNavbarWideVisible],
  );

  return (
    <Stack axis='y' css={mergeCss({ background: '$tertiaryLighterGray', fill: true }, css)} gap='1px' {...props}>
      <Header
        logo={<Logo css={{ fill: true }} />}
        widgetsLeft={
          <IconMenu
            css={{
              cursor: 'pointer',
              '@sm': {
                display: 'none',
              },
            }}
            onClick={toggleNavbarWideVisibility}
          />
        }
        widgetsRight={headerWidgets}
      />
      <Fluid axis='y'>
        <Box css={{ display: 'flex' }}>
          <Navbar
            css={{
              '@xs': {
                display: 'none',
              },
              '@sm': {
                display: 'unset',
              },
              flex: '0 0 auto',
            }}
          >
            {navbarItems}
          </Navbar>
          <Box css={{ background: '$background', fill: true, '> *': { fill: true } }}>
            <Overlay
              animation='slide'
              cover={<Navbar wide>{navbarItems}</Navbar>}
              css={{
                overflow: 'scroll',
                '@sm': {
                  display: 'none',
                },
              }}
              on={navbarWideVisible}
            >
              {children}
            </Overlay>
            <Box
              css={{
                fill: true,
                '@xs': {
                  display: 'none',
                },
                '@sm': {
                  display: 'unset',
                },
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Fluid>
    </Stack>
  );
};
