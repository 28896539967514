"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeterOperatorStaging = exports.DistributionSystemOperatorStaging = exports.TransmissionSystemOperatorStaging = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
exports.TransmissionSystemOperatorStaging = zod_1.z.object({
    id: util_1.uuid.optional(),
    name: zod_1.z.string(),
    bdewCode: zod_1.z.string().regex(new RegExp(/^\d{13}$/), 'parkDetailsForm.errors.bdewCode'),
    controlZone: zod_1.z.string().optional().nullable(),
});
exports.DistributionSystemOperatorStaging = zod_1.z.object({
    id: util_1.uuid.optional(),
    name: zod_1.z.string(),
    balanceArea: zod_1.z.string().optional().nullable(),
    bdewCode: zod_1.z.string().regex(new RegExp(/^\d{13}$/), 'parkDetailsForm.errors.bdewCode'),
    newIBNProcessNumber: zod_1.z.string().optional().nullable(),
    validFrom: util_1.zodDate.optional().nullable(),
});
exports.MeterOperatorStaging = zod_1.z.object({
    id: util_1.uuid.optional(),
    name: zod_1.z.string().optional().nullable(),
    bdewCode: zod_1.z
        .string()
        .regex(new RegExp(/^\d{13}$/), 'parkDetailsForm.errors.bdewCode')
        .or(util_1.emptyStringToNull)
        .optional()
        .nullable(),
    isDefaultMSB: zod_1.z.boolean().optional().nullable(),
});
