import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import de from './locales/de.json';
import en from './locales/en.json';

export const i18n = i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    detection: {
      order: ['navigator', 'htmlTag'],
      caches: ['localStorage', 'cookie'],
      htmlTag: document.documentElement,
    },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en,
      de,
    },
  });
