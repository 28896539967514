"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OptionalContractStaging = exports.ContractStagingWithShortSA = exports.ContractStaging = exports.ContractStagingDTO = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
var billingStaging_1 = require("./billingStaging");
var companyStaging_1 = require("./companyStaging");
var parkStaging_1 = require("./parkStaging");
var shareStaging_1 = require("./shareStaging");
exports.ContractStagingDTO = zod_1.z.object({
    id: util_1.uuid.optional(),
    name: zod_1.z.string().nonempty('commonErrors.empty'),
    contractualPartner: companyStaging_1.CompanyStagingDTO,
    commercialManagement: companyStaging_1.CompanyStagingDTO,
    invoice: companyStaging_1.CompanyStagingDTO,
    technicalManagement: companyStaging_1.CompanyStagingDTO,
    billing: billingStaging_1.BillingStaging.optional().nullable(),
    park: parkStaging_1.ParkStaging.optional().nullable(),
    shares: zod_1.z.array(shareStaging_1.ShareStagingDTO).optional().nullable(),
    stagingAggregator: util_1.uuid.optional().nullable(),
});
exports.ContractStaging = zod_1.z.object({
    id: util_1.uuid.optional(),
    name: zod_1.z.string().nonempty('commonErrors.empty'),
    contractualPartner: companyStaging_1.CompanyStaging,
    commercialManagement: companyStaging_1.CompanyStaging,
    invoice: companyStaging_1.CompanyStaging,
    technicalManagement: companyStaging_1.CompanyStaging,
    billing: billingStaging_1.BillingStaging.optional().nullable(),
    park: parkStaging_1.ParkStaging.optional().nullable(),
    shares: zod_1.z.array(shareStaging_1.ShareStagingDTO).optional().nullable(),
    stagingAggregator: util_1.uuid.optional().nullable(),
});
exports.ContractStagingWithShortSA = exports.ContractStagingDTO.omit({ stagingAggregator: true }).merge(zod_1.z.object({
    stagingAggregator: zod_1.z.object({
        id: util_1.uuid.optional(),
        name: zod_1.z.string(),
        contracts: zod_1.z
            .array(zod_1.z.object({
            id: util_1.uuid.optional(),
            name: zod_1.z.string(),
        }))
            .optional()
            .nullable(),
        isSubmitted: zod_1.z.boolean(),
    }),
}));
exports.OptionalContractStaging = exports.ContractStaging.deepPartial();
