import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { adminsApi } from './admins';
import { billingsApi } from './billings';
import { companiesApi } from './companies';
import { contractsApi } from './contracts';
import { customersApi } from './customers';
import { exportsApi } from './exports';
import { listsDataApi } from './listsData';
import { locationsApi } from './locations';
import { parksApi } from './parks';
import { pisaInputsApi } from './pisaInputs';
import { pricingInputApi } from './pricingInputs';
import { reportsApi } from './reports';
import { reportsDraftApi } from './reportsDraft';
import { sectionsApi } from './sections';
import { sharesApi } from './shares';
import { turbinesApi } from './turbines';
import { graphApi, userApi } from './users';

export const store = configureStore({
  reducer: {
    [adminsApi.reducerPath]: adminsApi.reducer,
    [billingsApi.reducerPath]: billingsApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [contractsApi.reducerPath]: contractsApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [exportsApi.reducerPath]: exportsApi.reducer,
    [graphApi.reducerPath]: graphApi.reducer,
    [listsDataApi.reducerPath]: listsDataApi.reducer,
    [locationsApi.reducerPath]: locationsApi.reducer,
    [parksApi.reducerPath]: parksApi.reducer,
    [pisaInputsApi.reducerPath]: pisaInputsApi.reducer,
    [pricingInputApi.reducerPath]: pricingInputApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [reportsDraftApi.reducerPath]: reportsDraftApi.reducer,
    [sectionsApi.reducerPath]: sectionsApi.reducer,
    [sharesApi.reducerPath]: sharesApi.reducer,
    [turbinesApi.reducerPath]: turbinesApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      adminsApi.middleware,
      billingsApi.middleware,
      companiesApi.middleware,
      contractsApi.middleware,
      customersApi.middleware,
      exportsApi.middleware,
      graphApi.middleware,
      listsDataApi.middleware,
      locationsApi.middleware,
      parksApi.middleware,
      pisaInputsApi.middleware,
      pricingInputApi.middleware,
      reportsApi.middleware,
      reportsDraftApi.middleware,
      sectionsApi.middleware,
      sharesApi.middleware,
      turbinesApi.middleware,
      userApi.middleware,
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
