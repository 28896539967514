import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { Contract, LocationDTO, Park, TurbineDTO } from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

type TurbineWithLocation = TurbineDTO & { location: LocationDTO };

export const turbinesApi = createApi({
  reducerPath: 'turbines',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
  }),
  tagTypes: ['Turbines'],
  endpoints: (builder) => ({
    getAllParkTurbines: builder.query<TurbineDTO[], Park['id']>({
      query: (id) => `/api/turbines/park/${id}`,
      providesTags: ['Turbines'],
    }),
    getAllParkTurbinesByContractId: builder.query<TurbineWithLocation[], Contract['id']>({
      query: (id) => `/api/turbines/contract/${id}`,
      providesTags: ['Turbines'],
    }),
  }),
});

export const {
  endpoints,
  middleware,
  reducer,
  reducerPath,
  useGetAllParkTurbinesQuery,
  useGetAllParkTurbinesByContractIdQuery,
} = turbinesApi;
