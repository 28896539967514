export enum ContractFormTabsNames {
  CUSTOMER_INFO,
  BILLING,
  PARK_BASE_DATA,
  PARK_DETAILS,
  PISA,
  PRICING_INPUT,
}

export interface ContractTab {
  id: ContractFormTabsNames;
  isDisabled: boolean;
}

export const contractTabsConfig: ContractTab[] = [
  {
    id: ContractFormTabsNames.CUSTOMER_INFO,
    isDisabled: false,
  },
  {
    id: ContractFormTabsNames.BILLING,
    isDisabled: true,
  },
  {
    id: ContractFormTabsNames.PARK_BASE_DATA,
    isDisabled: true,
  },
  {
    id: ContractFormTabsNames.PARK_DETAILS,
    isDisabled: true,
  },
  {
    id: ContractFormTabsNames.PISA,
    isDisabled: true,
  },
  {
    id: ContractFormTabsNames.PRICING_INPUT,
    isDisabled: true,
  },
];
