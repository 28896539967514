import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { GraphUser, User } from 'markets-lib';

import { config } from '../config';
import { prepGraphHeaders, prepHeaders } from '../util';

export const userApi = createApi({
  reducerPath: 'users',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
  }),
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getMe: builder.query<User, void>({
      query: () => '/api/me',
      providesTags: ['Users'],
    }),
    getAll: builder.query<User[], void>({
      query: () => '/api/users',
      providesTags: ['Users'],
    }),
    update: builder.mutation<User, User>({
      query: (body) => ({
        url: '/api/users',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    remove: builder.mutation<boolean, User['id']>({
      query: (id: User['id']) => ({
        url: `/api/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const {
  endpoints,
  middleware,
  reducer,
  reducerPath,
  useGetMeQuery,
  useGetAllQuery,
  useUpdateMutation,
  useRemoveMutation,
} = userApi;

export const graphApi = createApi({
  reducerPath: 'graph',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://graph.microsoft.com/v1.0',
    prepareHeaders: prepGraphHeaders,
  }),
  tagTypes: ['Graph'],
  endpoints: (builder) => ({
    // NOTE: search based on following query
    // https://graph.microsoft.com/v1.0/users?$search="displayName:szymon" OR "mail:szymon"&$filter=givenName ne null and surname ne null and mail ne null&$select=id,displayName,mail,givenName,surname,mail
    getGraphUsers: builder.query<GraphUser[], string>({
      query: (query) =>
        `/users?$search="displayName:${query}" OR "mail:${query}"&$filter=givenName ne null and surname ne null and mail ne null&$select=id,displayName,givenName,surname,mail`,
      providesTags: ['Graph'],
      transformResponse: (rawResult: { value: GraphUser[] }): GraphUser[] => rawResult.value,
    }),
  }),
});

export const { useGetGraphUsersQuery } = graphApi;
