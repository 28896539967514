import {
  createStitches,
  CSSProperties,
  PropertyValue as PropertyValue_,
  ScaleValue as ScaleValue_,
} from '@stitches/react';
import type CSSUtil from '@stitches/react/types/css-util';
import _ from 'lodash/fp';
import { ReactNode } from 'react';

import { breakpoints } from './breakpoints';
import { utils } from './cssUtils';
import { theme as defaultTheme } from './theme';
import { themeMap } from './themeMap';
import { HTMLTag } from './types';

export type { StyledComponent } from '@stitches/react/types/styled-component';

export const media = _.pipe([Array.from, _.map(([name, value]) => [name, `(min-width: ${value}px)`]), _.fromPairs])(
  breakpoints.entries(),
);

export const config = {
  theme: defaultTheme,
  themeMap,
  media,
  prefix: 'vf',
  utils,
} as const;

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme } = createStitches(config);

export type Config = typeof config;
export type Prefix = Config['prefix'];
export type Media = Config['media'];
export type Theme = Config['theme'];
export type ThemeMap = Config['themeMap'];
export type Utils = Config['utils'];
export type CSS<M = Media, T = Theme, TM = ThemeMap, U = Utils> = CSSUtil.CSS<M, T, TM, U>;
export type CSSPropertyOrUtilName = keyof CSSProperties | keyof Utils;
export type GlobalCSS = Parameters<typeof globalCss>[0];
export interface DefaultPropsWithoutChildren<C extends HTMLTag = 'div'> {
  as?: C;
  className?: string;
  css?: CSS;
}
export type DefaultProps<C extends HTMLTag = 'div'> = DefaultPropsWithoutChildren<C> & {
  children?: ReactNode;
};

export type Token<Name extends string = string> = `$${Name}`;
export type CSSProperty = keyof CSSUtil.CSSProperties;

export type PropertyValue<Property extends CSSProperty> = PropertyValue_<Property, Config>;
export type ScaleValue<Scale extends keyof Theme> = ScaleValue_<Scale, Config>;
export type CSSValue<Key extends keyof Theme | CSSProperty> = Key extends keyof Theme
  ? ScaleValue<Key>
  : Key extends CSSProperty
  ? PropertyValue<Key>
  : never;
