import { CSS } from '@stitches/react';
import classNames from 'classnames';
import _ from 'lodash';
import { forwardRef } from 'react';

import { css } from '../stitches.config';
import { theme } from '../theme';
import { HTMLTag } from '../types';
import { Box, BoxProps } from './Box';

export type RadioProps<C extends HTMLTag = 'input'> = {
  checked: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
} & BoxProps<C>;

export const Radio = forwardRef<HTMLElement, RadioProps<HTMLTag>>(function Radio(
  { as = 'input', size = 'lg', checked, ...props },
  ref,
) {
  return (
    <Box
      as={as}
      defaultChecked={checked}
      type='radio'
      className={classNames(
        css({
          '-webkit-apperance': 'none',
          appearance: 'none',
          background: '$background',
          border: '1px solid #a4a9ac',
          borderRadius: '50%',
          cursor: 'pointer',
          margin: '0',
          position: 'relative',

          '&::before': {
            backgroundColor: '$primaryMagneticGray',
            borderRadius: '50%',
            content: '',
            height: '46.16%',
            width: '46.16%',
            left: '50%',
            top: '50%',
            opacity: '0',
            position: 'absolute',
            transform: 'translate(-50%, -50%) scale(0)',
            transition: 'transform 0.15s ease-in-out',
          },

          '&:hover&:not(:disabled)&:not(:checked)': {
            '&:before': {
              opacity: '1',
              transform: 'translate(-50%, -50%) scale(1)',
              backgroundColor: '$tertiaryLightGray',
            },
          },

          '&:checked::before': {
            opacity: '1',
            transform: 'translate(-50%, -50%) scale(1)',
          },

          '&:disabled': {
            backgroundColor: '#eee',
            border: '1px solid $tertiaryLightGray',
            color: '$tertiaryLightGray',
            cursor: 'auto',
          },

          '&:disabled::before': {
            backgroundColor: '$tertiaryLightGray',
          },

          variants: {
            size: _.zipObject(
              ['sm', 'md', 'lg', 'xl'],
              _.map<'sm' | 'md' | 'lg' | 'xl', CSS>(['sm', 'md', 'lg', 'xl'], (size) => ({
                height: `calc(${theme.fontSizes[size]} * 1.4)`,
                width: `calc(${theme.fontSizes[size]} * 1.4)`,
              })),
            ),
          },
        })({ size }),
      )}
      ref={ref}
      {...props}
    />
  );
});
