import * as _ from 'lodash/fp';
import { LegacyRef, MutableRefObject, RefCallback } from 'react';

import { CSS } from './stitches.config';
import { defaultFontSize, Distance } from './types';

export const getPixels = (value: Distance): number => {
  if (value === '0') {
    return 0;
  } else if (value.endsWith('px')) {
    return parseFloat(value);
  } else if (value.endsWith('em')) {
    return parseFloat(value) * defaultFontSize;
  } else {
    throw new Error(`Can't calculate pixels from ${value}`);
  }
};

export const mergeCss: (...css: (CSS | undefined)[]) => CSS = _.unapply(_.reduce(_.merge, {}));

// export const isToken = (value: unknown): value is Token => {
//   return typeof value === 'string' && value.startsWith('$');
// };

export function mergeRefs<T = unknown>(refs: (MutableRefObject<T> | LegacyRef<T>)[]): RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as MutableRefObject<T | null>).current = value;
      }
    });
  };
}
