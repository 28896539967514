import { ComponentPropsWithoutRef, forwardRef, useMemo } from 'react';

import { DefaultProps, styled } from '../stitches.config';
import { HTMLTag } from '../types';

// TODO: only html tags for now
// See the implementation for tags + react components:
// https://github.com/mantinedev/mantine/blob/master/src/mantine-core/src/components/Box/Box.tsx

// TODO: it's very tricky if even possible to make forwardRef produce a generic component so the Box component props
// are always typed BoxProps<HTMLTag> (instead of BoxProps<'div'> etc.)
// https://github.com/system-ui/theme-ui/issues/1124
// https://stackoverflow.com/questions/62642843/typescript-react-forwardref-with-generic-props-from-material-ui

export type BoxProps<C extends HTMLTag = 'div'> = DefaultProps<C> & ComponentPropsWithoutRef<C>;

export const Box = forwardRef<HTMLElement, BoxProps<HTMLTag>>(function Box({ as = 'div', ...props }, ref) {
  const StyledComponent = useMemo(() => styled(as), [as]);

  // TODO: fix ref type
  return <StyledComponent ref={ref as never} {...props} />;
});
