import { Box, Button, Col, Grid, H2, H4, Row, Text } from '@rcl/rcl';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import windpark from '../../assets/windpark.png';
import { useGetMeQuery } from '../../store/users';
import { Page } from '../Page';

interface HomePageBoxProps {
  buttonText: string;
  description: string;
  title: string;
  url: string;
}

const HomePageBox = ({ title, description, url, buttonText }: HomePageBoxProps) => {
  const history = useHistory();

  const goToPage = (url: string) => () => {
    history.push(url);
  };

  return (
    <Box css={{ background: '#f2f2f2', borderRadius: '20px' }}>
      <Grid>
        <Row>
          <Col>
            <H4 css={{ margin: '0' }}>{title}</H4>
          </Col>
        </Row>

        <Row>
          <Col>
            <Text>{description}</Text>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button onClick={goToPage(url)}>{buttonText}</Button>
          </Col>
        </Row>
      </Grid>
    </Box>
  );
};

export const HomePage = () => {
  const { data: me } = useGetMeQuery();
  const { t } = useTranslation();

  return (
    <Page>
      <Grid css={{ height: 'inherit', boxSizing: 'border-box' }} rowAlign='center' rowJustify='center'>
        <Row>
          <Col span={12}>
            <Box css={{ borderRadius: '20px', height: '500px', overflow: 'hidden' }}>
              <img src={windpark} alt='Wind Offshore' className='homepage__image' />
            </Box>
          </Col>
        </Row>

        <Row>
          <Col>
            <H2 css={{ margin: '0' }}>{t('welcome')}</H2>
          </Col>
        </Row>

        <Row>
          <Col span={{ xs: 12, sm: 6 }}>
            <Text as='p' align='center' css={{ margin: '0' }}>
              {t('welcomeMessage')}
            </Text>
          </Col>
        </Row>

        <Row css={{ alignItems: 'start' }}>
          <Col span={{ xs: 12, sm: 4 }}>
            <HomePageBox
              title={t('contractsHeading')}
              description={t('contractsText')}
              buttonText={t('contractsGoTo')}
              url='/contracts'
            />
          </Col>

          <Col span={{ xs: 12, sm: 4 }}>
            <HomePageBox
              title={t('exportsHeading')}
              description={t('exportsText')}
              buttonText={t('exportsGoTo')}
              url='/exports'
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Text css={{ fontSize: '12px' }}>
              {t('loggedInAs')} {me?.name}
            </Text>
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};
