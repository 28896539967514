"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrentBalanceModel = exports.currentBalanceModel = exports.currentBalanceModelDefaultValue = exports.CurrentStatusTolerationCase = exports.currentStatusTolerationCase = exports.currentStatusTolerationCaseDefaultValue = exports.DesiredAccountingProcedureAtTheGridOperator = exports.desiredAccountingProcedureAtTheGridOperator = exports.CurrentAccountingProcedureAtTheGridOperator = exports.currentAccountingProcedureAtTheGridOperator = exports.accountingProcedureDefaultValue = exports.DesiredDirectMarketingForm = exports.desiredDirectMarketingForm = exports.CurrentDirectMarketingForm = exports.currentDirectMarketingForm = exports.marketingFormDefaultValue = exports.OptionalPark = exports.ParkForm = exports.Park = exports.ParkEmsysForm = exports.ParkEmsys = exports.ParkAddress = exports.ParkType = exports.parkTypes = exports.Country = exports.countries = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
var company_1 = require("./company");
var location_1 = require("./location");
var operator_1 = require("./operator");
exports.countries = [
    'AL',
    'AD',
    'AM',
    'AT',
    'BY',
    'BE',
    'BA',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'GE',
    'DE',
    'GR',
    'HU',
    'IS',
    'IE',
    'IT',
    'XK',
    'LV',
    'LI',
    'LT',
    'LU',
    'MK',
    'MT',
    'MD',
    'MC',
    'ME',
    'NL',
    'NO',
    'PL',
    'PT',
    'RO',
    'RU',
    'SM',
    'RS',
    'SK',
    'SI',
    'ES',
    'SE',
    'CH',
    'TR',
    'UA',
    'GB', // United Kingdom
];
exports.Country = zod_1.z.enum(exports.countries);
exports.parkTypes = ['WFL', 'WNL', 'SOL'];
exports.ParkType = zod_1.z.enum(exports.parkTypes);
exports.ParkAddress = company_1.Address.omit({ streetName: true, houseNumber: true }).merge(zod_1.z.object({
    streetName: zod_1.z.string().optional().nullable(),
    houseNumber: zod_1.z.string().optional().nullable(),
    districtAndParcel: zod_1.z.string().optional().nullable(),
    state: zod_1.z.custom().and(zod_1.z.string().min(1, 'customerForm.errors.empty')),
    country: exports.Country,
}));
exports.ParkEmsys = zod_1.z.object({
    pointOfDeliveryId: zod_1.z.string().optional().nullable(),
    parkId: zod_1.z.string().optional().nullable(),
    portfolio: zod_1.z.string().optional().nullable(),
    designationDirectMarketer: zod_1.z.string().optional().nullable(),
});
exports.ParkEmsysForm = zod_1.z.object({
    pointOfDeliveryId: util_1.zodNullToStringNonempty,
    parkId: util_1.zodNullToStringNonempty,
    portfolio: util_1.zodNullToStringNonempty,
    designationDirectMarketer: util_1.zodNullToStringNonempty,
});
exports.Park = zod_1.z.object({
    id: util_1.uuid.optional(),
    parkInformation: zod_1.z.object({
        parkName: zod_1.z.string().nonempty('customerForm.errors.empty'),
        eanCode: zod_1.z.string().nonempty('customerForm.errors.empty'),
        parkType: exports.ParkType,
    }),
    address: exports.ParkAddress,
    remoteControllability: zod_1.z.object({
        loadType: zod_1.z.string().optional().nullable(),
        controlType: zod_1.z.string().optional().nullable(),
        dialUpSoftware: zod_1.z.string().optional().nullable(),
        networkConnectionPointName: zod_1.z.string().optional().nullable(),
        distributionSignal: zod_1.z.string().optional().nullable(),
        installationSiteLoadManagement: zod_1.z.string().optional().nullable(),
        oldRemoteControlServiceProvider: zod_1.z.string().optional().nullable(),
    }),
    transmissionSystemOperator: operator_1.TransmissionSystemOperator,
    distributionSystemOperator: operator_1.DistributionSystemOperator,
    meterOperator: operator_1.MeterOperator.optional().nullable(),
    emsys: exports.ParkEmsys,
    locations: zod_1.z.array(location_1.LocationDTO).optional().nullable(),
    contracts: zod_1.z
        .array(zod_1.z.object({
        id: util_1.uuid,
        name: zod_1.z.string(),
        export: zod_1.z.object({ id: util_1.uuid }).nullable(),
    }))
        .optional(),
    // NOTE: Used to attach park instance to contract
    contractId: util_1.uuid.optional(),
    stagingAggregator: util_1.uuid.optional().nullable(),
    currentDirectMarketingForm: zod_1.z.string(),
    desiredDirectMarketingForm: zod_1.z.string(),
    currentAccountingProcedureAtTheGridOperator: zod_1.z.string(),
    desiredAccountingProcedureAtTheGridOperator: zod_1.z.string(),
    currentStatusTolerationCase: zod_1.z.string(),
    currentBalanceModel: zod_1.z.string(),
});
exports.ParkForm = exports.Park.omit({ distributionSystemOperator: true, emsys: true }).merge(zod_1.z.object({
    distributionSystemOperator: operator_1.DistributionSystemOperatorForm,
    emsys: exports.ParkEmsysForm,
}));
exports.OptionalPark = exports.Park.deepPartial();
exports.marketingFormDefaultValue = 'Geförderte DV (Marktprämie)';
var marketingForms = [exports.marketingFormDefaultValue, 'Sonstige DV', 'Weiterbetrieb'];
exports.currentDirectMarketingForm = marketingForms;
exports.CurrentDirectMarketingForm = zod_1.z.enum(exports.currentDirectMarketingForm);
exports.desiredDirectMarketingForm = marketingForms;
exports.DesiredDirectMarketingForm = zod_1.z.enum(exports.desiredDirectMarketingForm);
exports.accountingProcedureDefaultValue = 'Pauschal';
var accountingProcedures = [exports.accountingProcedureDefaultValue, 'Spitz', 'Spitz-light'];
exports.currentAccountingProcedureAtTheGridOperator = accountingProcedures;
exports.CurrentAccountingProcedureAtTheGridOperator = zod_1.z.enum(exports.currentAccountingProcedureAtTheGridOperator);
exports.desiredAccountingProcedureAtTheGridOperator = accountingProcedures;
exports.DesiredAccountingProcedureAtTheGridOperator = zod_1.z.enum(exports.desiredAccountingProcedureAtTheGridOperator);
exports.currentStatusTolerationCaseDefaultValue = 'Duldungsfall';
exports.currentStatusTolerationCase = [exports.currentStatusTolerationCaseDefaultValue, 'Aufforderungsfall'];
exports.CurrentStatusTolerationCase = zod_1.z.enum(exports.currentStatusTolerationCase);
exports.currentBalanceModelDefaultValue = 'Prognosemodell';
exports.currentBalanceModel = [exports.currentBalanceModelDefaultValue, 'Planwertmodell'];
exports.CurrentBalanceModel = zod_1.z.enum(exports.currentBalanceModel);
