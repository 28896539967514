"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportDraftDTO = exports.MeteringPointDetails = exports.Unit = exports.ESalesParkDetails = exports.EbvContractDetails = exports.Steerability = exports.steerability = exports.EndurPortfolio = exports.endurPortfolios = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
var park_1 = require("./park");
var pisaInput_1 = require("./pisaInput");
var section_1 = require("./section");
var turbine_1 = require("./turbine");
exports.endurPortfolios = ['VET_Wind', 'VET_Wind_Off', 'VET_Solar_WL', 'VET_Wind_WL', 'VET_Solar'];
exports.EndurPortfolio = zod_1.z.enum(exports.endurPortfolios);
exports.steerability = ['LIMITABLE', 'NO'];
exports.Steerability = zod_1.z.enum(exports.steerability);
exports.EbvContractDetails = zod_1.z.object({
    serviceFee: zod_1.z.number().nullable(),
    vdnAbbreviaton: zod_1.z.string(),
    vatUniquenessFeature: zod_1.z.number(),
    aneNumber: zod_1.z.string().optional().nullable(),
    remEmail: zod_1.z.string(),
    remCompanyName: zod_1.z.string(),
    posCompanyName: zod_1.z.string(),
    bundleInvoice: zod_1.z.string(),
    distributors: zod_1.z.string(),
    DLBillingModel: zod_1.z.string().nullable().optional(),
});
exports.ESalesParkDetails = zod_1.z.object({
    compensationFloor: zod_1.z.number().optional().nullable(),
    compensationForm: pisaInput_1.CompensationForm.optional().nullable(),
    compensationShare: zod_1.z.number().optional().nullable(),
    compensationStages: zod_1.z.string().optional(),
    contactApiAddress: zod_1.z.string().optional().nullable(),
    customer: zod_1.z.string(),
    customerCompensationFunction: pisaInput_1.CustomerCompensationFunction.optional().nullable(),
    customerCompensationPercentage: zod_1.z.number().optional().nullable(),
    dialUpSoftware: zod_1.z.string().optional().nullable(),
    directMarketingForm: zod_1.z.string(),
    distributionSignal: zod_1.z.string().optional().nullable(),
    DLEZV51Quantities: zod_1.z.number(),
    DLEZVLostWork: zod_1.z.union([zod_1.z.literal(0), zod_1.z.literal(1)]),
    endOfContract: zod_1.z.string(),
    endurPortfolio: exports.EndurPortfolio,
    eSalesShortName: zod_1.z.string(),
    feeBase: zod_1.z.string(),
    feeBaseFix: zod_1.z.number().optional().nullable(),
    feeFunction: pisaInput_1.FeeFunction.optional().nullable(),
    internWhitelabel: zod_1.z.string(),
    managerContactPerson: zod_1.z.string(),
    managerEmail: zod_1.z.string(),
    managerName: zod_1.z.string(),
    managerPhone: zod_1.z.string(),
    networkPointName: zod_1.z.string().optional().nullable(),
    oneWayPaymentFlow: pisaInput_1.YesNoOption,
    operatorPortalUsername: zod_1.z.string(),
    ownership: zod_1.z.string(),
    parkControlType: zod_1.z.string().optional().nullable(),
    plannedStartOfProduction: zod_1.z.string().optional().nullable(),
    portfolio: zod_1.z.string(),
    priceGuaranteeOfOrigin: zod_1.z.number().optional().nullable(),
    pricingCosts: zod_1.z.number().optional().nullable(),
    pricingFlexMargin: zod_1.z.number().optional().nullable(),
    pricingMw: zod_1.z.number().optional().nullable(),
    pricingOffer: zod_1.z.number().optional().nullable(),
    pricingPmId: zod_1.z.string().optional().nullable(),
    pricingTargetMargin: zod_1.z.number().optional().nullable(),
    pricingVat: zod_1.z.string().optional().nullable(),
    rd20BillingProcedure: zod_1.z.string(),
    rd20CompensationFactor: zod_1.z.union([zod_1.z.literal(0), zod_1.z.literal(1)]),
    rd20DLEZVLostWork: zod_1.z.union([zod_1.z.literal(0), zod_1.z.literal(1)]).optional(),
    rd20FeeBase: zod_1.z.string(),
    rd20WLCompensationLostWork: zod_1.z.union([zod_1.z.literal(0), zod_1.z.literal(1)]).optional(),
    rd20WLFeeBase: zod_1.z.string().optional().nullable(),
    remoteControllabilityLoadType: zod_1.z.string().optional().nullable(),
    remoteControlRuleGroup: zod_1.z.string(),
    remoteControlService: zod_1.z.string(),
    startOfMarketing: zod_1.z.string(),
    state: zod_1.z.string(),
    steerCompensationFactor: zod_1.z.union([zod_1.z.literal(0), zod_1.z.literal(1)]),
    steerFunction: pisaInput_1.SteerFunction.optional().nullable(),
    steeringAccountingProcedure: pisaInput_1.SteeringProcedure,
    steeringCompensationBase: pisaInput_1.SteeringCompensationBase.optional().nullable(),
    steeringFloor: zod_1.z.number().optional().nullable(),
    typeOfMarketing: park_1.ParkType,
    whitelabelCompensationFloor: zod_1.z.number().optional().nullable(),
    whitelabelCompensationShare: zod_1.z.number().optional().nullable(),
    whitelabelProvision: zod_1.z.number().optional().nullable(),
    WLPartner: zod_1.z.string().optional().nullable(),
});
exports.Unit = zod_1.z.object({
    eSalesShortName: zod_1.z.string(),
    unitName: zod_1.z.string().optional(),
    p51Relevant: zod_1.z.number(),
    fourHourP51Relevant: zod_1.z.number(),
    unitSteeringAccountingProcedure: zod_1.z.string(),
    steerability: exports.Steerability,
    activeUnit: zod_1.z.string(),
    currentValueToBeApplied: zod_1.z.number().optional().nullable(),
    artLM: zod_1.z.string().optional().nullable(),
    enmPlantId: zod_1.z.string(),
    enmDesignationDirectMarketer: zod_1.z.string(),
    enmParkId: zod_1.z.string(),
    enmPortfolio: zod_1.z.string(),
    enmPointOfDeliveryId: zod_1.z.string(),
    installationSiteLoadManagement: zod_1.z.string().optional().nullable(),
    totalPower: zod_1.z.number().optional().nullable(),
    meteringPointId: zod_1.z.string().optional().nullable(),
    nightReductionFrom: zod_1.z.number().optional().nullable(),
    isNightReduction: zod_1.z.string().optional().nullable(),
    nightReductionTo: zod_1.z.number().optional().nullable(),
    serialNumber: zod_1.z.string().optional().nullable(),
    numberOfInverters: zod_1.z.number().optional().nullable(),
    inverterManufacturer: zod_1.z.string().optional().nullable(),
    powerPerInverter: zod_1.z.number().optional().nullable(),
    inverterTypeSeries: zod_1.z.string().optional().nullable(),
    unitDetails: zod_1.z.union([turbine_1.TurbineDTO, section_1.SectionDTO]),
    // NOTE: needed for excel report
    percentage: zod_1.z.number().optional(),
    shareId: util_1.uuid.optional(),
});
exports.MeteringPointDetails = zod_1.z.object({
    meteringPointId: zod_1.z.string().optional(),
    IBNComissioning: zod_1.z.string(),
    awSteer: zod_1.z.number(),
    limitPrice: zod_1.z.number(),
    currentLimitPrice: zod_1.z.number(),
    mwAllocation: zod_1.z.number(),
    mwSteer: zod_1.z.number(),
    units: zod_1.z.array(exports.Unit),
    eSalesParkShortName: zod_1.z.string(),
    MPSerialNumber: zod_1.z.number(),
    fourHourAwSteer: zod_1.z.number(),
    fourHourMwAllocation: zod_1.z.number(),
    fourHourMwSteer: zod_1.z.number(),
});
exports.ReportDraftDTO = zod_1.z.object({
    id: util_1.uuid.optional(),
    data: zod_1.z.object({
        ebvContractDetails: exports.EbvContractDetails,
        eSalesParkDetails: exports.ESalesParkDetails,
        meteringPointDetails: zod_1.z.array(exports.MeteringPointDetails),
    }),
    contractId: util_1.uuid.optional(),
});
