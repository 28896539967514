import { Box, CSS, H1, Text } from '@rcl/rcl';
import { useTranslation } from 'react-i18next';

import windpark from '../../assets/windpark.png';

const textColor: CSS = { color: '$primaryAuraWhite' };
const image = windpark;
const imageAlt = 'Wind and Solar';

export const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <Box
      css={{
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        overflow: 'hidden',
        img: {
          objectFit: 'cover',
          height: '100%',
          width: '100%',
          objectPosition: 'center',
        },
      }}
    >
      <img src={image} alt={imageAlt} />
      <Box
        css={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          background: 'rgba(0,0,0,.3)',
        }}
      >
        <Box
          css={{
            height: '100%',
            display: 'grid',
            alignContent: 'center',
            maxWidth: '500px',
            margin: '-1em auto 0',
            textAlign: 'center',
          }}
        >
          <H1 css={textColor}>401</H1>
          <Text size='$lg' css={textColor}>
            {t('error401text')}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
