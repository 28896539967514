import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { StagingAggregatorDTO } from 'markets-lib';

import { config } from '../config';
import { ListResponse } from '../types';
import { prepHeaders } from '../util';

export const customersApi = createApi({
  reducerPath: 'customers',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
  }),
  tagTypes: ['Customers'],
  endpoints: (builder) => ({
    upsert: builder.mutation<StagingAggregatorDTO, StagingAggregatorDTO>({
      query: (contract) => ({
        url: '/api/staging-aggregators',
        method: 'POST',
        body: contract,
      }),
      invalidatesTags: ['Customers'],
    }),
    getAll: builder.query<ListResponse<StagingAggregatorDTO>, { page: number; search: string }>({
      query: ({ page, search }) =>
        page
          ? `/api/staging-aggregators?page=${page}${search && `&search=${search}`}`
          : `/api/staging-aggregators${search && `?search=${search}`}`,
      providesTags: ['Customers'],
    }),
    getById: builder.query<StagingAggregatorDTO, StagingAggregatorDTO['id']>({
      query: (id) => `/api/staging-aggregators/${id}`,
      providesTags: ['Customers'],
    }),
    remove: builder.mutation<StagingAggregatorDTO[], StagingAggregatorDTO['id']>({
      query: (id) => ({
        url: `/api/staging-aggregators/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Customers'],
    }),
  }),
});

export const {
  endpoints,
  middleware,
  reducer,
  reducerPath,
  useUpsertMutation,
  useRemoveMutation,
  useGetAllQuery,
  useGetByIdQuery,
} = customersApi;
