import { auth } from './auth';
import { config } from './config';
import { DotNestedKeys } from './types';

const EAN_CODE_LENGTH = 33;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const throttle = (callback: any, limit: number) => {
  let wait = false;
  return function () {
    if (!wait) {
      callback.call();
      wait = true;
      setTimeout(function () {
        wait = false;
      }, limit);
    }
  };
};

const capitalize = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

const dashSpacedToCapitalize = (string: string): string =>
  string
    .split('_')
    .map((item) => capitalize(item))
    .join(' ');

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const content = (reader.result as string)?.split('base64,')[1];

      resolve({ fileName: file.path, content });
    };
    reader.onerror = (error) => reject(error);
  });

const prepHeaders = async (headers: Headers) => {
  const token = await auth.getToken();

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  headers.set('Content-Type', 'application/json');

  return headers;
};

const prepGraphHeaders = async (headers: Headers) => {
  const token = await auth.getGraphToken();

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  headers.set('Content-Type', 'application/json');
  headers.set('ConsistencyLevel', 'eventual');

  return headers;
};

const getNameFromDisplayName = (displayName: string | undefined) => {
  const names = displayName?.split(' ');
  return names ? names[1] : 'User';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sortArrayOfObjects = (array: any[], fieldName: string, dir: 'ASC' | 'DESC' = 'ASC') => {
  if (!array.length) {
    return array;
  }

  const modifiers = {
    ASC: 1,
    DESC: -1,
  };

  return [...array].sort((a, b) => {
    const fields = fieldName.split('.');
    let fieldA = a;
    let fieldB = b;

    fields.forEach((field) => {
      fieldA = fieldA[field];
      fieldB = fieldB[field];
    });

    if (typeof fieldA === 'string' && typeof fieldB === 'string') {
      return modifiers[dir] * fieldA.localeCompare(fieldB);
    }

    if (dir === 'DESC') {
      return fieldB - fieldA;
    }

    return fieldA - fieldB;
  });
};

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

const random = (max: number): number => Math.floor(Math.random() * max + 1);

const isISODate = (dateStr: string) => {
  const date = new Date(Date.parse(dateStr));
  return date.toISOString() === dateStr;
};

const generateMonthsValues = () => {
  const months = [];

  for (let i = 0; i < 36; i++) {
    months.push(Math.random().toFixed(2).toString());
  }

  return months;
};

const createTicksArrForSlider = (numberOfYears: number, startYear: Date) => {
  const year = startYear.getFullYear();
  const numberOfMonths = numberOfYears * 12;
  let yearsPassed = 0;

  return [...Array(numberOfMonths)].map((_, idx) => {
    // Don't update the year for the first iteration
    // If it's the last month, add another year for better UI/UX
    if ((idx % 12 === 0 && idx !== 0) || idx === numberOfMonths - 1) {
      yearsPassed++;
    }

    return {
      id: idx,
      year: year + yearsPassed,
    };
  });
};

// NOTE: Order of object properties matters
const areObjectsEqualJSON = <T>(obj1: T, obj2: T) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

const recursiveObjectModify = <T extends Record<string, string | object>>(obj: T, config?: Config<T>) => {
  let k: keyof T;
  const { options, defaultOverwrite = '' } = config ?? { defaultOverwrite: '' };

  for (k in obj) {
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      recursiveObjectModify(obj[k] as Record<string, object>, config as Config<Record<string, object>>);
      continue;
    }

    let updateV;
    const found = options?.find(({ key, value }) => {
      const index = key.lastIndexOf('.');
      const currKey = key.slice(index + 1);

      if (k === currKey) {
        updateV = value;
        return true;
      }
    });

    obj[k] = defaultOverwrite as T[keyof T];

    if (found) {
      obj[k] = updateV as unknown as T[keyof T];
    }
  }

  return obj;
};

interface Opt<T> {
  key: DotNestedKeys<T>;
  value: unknown;
}

interface Config<T> {
  options?: Opt<T>[];
  defaultOverwrite?: unknown;
}

/**
 * Clones object and sets all key values to empty string "".
 *
 * If you want to overwrite value of object's key, you can pass an array of option objects
 * that consists of key - which targets the value you want to modify, and a value that modifies the value
 *
 * For example:
 *
 * const example = { a: { a1: "a1", a2: 2 }, b: true };
 *
 * copyAndSetObjectValues(example, {options: [{ key: "a.a1", value: "new value" }]})
 *
 * Will return:
 *
 * { a: { a1: 'new value', a2: '' }, b: '' }
 * @param {T} obj - Object to clone.
 * @param {{ options?: {key: DotNestedKeys<T>, value: unknown}, defaultOverwrite?: unknown }} config - Config object that let you target keys or set defaultOverwrite value
 */
const copyAndSetObjectValues = <T>(obj: T, config?: Config<T>) => {
  return recursiveObjectModify(JSON.parse(JSON.stringify(obj)), config);
};

const createParkEanCode = (value: string) => {
  let srEanCode = `DE99${value
    .split(' ')
    .map((w) =>
      w.toLowerCase().replace(/\b(?:wp|windpark|sp|solarpark|pv|pva|photovoltaikanlage|bwp|bürgerwindpark)\b/g, ''),
    )
    .join(',')
    .replace(/[ |\-|.|=|!|@|#|$|%|^|&|*|(|)|_|=|+|:|'|"|,|<|>|?|£|§|{|}|/|\\]/g, '')
    .toUpperCase()}`;

  for (let i = srEanCode.length; i < EAN_CODE_LENGTH; i++) {
    srEanCode += '9';
  }

  return srEanCode.slice(0, EAN_CODE_LENGTH);
};

const createSrEanCode = (value: string) => {
  let srEanCode = `DE99${value}`;

  for (let i = srEanCode.length; i < EAN_CODE_LENGTH; i++) {
    srEanCode += '9';
  }

  return srEanCode;
};

const shouldPrefillForm = config.SHOULD_PREFILL_FORM === 'true' ? true : false;

// TODO: Expose react-table types
const customNameSort = (
  rowA: { values: Record<string, unknown> },
  rowB: { values: Record<string, unknown> },
  columnId: string,
) => {
  const a = String(rowA.values[columnId]).toLowerCase();
  const b = String(rowB.values[columnId]).toLowerCase();
  return a > b ? 1 : -1;
};

const sortObjectsByProperty = <T, K extends keyof T>(arr: T[], key: K, direction: 'asc' | 'desc' = 'asc') => {
  return arr.sort((a, b) => {
    const propA = String(a[key]).toLowerCase();
    const propB = String(b[key]).toLowerCase();
    let comparison = 0;

    if (propA < propB) {
      comparison = -1;
    } else if (propA > propB) {
      comparison = 1;
    }

    return direction === 'asc' ? comparison : -comparison;
  });
};

export {
  areObjectsEqualJSON,
  capitalize,
  copyAndSetObjectValues,
  createParkEanCode,
  createSrEanCode,
  createTicksArrForSlider,
  customNameSort,
  dashSpacedToCapitalize,
  formatBytes,
  generateMonthsValues,
  getBase64,
  getNameFromDisplayName,
  isISODate,
  parseJwt,
  prepGraphHeaders,
  prepHeaders,
  random,
  shouldPrefillForm,
  sortArrayOfObjects,
  sortObjectsByProperty,
  throttle,
};
