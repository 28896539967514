import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { ContractDTO, ContractStagingDTO } from 'markets-lib';

import { config } from '../config';
import { ListResponse } from '../types';
import { prepHeaders } from '../util';

export const contractsApi = createApi({
  reducerPath: 'contracts',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
  }),
  tagTypes: ['Contracts'],
  endpoints: (builder) => ({
    upsert: builder.mutation<ContractDTO, ContractDTO>({
      query: (contract) => ({
        url: '/api/contracts',
        method: 'POST',
        body: contract,
      }),
      invalidatesTags: ['Contracts'],
    }),
    getAll: builder.query<ListResponse<ContractDTO>, { page: number; search: string }>({
      query: ({ page, search }) =>
        page
          ? `/api/contracts?page=${page}${search && `&search=${search}`}`
          : `/api/contracts${search && `?search=${search}`}`,
      providesTags: ['Contracts'],
    }),
    getById: builder.query<ContractDTO, ContractDTO['id']>({
      query: (id) => `/api/contracts/${id}`,
      providesTags: ['Contracts'],
    }),
    remove: builder.mutation<ContractDTO[], ContractDTO['id']>({
      query: (id) => ({
        url: `/api/contracts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Contracts'],
    }),
    mergeIntoProd: builder.mutation<ContractDTO, ContractStagingDTO>({
      query: (contract) => ({
        url: '/api/contracts/merge',
        method: 'POST',
        body: contract,
      }),
      invalidatesTags: ['Contracts'],
    }),
  }),
});

export const {
  endpoints,
  middleware,
  reducer,
  reducerPath,
  useUpsertMutation,
  useRemoveMutation,
  useGetAllQuery,
  useGetByIdQuery,
  useMergeIntoProdMutation,
} = contractsApi;
