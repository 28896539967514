"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = exports.SuperAdmin = exports.GraphUser = exports.GenerateAccessLink = exports.ActiveDirectoryUser = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
exports.ActiveDirectoryUser = zod_1.z.object({
    id: util_1.uuid,
    email: zod_1.z.string(),
    name: zod_1.z.string(),
});
exports.GenerateAccessLink = zod_1.z.object({
    email: zod_1.z.string(),
});
exports.GraphUser = zod_1.z.object({
    id: zod_1.z.string(),
    displayName: zod_1.z.string(),
    givenName: zod_1.z.string(),
    mail: zod_1.z.string(),
    surname: zod_1.z.string(),
});
exports.SuperAdmin = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
});
exports.User = zod_1.z.object({
    id: util_1.uuid,
    email: zod_1.z.string(),
    isAdmin: zod_1.z.boolean(),
    isUser: zod_1.z.boolean(),
    name: zod_1.z.string(),
});
