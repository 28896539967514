"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.randomString = exports.zodDate = exports.zodNullToStringNonempty = exports.emptyStringToNull = exports.uuid = exports.errorify = void 0;
var zod_1 = require("zod");
var errorify = function (err) {
    if (err instanceof Error) {
        return err;
    }
    else {
        return new Error("Unknown error: ".concat(err));
    }
};
exports.errorify = errorify;
exports.uuid = zod_1.z
    .string()
    .regex(new RegExp(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/));
exports.emptyStringToNull = zod_1.z.literal('').transform(function () { return null; });
exports.zodNullToStringNonempty = zod_1.z.preprocess(function (arg) {
    if (arg === null) {
        return '';
    }
    return arg;
}, zod_1.z.string().nonempty('commonErrors.empty'));
exports.zodDate = zod_1.z.preprocess(function (arg) {
    if (typeof arg == 'string' || arg instanceof Date) {
        return new Date(arg);
    }
}, zod_1.z.date({ required_error: 'commonErrors.selectDate' }));
/**
 * Pseudo-random string generator
 * http://stackoverflow.com/a/27872144/383904
 * Default: return a random alpha-numeric string
 *
 * @param {Integer} len Desired length
 * @param {String} an Optional (alphanumeric), "a" (alpha), "n" (numeric)
 * @return {String}
 */
function randomString(len, an) {
    if (an === void 0) { an = 'n'; }
    var type = an && an.toLowerCase();
    var str = '';
    var min = type == 'a' ? 10 : 0;
    var max = type == 'n' ? 10 : 62;
    for (var i = 0; i++ < len;) {
        var r = (Math.random() * (max - min) + min) << 0;
        str += String.fromCharCode((r += r > 9 ? (r < 36 ? 55 : 61) : 48));
    }
    return str;
}
exports.randomString = randomString;
