// @vf-alchemy/vattenfall-design-system/scss/_variables.scss

export const theme = {
  colors: {
    // https://digitaldesign.vattenfall.com/designers/colours
    // main colors
    primary: '$primarySolarYellow',
    secondary: '$primaryOceanBlue',
    text: '$primaryCoalBlack',
    background: '$primaryAuraWhite',
    border: '#dee2e6', // gray-300 ???
    line: '$tertiaryLighterGray',

    // raw colors
    primarySolarYellow: '#ffda00',
    primaryOceanBlue: '#2071b5',
    primaryAuraWhite: '#ffffff',
    primaryCoalBlack: '#000000',
    primaryMagneticGray: '#4e4b48',
    secondaryDarkGreen: '#005c63',
    secondaryDarkBlue: '#1e324f',
    secondaryPink: '#d1266b',
    secondaryDarkPurple: '#85254b',
    secondaryGreen: '#3dc07c',
    secondaryRed: '#f93b18',
    secondaryPurple: '#9b62c3',
    tertiaryAshBlue: '#869bad',
    tertiaryDarkAshBlue: '#69788c',
    tertiaryLighterGray: '#e6e6e6',
    tertiaryLightGray: '#cccccc',
    tertiaryMediumGray: '#999999',
    tertiaryMediumDarkGray: '#767676',
    tertiaryDarkGray: '#666666',
    lightYellow: '#fffee5',
    lightGreen: '#edf9f3',
    lightBlue: '#edf1f6',
    lightBlueAlt: '#ebf2f3',
    lightRed: '#fef0ea',
    lightGray: '#f2f2f2',
    lightTurquoise: '#ebf2f3',
    energyHydro: '#2da55d',
    energyWind: '#4fcc51',
    energySolar: '#81e0a8',
    energyBiomass: '#375e4e',
    energyCoal: '#e88a74',
    energyGas: '#d85067',
    energyNuclear: '#e6e6e6',
    energyDistrictHeating: '#a376cc',

    // input
    errorRedBorder: '#ffb2a4',
    warningYellowBorder: '#f5a623',
    hoverBorder: '#222222',
    labelBlue: '#1964a3',
    dropdownInputDisabled: '#f1f1f1',
    darkBorderGrey: '#bcbcbc',
    lightBorderGrey: '#e8e8e9',

    // table and accordion
    itemBorder: '#d8d8d8',

    // NOTE: mapping from current version of vattenfall design
    white: '$primaryAuraWhite',
    black: '$primaryCoalBlack',
    grey: '$tertiaryMediumGray',
    yellow: '#ffda00',
    blue: '#2071b5',
    green: '#28a745',
    orange: '#ffc107',
    red: '#f93b18',
    pink: '#d1266b',
    purple: '#9b62c3',
  },
  fonts: {
    VattenfallHall: 'Vattenfall Hall',
    VattenfallHallDisplay: 'Vattenfall Hall Display',
    default:
      '$VattenfallHall, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    display: '$VattenfallHallDisplay',
    monospace: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  },

  fontSizes: {
    xs: '0.5rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.25rem',
    xl: '1.5rem',
  },

  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 600,
    heavy: 700,
  },

  space: {
    none: '0px',
    xs: '8px',
    sm: '12px',
    md: '16px',
    lg: '20px',
    xl: '20px',
    xxl: '32px',
  },
};

export const darkTheme = {
  colors: {
    background: '$primaryCoalBlack',
    text: '$primaryAuraWhite',
  },
};

// const baseColors = {
//   black: '#343434',
//   blue: '#007bff',
//   blueGrey: '#69788c',
//   borderDarkGrey: '#999999',
//   borderGrey: '#cdd1d4',
//   buttonBlue: '#1964a3',
//   darkBlue: '#1e324f',
//   darkBorderGrey: '#bcbcbc',
//   darkGreen: '#3dc07c',
//   darkMoss: '#005c63',
//   darkPink: '#d1266b',
//   darkPurple: '#9b62c3',
//   darkRed: '#f93b18',
//   'dropdown-input-disabled': '#f1f1f1',
//   'dropdown-item-border': '#a4a9ac',
//   'dropdown-item-border-before': '#d8d8d8',
//   'dropdown-underline-border': '#869bad',
//   errorRedBorder: '#ffb2a4',
//   grey100: '#f8f9fa',
//   grey200: '#e6eaed',
//   grey300: '#dee2e6',
//   grey400: '#ced4da',
//   grey500: '#adb5bd',
//   grey600: '#6c757d',
//   grey700: '#495057',
//   grey800: '#343a40',
//   grey900: '#212529',
//   green: '#28a745',
//   inputGrey: '#cccccc',
//   lightBlue: '#edf1f6',
//   lightBorderGrey: '#e8e8e9',
//   lightGreen: '#edf9f3',
//   lightGrey: '#f2f2f2',
//   lightRed: '#fef0ea',
//   lightTurquoise: '#ebf2f3',
//   lightYellow: '#fffee5',
//   lighterYellow: '#faf9dc',
//   metadataGrey: '#888888',
//   midBlue: '#2071b5',
//   sunflowerYellow: '#ffda00',
//   // tooltipBlueBg: '#f1f8ff',
//   // tooltipBlueBorder: '#c5dcee',
//   // tooltipGreen: '#c5eecc',
//   // tooltipRedBg: '#fff5f1',
//   // tooltipRedBorder: '#eec8c5',
//   warningYellow: '#f5a623',
//   warningYellowBorder: '#ffb2a4',
//   white: '#fff',
//   yellow: '#ffc107',
// };

// const colors = {
//   ...baseColors,
//   danger: baseColors.darkRed,
//   light: baseColors.grey100,
//   primary: baseColors.sunflowerYellow,
//   secondary: baseColors.midBlue,
//   success: baseColors.green,
//   warning: baseColors.yellow,
// };

// colors: {
//     black: '#343434',
//     blue: '#007bff',
//     blueGrey: '#69788c',
//     borderDarkGrey: '#999999',
//     borderGrey: '#cdd1d4',
//     buttonBlue: '#1964a3',
//     danger: '$darkRed',
//     darkBlue: '#1e324f',
//     darkBorderGrey: '#bcbcbc',
//     darkGreen: '#3dc07c',
//     darkMoss: '#005c63',
//     darkPink: '#d1266b',
//     darkPurple: '#9b62c3',
//     darkRed: '#f93b18',
//     dropdownInputDisabled: '#f1f1f1',
//     dropdownItemBorderBefore: '#d8d8d8',
//     dropdownItemBorder: '#a4a9ac',
//     dropdownUnderlineBorder: '#869bad',
//     errorRedBorder: '#ffb2a4',
//     green: '#28a745',
//     grey100: '#f8f9fa',
//     grey200: '#e6eaed',
//     grey300: '#dee2e6',
//     grey400: '#ced4da',
//     grey500: '#adb5bd',
//     grey600: '#6c757d',
//     grey700: '#495057',
//     grey800: '#343a40',
//     grey900: '#212529',
//     inputGrey: '#cccccc',
//     light: '$grey100',
//     lightBlue: '#edf1f6',
//     lightBorderGrey: '#e8e8e9',
//     lighterYellow: '#faf9dc',
//     lightGreen: '#edf9f3',
//     lightGrey: '#f2f2f2',
//     lightRed: '#fef0ea',
//     lightTurquoise: '#ebf2f3',
//     lightYellow: '#fffee5',
//     metadataGrey: '#888888',
//     midBlue: '#2071b5',
//     primary: '$sunflowerYellow',
//     secondary: '$midBlue',
//     success: '$green',
//     sunflowerYellow: '#ffda00',
//     tooltipBlueBg: '#f1f8ff',
//     tooltipBlueBorder: '#c5dcee',
//     tooltipGreen: '#c5eecc',
//     tooltipRedBg: '#fff5f1',
//     tooltipRedBorder: '#eec8c5',
//     warning: '$yellow',
//     warningYellow: '#f5a623',
//     warningYellowBorder: '#ffb2a4',
//     white: '#fff',
//     yellow: '#ffc107',
//   },

// energy: {
//       hydro: '#2da55d',
//       wind: '#4FCC51',
//       solar: '#81E0A8',
//       biomass: '#375E4E',
//       coal: '#E88A74',
//       gas: '#D85067',
//       nuclear: '#E6E6E6',
//       districtHeating: '#A376CC',
//     },
