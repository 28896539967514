import { Children, cloneElement, isValidElement, useMemo } from 'react';

import { Breakpoint } from '../breakpoints';
import {
  createValueByBreakpointFull,
  mapValueByBreakpointListToCss,
  mapValueByBreakpointToCss,
  ValueByBreakpoint,
} from '../breakpointsUtils';
import { DefaultProps, PropertyValue } from '../stitches.config';
import { useTheme } from '../ThemeProvider';
import { mergeCss } from '../util';
import { Box } from './Box';
import { Stack } from './Stack';

export type ColSpan = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

// { xs: '$xs', ... }
const defaultSpace = createValueByBreakpointFull((bp: Breakpoint) => `$${bp}`);

export const Grid = ({
  children,
  colGap,
  colSpan,
  css,
  padding,
  rowAlign,
  rowGap,
  rowJustify,
  ...props
}: {
  colGap?: PropertyValue<'gap'>;
  colSpan?: ValueByBreakpoint<ColSpan>;
  padding?: ValueByBreakpoint<PropertyValue<'paddingLeft'>>;
  rowAlign?: PropertyValue<'alignContent'>;
  rowGap?: PropertyValue<'gap'>;
  rowJustify?: PropertyValue<'justifyContent'>;
} & DefaultProps) => {
  const rows =
    children &&
    Children.toArray(children).map((row) =>
      isValidElement(row)
        ? cloneElement(row, {
            align: rowAlign,
            colGap,
            colSpan,
            justify: rowJustify,
            ...row.props,
          })
        : row,
    );

  return (
    <Stack
      axis='y'
      gap={rowGap}
      css={mergeCss(
        mapValueByBreakpointToCss(
          (value) => ({
            padding: value,
          }),
          defaultSpace,
          padding,
        ),
        mapValueByBreakpointToCss(
          (value) => ({
            gap: value,
          }),
          defaultSpace,
          rowGap,
        ),
        css,
      )}
      {...props}
    >
      {rows}
    </Stack>
  );
};

export const Row = ({
  align = 'stretch',
  children,
  colGap,
  colSpan,
  css,
  justify = 'center',
  ...props
}: {
  align?: PropertyValue<'alignContent'>;
  colGap?: PropertyValue<'gap'>;
  colSpan?: ValueByBreakpoint<ColSpan>;
  justify?: PropertyValue<'justifyContent'>;
} & DefaultProps) => {
  const colsNum = Children.toArray(children).length;
  const cols =
    children &&
    Children.toArray(children).map((col) =>
      isValidElement(col)
        ? cloneElement(col, {
            colGap,
            rowColsNum: colsNum,
            span: colSpan,
            ...col.props,
          })
        : col,
    );

  return (
    <Box
      css={mergeCss(
        {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: justify,
          alignItems: align,
        },
        mapValueByBreakpointToCss(
          (value) => ({
            gap: value,
          }),
          defaultSpace,
          colGap,
        ),
        css,
      )}
      {...props}
    >
      {cols}
    </Box>
  );
};

export const Col = ({
  colGap: colGap_,
  css,
  offset,
  grow,
  rowColsNum = 1,
  span = 'content',
  ...props
}: {
  colGap?: PropertyValue<'gap'>;
  grow?: boolean;
  offset?: ValueByBreakpoint<0 | ColSpan>;
  rowColsNum?: number;
  span?: ValueByBreakpoint<ColSpan | 'content'>;
} & DefaultProps) => {
  const { getTokenValue } = useTheme();

  // Stitches won't resolve the token value because gap belongs to a different scale than
  // flexBasis and maxWidth (space vs sizes)
  const colGap = useMemo(() => {
    const tokenValue = typeof colGap_ === 'string' ? getTokenValue('space', colGap_) : undefined;
    return tokenValue !== undefined ? tokenValue : colGap_;
  }, [colGap_, getTokenValue]);

  const defaultSpaceRaw = createValueByBreakpointFull((bp: Breakpoint) => getTokenValue('space', bp));

  return (
    <Box
      css={mergeCss(
        {
          boxSizing: 'border-box',
          flexGrow: grow ? 1 : 0,
        },
        mapValueByBreakpointListToCss(
          ([spanValue, colGapValue]: [ColSpan | 'content', PropertyValue<'gap'>]) => ({
            $$width:
              typeof spanValue === 'number'
                ? `calc(100% * ${spanValue} / 12 - ${colGapValue} * ${rowColsNum - 1} / ${rowColsNum})`
                : undefined,
            flexBasis: spanValue === 'content' ? 'content' : '$$width',
            flexShrink: 0,
            maxWidth: grow || spanValue === 'content' ? '100%' : '$$width',
          }),
          [createValueByBreakpointFull<ColSpan | 'content'>('content'), defaultSpaceRaw],
          [span, colGap],
        ),
        mapValueByBreakpointToCss(
          (value) => ({
            marginLeft: `calc(100% / 12 * ${value})`,
          }),
          createValueByBreakpointFull<0>(0),
          offset,
        ),
        css,
      )}
      {...props}
    />
  );
};
