"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationDTO = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
exports.LocationDTO = zod_1.z.object({
    id: util_1.uuid.optional(),
    meteringPointId: zod_1.z
        .string()
        .regex(/^[0-9]{11}$/, 'shareForm.errors.meteringPointId')
        .or(util_1.emptyStringToNull)
        .optional()
        .nullable(),
    // NOTE:
    // This is to avoid circural types, I have no idea how to handle it otherwise.
    // Omitting locationStaging doesn't work
    // https://gist.github.com/colinhacks/0041e748e9c10296dea9beb5a10f483f -> doesn't work
    // https://github.com/colinhacks/zod#recursive-types -> doesn't work
    locationStaging: zod_1.z
        .object({
        id: util_1.uuid.optional(),
        meteringPointId: zod_1.z
            .string()
            .regex(/^[0-9]{11}$/, 'shareForm.errors.meteringPointId')
            .or(util_1.emptyStringToNull)
            .optional()
            .nullable(),
        parkId: util_1.uuid.optional().nullable(),
    })
        .optional()
        .nullable(),
    parkId: util_1.uuid.optional().nullable(),
});
