import { Context, createContext, ReactNode, useContext, useEffect } from 'react';

import { globalStyles } from './globalStyles';
import { GlobalCSS, globalCss, ScaleValue, Theme } from './stitches.config';
import { theme } from './theme';

export interface ThemeContextType {
  id: number;
  theme: Theme;
  getTokenValue: <Scale extends keyof Theme>(scale: Scale, tokenName: string) => ScaleValue<Scale> | undefined;
}

export const ThemeContext: Context<ThemeContextType> = createContext<ThemeContextType>({
  id: Math.random(),
  theme,
  getTokenValue: () => undefined,
});

export const ThemeProvider = ({ css = [globalStyles], children }: { css?: GlobalCSS[]; children?: ReactNode }) => {
  useEffect(() => {
    globalCss(...css)();
  }, [css]);

  const context = {
    id: Math.random(),
    theme,
    getTokenValue<Scale extends keyof Theme>(scale: Scale, tokenName_: string) {
      const tokenName = tokenName_.startsWith('$') ? tokenName_.slice(1) : tokenName_;
      return tokenName in theme[scale] ? Object(theme[scale])[tokenName] : undefined;
    },
  };

  return <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => useContext(ThemeContext);
