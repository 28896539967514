import { keyframes } from '@stitches/react';
import classNames from 'classnames';
import { ReactElement, useRef } from 'react';
import { CSSTransition, TransitionStatus } from 'react-transition-group';

import { CSS, css, DefaultProps } from '../stitches.config';
import { HTMLTag } from '../types';
import { mergeCss } from '../util';
import { Box } from './Box';

const OverlayCover = ({ css, on, opacity = 1, ...props }: { on: boolean; opacity?: number } & DefaultProps) => (
  <Box
    css={mergeCss(
      {
        gridArea: '1 / 1',
        center: true,
        opacity,
        zIndex: 2,
        visibility: on ? 'unset' : 'hidden',
        '*': {
          alignSelf: 'center',
          justifySelf: 'center',
        },
      },
      css,
    )}
    {...props}
  />
);

const OverlayCoverTransition = ({
  className,
  on,
  timeoutMs = 300,
  transitionCss,
  ...props
}: {
  on: boolean;
  opacity?: number;
  timeoutMs?: number;
  transitionCss: { [transitionStatus in TransitionStatus]?: CSS };
} & DefaultProps) => {
  const ref = useRef(null);

  return (
    <CSSTransition
      in={on}
      nodeRef={ref} // needed in Strict Mode https://github.com/reactjs/react-transition-group/issues/668
      timeout={timeoutMs}
    >
      {(transitionStatus) => (
        <Box
          className={classNames(
            css({
              gridArea: '1 / 1',
              fill: true,
              zIndex: 1,
              variants: {
                transitionStatus: transitionCss,
              },
            })({ transitionStatus }),
            className,
          )}
          ref={ref}
        >
          <OverlayCover css={{ fill: true }} on {...props} />
        </Box>
      )}
    </CSSTransition>
  );
};

const slideIn = keyframes({
  '100%': { transform: 'translateX(0%)' },
});

const slideOut = keyframes({
  '0%': { transform: 'translateX(0%)' },
  '100%': { transform: 'translateX(-100%)' },
});

export type OverlayProps<C extends HTMLTag = 'div'> = {
  animation?: 'fade' | 'slide';
  animationMs?: number;
  cover?: ReactElement;
  on: boolean;
  opacity?: number;
} & DefaultProps<C>;

export const Overlay = function <C extends HTMLTag>({
  animation,
  animationMs = 300,
  children,
  className,
  cover,
  on,
  opacity = 1,
  ...props
}: OverlayProps<C>) {
  return (
    <Box
      className={classNames(
        css({
          display: 'grid',
          gridAutoColumns: '100%',
          gridAutoRows: '100%',
          overflow: 'hidden',
        })(),
        className,
      )}
      {...props}
    >
      {animation === 'fade' && (
        <OverlayCoverTransition
          className={css({
            opacity: 0,
          })()}
          on={on}
          transitionCss={{
            entering: {
              opacity,
              transition: `opacity ${animationMs}ms linear`,
            },
            entered: {
              opacity,
            },
            exiting: {
              opacity: 0,
              transition: `opacity ${animationMs}ms linear`,
            },
            exited: {
              opacity: 0,
              visibility: 'hidden',
            },
          }}
        >
          {cover}
        </OverlayCoverTransition>
      )}
      {animation === 'slide' && (
        <OverlayCoverTransition
          className={css({
            opacity,
            transform: 'translateX(-100%)',
          })()}
          on={on}
          transitionCss={{
            entering: {
              animation: `${slideIn} ${animationMs}ms forwards`,
            },
            entered: {
              transform: 'translateX(0%)',
            },
            exiting: {
              animation: `${slideOut} ${animationMs}ms forwards`,
            },
            exited: {
              visibility: 'hidden',
            },
          }}
        >
          {cover}
        </OverlayCoverTransition>
      )}
      {!animation && (
        <OverlayCover on={on} opacity={opacity}>
          {cover}
        </OverlayCover>
      )}
      <Box
        css={{
          gridArea: '1 / 1',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
