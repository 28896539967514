import { PropertyValue } from '@stitches/react';
import _ from 'lodash/fp';
import { match } from 'ts-pattern';

import { border } from './cssHelpers';

export const utils = {
  border: (value: PropertyValue<'border'> | boolean) => ({
    border: match(value)
      .with(true, () => border())
      .with(false, () => undefined)
      .otherwise(_.identity),
  }),
  centerX: (value: boolean) =>
    value
      ? {
          display: 'flex',
          justifyContent: 'center',
        }
      : {},
  centerY: (value: boolean) =>
    value
      ? {
          display: 'flex',
          alignItems: 'center',
        }
      : {},
  center: (value: boolean) =>
    value
      ? {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }
      : {},
  fillX: (value: boolean) => ({
    width: value ? '100%' : undefined,
  }),
  fillY: (value: boolean) => ({
    height: value ? '100%' : undefined,
  }),
  fill: (value: PropertyValue<'fill'> | boolean) =>
    match(typeof value)
      .with('boolean', () => ({ fillX: value, fillY: value }))
      .otherwise(() => ({ fill: value })),
  marginX: (value: PropertyValue<'marginLeft'>) => ({
    marginLeft: value,
    marginRight: value,
  }),
  marginY: (value: PropertyValue<'marginBottom'>) => ({
    marginBottom: value,
    marginTop: value,
  }),
  paddingX: (value: PropertyValue<'paddingLeft'>) => ({
    paddingLeft: value,
    paddingRight: value,
  }),
  paddingY: (value: PropertyValue<'paddingBottom'>) => ({
    paddingBottom: value,
    paddingTop: value,
  }),
  size: (value: PropertyValue<'width'>) => ({
    height: value,
    width: value,
  }),
} as const;
