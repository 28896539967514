import { CSS, keyframes } from '@stitches/react';
import classNames from 'classnames';
import _ from 'lodash';
import RCSelect, { SelectProps } from 'rc-select';
import { forwardRef, ReactNode } from 'react';

import { calculateFormElementsHeight, calculateFormElementsPadding } from '..';
import { calculateFormElementsPaddingHorizontal } from '../cssHelpers';
import { css as stitchesCss } from '../stitches.config';
import { theme } from '../theme';
import { Box } from './Box';
import { IconCheck, IconClose, IconDown } from './icons';

export type SelectComponentProps = {
  css?: CSS;
  error?: boolean;
  fontSize?: 'sm' | 'md' | 'lg' | 'xl';
  label?: string;
  loadingMessage?: string;
  stopHeightGrow?: boolean;
  tight?: boolean;
  variant?: 'underline';
  warning?: boolean;
} & SelectProps;

const rcSelectLoadingIcon = keyframes({
  '0%': { transform: 'rotate(0)' },
  '100%': { transform: 'rotate(360deg)' },
});

const calculateMinHeight = (fontSize: 'sm' | 'md' | 'lg' | 'xl') => {
  if (fontSize === 'sm') {
    return '200px';
  }
  if (fontSize === 'md') {
    return '220px';
  }
  if (fontSize === 'lg') {
    return '260px';
  }

  return '300px';
};

const createDropdownMessage = ({
  loading,
  loadingMessage,
  notFoundContent,
}: {
  loading?: boolean;
  loadingMessage?: string;
  notFoundContent?: ReactNode;
}) => {
  if (loading) {
    return (
      <Box
        css={{
          backgroundColor: '$background',
          border: '1px solid $tertiaryMediumGray',
          padding: '30px 0',
          borderRadius: '4px',
        }}
      >
        {loadingMessage || 'Loading content'}
      </Box>
    );
  }

  if (notFoundContent) {
    return notFoundContent;
  }

  return (
    <Box
      css={{
        backgroundColor: '$background',
        borderRadius: '4px',
        border: '1px solid $tertiaryMediumGray',
        padding: '30px 0',
      }}
    >
      Not found
    </Box>
  );
};

export const Select = forwardRef<HTMLElement, SelectComponentProps>(function Select(
  {
    allowClear,
    children,
    className,
    clearIcon = <IconClose className='rc-select-clear-icon' />,
    css,
    error,
    fontSize = 'lg',
    inputIcon = (
      <Box className='rc-select-arrow-icon'>
        <IconDown />
      </Box>
    ),
    label,
    loading,
    loadingMessage,
    menuItemSelectedIcon = <IconCheck className='rc-select-option-icon' />,
    notFoundContent,
    removeIcon = <IconClose className='rc-select-selection-item-remove-icon' />,
    showArrow,
    showSearch,
    stopHeightGrow,
    tight = false,
    variant,
    warning,
    ...props
  },
  ref,
) {
  return (
    <Box
      ref={ref}
      css={css}
      className={classNames(
        stitchesCss({
          width: '100%',
          position: 'relative',

          // ============= BASE ===============

          '.rc-select': {
            position: 'relative',
            cursor: 'pointer',
          },

          '.rc-select-selector': {
            fontFamily: '$VattenfallHall',
            backgroundColor: '$primaryAuraWhite',
            boxSizing: 'border-box',
            color: '#222',
            borderRadius: '4px',
            border: '1px solid $tertiaryLightGray',
            marginBottom: '4px',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            alignItems: 'center',

            '&:hover:not(.rc-select-disabled .rc-select-selector)': {
              borderColor: '$tertiaryMediumGray',
            },
          },

          '.rc-select-open .rc-select-selector': {
            borderColor: '$tertiaryMediumGray',
          },

          '.rc-select-disabled, .rc-select-disabled input': {
            cursor: 'not-allowed',
          },

          '.rc-select-disabled .rc-select-selector': {
            cursor: 'not-allowed',
            color: '$tertiaryMediumDarkGray',
            backgroundColor: '$dropdownInputDisabled',
            borderColor: '$darkBorderGrey',
          },

          '.rc-select .rc-select-selection-search-input': {
            appearance: 'none',
            border: 'none',
            outline: 'none',
          },

          '.rc-select .rc-select-selection-search-input::-webkit-search-cancel-button': {
            display: 'none',
            appearance: 'none',
          },

          '.rc-select .rc-select-selection-placeholder': {
            pointerEvents: 'none',
            position: 'absolute',
            transform: 'translateX(2px)',
            color: '$tertiaryMediumDarkGray',
          },

          '.rc-select-selection-search-input': {
            height: '100%',
            width: '100%',
          },

          '.rc-select-focused .rc-select-selector': {
            borderColor: '$tertiaryMediumGray',
          },

          '.rc-select-selection-item': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          },

          '.rc-select-selection-search': {
            height: '100%',
            width: '90%',
          },

          '.rc-select-selector .rc-select-selection-overflow ': {
            paddingRight: `${allowClear ? '2.2em' : '0'}`,
          },

          // ============= SELECT ===============

          '.rc-select-multiple .rc-select-selector .rc-select-selection-overflow': {
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
          },

          '.rc-select-multiple .rc-select-selector .rc-select-selection-overflow-item': {
            flex: 'none',
            maxWidth: '100%',
          },

          '.rc-select-multiple .rc-select-selector .rc-select-selection-search': {
            position: 'relative',
            maxWidth: '100%',
          },

          '.rc-select-multiple .rc-select-selector .rc-select-selection-search-input, .rc-select-multiple .rc-select-selector .rc-select-selection-search-mirror':
            {
              padding: '1px',
              fontFamily: 'system-ui',
            },

          '.rc-select-multiple .rc-select-selector .rc-select-selection-search-mirror': {
            position: 'absolute',
            zIndex: '999',
            whiteSpace: 'nowrap',
            left: '0',
            top: '0',
            visibility: 'hidden',
          },

          '.rc-select-multiple .rc-select-selector .rc-select-selection-item': {
            backgroundColor: '$energyNuclear',
            flex: 'none',
            borderRadius: '4px',
            margin: '2px',
            padding: '4px 8px',
          },

          '.rc-select-multiple .rc-select-selector .rc-select-selection-item-disabled': {
            cursor: 'not-allowed',
            opacity: '0.5',
          },

          '.rc-select-multiple .rc-select-selector .rc-select-selection-search-input': {
            border: 'none',
            outline: 'none',
            width: '100%',
          },

          // ============= DROPDOWN ===============

          '.rc-select-single .rc-select-selector': {
            display: 'flex',
            position: 'relative',
          },

          '.rc-select-single .rc-select-selector .rc-select-selection-item, .rc-select-single .rc-select-selector .rc-select-selection-placeholder':
            {
              position: 'absolute',
              pointerEvents: 'none',
            },

          '.rc-select-single .rc-select-selection-search-input': {
            paddingLeft: '0',
          },

          '.rc-select-single .rc-select-selection-search': {
            width: '100%',
          },

          // ============= ICONS ===============

          '.rc-select-clear-icon, .rc-select-arrow-icon': {
            position: 'absolute',
            display: 'flex',
            cursor: 'pointer',
            fill: '$tertiaryMediumDarkGray',
            top: '50%',
            transform: 'translateY(calc(-50% - 0.1em))',
          },

          '.rc-select-arrow-icon': {
            pointerEvents: 'none',
            svg: {
              fill: '$tertiaryMediumDarkGray',
            },
          },

          '.rc-select-selection-item-remove-icon': {
            display: 'flex',
            marginLeft: '0.35em',
            width: `calc(${theme.fontSizes[fontSize]} - 0.35em)`,
            height: `calc(${theme.fontSizes[fontSize]} - 0.35em)`,
          },

          '.rc-select-arrow': {
            display: 'none',
          },

          '.rc-select-single .rc-select-selector .rc-select-selection-search': {
            maxWidth: `calc(100% - 15px - calc(${
              ((showArrow || loading) &&
                `calc(${calculateFormElementsPaddingHorizontal(theme.fontSizes[fontSize], false)})`) ??
              '0%'
            } + ${
              (allowClear && `calc(${calculateFormElementsPaddingHorizontal(theme.fontSizes[fontSize], false)})`) ??
              '0%'
            }))`,
          },

          '.rc-select-arrow-icon, .rc-select-clear-icon': {
            width: `calc(${theme.fontSizes[fontSize]} - 0.2em)`,
            right: calculateFormElementsPaddingHorizontal(theme.fontSizes[fontSize], tight),
          },

          variants: {
            fontSize: _.zipObject(
              ['sm', 'md', 'lg', 'xl'],
              _.map<'sm' | 'md' | 'lg' | 'xl', CSS>(['sm', 'md', 'lg', 'xl'], (fontSize) => ({
                'div.rc-select-selector': {
                  minHeight: `calc(${calculateFormElementsHeight({
                    fontSize: theme.fontSizes[fontSize],
                    tight,
                  })} + 18px)`,
                  padding: calculateFormElementsPadding({ fontSize: theme.fontSizes[fontSize], tight }),
                  fontSize: `$${fontSize}`,

                  input: {
                    fontSize: `$${fontSize}`,
                  },
                },

                '.rc-select-arrow-icon': loading && {
                  visibility: 'hidden',
                  height: fontSize === 'lg' || fontSize === 'md' ? '18px' : '20px',
                  display: 'inline-block',
                },

                '.rc-select-clear-icon': {
                  right:
                    showArrow || loading
                      ? `calc(${calculateFormElementsPaddingHorizontal(theme.fontSizes[fontSize], tight)} + calc(${
                          theme.fontSizes[fontSize]
                        } + 0.35em))`
                      : calculateFormElementsPaddingHorizontal(theme.fontSizes[fontSize], tight),
                },

                '.rc-select-selection-search': {
                  width: `calc(100% - calc(${((showArrow || loading) && '6%') ?? '0%'} + ${
                    (allowClear && '6%') ?? '0%'
                  }))`,
                },

                '.rc-select-show-arrow.rc-select-loading .rc-select-arrow-icon::before': {
                  width: `calc(${theme.fontSizes[fontSize]} - 0.15em)`,
                  height: `calc(${theme.fontSizes[fontSize]} - 2px)`,
                },
              })),
            ),

            showArrow: {
              true: {
                '.rc-select-arrow': {
                  display: 'block ',
                },
              },
            },

            stopHeightGrow: {
              true: {
                'div.rc-select-selector': {
                  height: `calc(${calculateFormElementsHeight({
                    fontSize: theme.fontSizes[fontSize],
                    tight,
                  })} + 18px)`,

                  minHeight: '0',
                },

                '.rc-select-multiple .rc-select-selector .rc-select-selection-overflow': {
                  paddingRight: '0',
                  flexWrap: 'nowrap',
                  overflowX: 'auto',
                  maxWidth: `calc(100% - 15px - calc(${
                    ((showArrow || loading) &&
                      `calc(${calculateFormElementsPaddingHorizontal(theme.fontSizes[fontSize], false)})`) ??
                    '0%'
                  } + ${
                    (allowClear &&
                      `calc(${calculateFormElementsPaddingHorizontal(theme.fontSizes[fontSize], false)})`) ??
                    '0%'
                  }))`,

                  '&::-webkit-scrollbar': {
                    height: '10px',
                  },

                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#c1c1c1',
                    border: '2px solid transparent',
                    borderRadius: '5px',
                    backgroundClip: 'padding-box',
                    height: '10px',

                    '&:hover': {
                      backgroundColor: '#7d7d7d',
                    },
                  },
                },
              },
            },

            hideSearch: {
              true: {
                '.rc-select-selection-search-input': {
                  display: 'none',
                },
                '.rc-select-selection-search': {
                  display: 'none',
                },
              },
            },

            variant: {
              underline: {
                '.rc-select-selector': {
                  borderLeft: 'none',
                  borderTop: 'none',
                  borderRight: 'none',
                  borderRadius: '0',
                  paddingLeft: '0 !important',
                },

                '.rc-select-arrow-icon': {
                  right: '0',
                },

                '.rc-select-clear-icon': {
                  right:
                    showArrow || loading
                      ? `calc(${theme.fontSizes[fontSize]} * 1.25)`
                      : calculateFormElementsPaddingHorizontal(theme.fontSizes[fontSize], tight),
                },
              },
            },

            loading: {
              true: {
                '.rc-select-show-arrow .rc-select-arrow-icon::before': {
                  content: "''",
                  border: '4px solid transparent',
                  width: '0',
                  height: '0',
                  display: 'inline-block',
                  borderTopColor: '$tertiaryMediumDarkGray',
                },

                '.rc-select-show-arrow.rc-select-loading .rc-select-arrow-icon::before': {
                  boxSizing: 'border-box',
                  borderRadius: '100%',
                  border: '2px solid $tertiaryMediumDarkGray',
                  borderTopColor: 'transparent',
                  borderBottomColor: 'transparent',
                  animation: `${rcSelectLoadingIcon} 0.5s infinite`,
                  visibility: 'visible',
                },

                '.rc-select-arrow-icon': {
                  visibility: 'hidden',
                },

                '.rc-select-arrow': {
                  display: 'block',
                },
              },
            },

            warning: {
              true: {
                '.rc-select-selector': {
                  border: '1px solid $errorRedBorder',
                  boxShadow: '0 0 $warningYellowBorder',

                  '&:hover:not(.rc-select-disabled .rc-select-selector)': {
                    borderColor: '$warningYellowBorder',
                  },
                },

                '.rc-select-open .rc-select-selector': {
                  borderColor: '$warningYellowBorder',
                  boxShadow: '0 1px $warningYellowBorder',
                  transition: 'box-shadow 0.2s ease-in-out',
                },

                '.rc-select .rc-select-selection-placeholder': {
                  color: '$warningYellowBorder',
                  opacity: '1',
                },

                '.rc-select-disabled .rc-select-selector .rc-select-selection-placeholder': {
                  color: '$text',
                  opacity: '0.4',
                },

                '.rc-select-clear-icon': {
                  fill: '$warningYellowBorder',
                },

                '.rc-select-arrow-icon': {
                  svg: {
                    fill: '$warningYellowBorder',
                  },
                },
              },
            },

            error: {
              true: {
                '.rc-select-selector': {
                  border: '1px solid $errorRedBorder',
                  boxShadow: '0 0 $errorRedBorder',

                  '&:hover:not(.rc-select-disabled .rc-select-selector)': {
                    borderColor: '$secondaryRed',
                  },
                },

                '.rc-select-open .rc-select-selector': {
                  borderColor: '$secondaryRed',
                  boxShadow: '0 1px $secondaryRed',
                  transition: 'box-shadow 0.2s ease-in-out',
                },

                '.rc-select .rc-select-selection-placeholder': {
                  color: '$secondaryRed',
                  opacity: '1',
                },

                '.rc-select-disabled .rc-select-selector .rc-select-selection-placeholder': {
                  color: '$text',
                  opacity: '0.4',
                },

                '.rc-select-clear-icon': {
                  fill: '$secondaryRed',
                },

                '.rc-select-arrow-icon': {
                  svg: {
                    fill: '$secondaryRed',
                  },
                },
              },
            },
          },

          compoundVariants: [
            {
              warning: true,
              variant: 'underline',
              css: {
                '.rc-select-selector': {
                  borderLeft: 'none',
                  borderTop: 'none',
                  borderRight: 'none',
                },
              },
            },
            {
              error: true,
              variant: 'underline',
              css: {
                '.rc-select-selector': {
                  borderLeft: 'none',
                  borderTop: 'none',
                  borderRight: 'none',
                },
              },
            },
            {
              loading: true,
              fontSize: 'sm',
              css: {
                '.rc-select-show-arrow.rc-select-loading .rc-select-arrow-icon::before': {
                  border: '1px solid $tertiaryMediumDarkGray',
                  borderTopColor: 'transparent',
                  borderBottomColor: 'transparent',
                },
              },
            },
            {
              loading: true,
              fontSize: 'md',
              css: {
                '.rc-select-show-arrow.rc-select-loading .rc-select-arrow-icon::before': {
                  border: '1.3px solid $tertiaryMediumDarkGray',
                  borderTopColor: 'transparent',
                  borderBottomColor: 'transparent',
                },
              },
            },
            {
              loading: true,
              fontSize: 'lg',
              css: {
                '.rc-select-show-arrow.rc-select-loading .rc-select-arrow-icon::before': {
                  border: '1.8px solid $tertiaryMediumDarkGray',
                  borderTopColor: 'transparent',
                  borderBottomColor: 'transparent',
                },
              },
            },
          ],
        })({
          fontSize,
          showArrow,
          allowClear,
          loading,
          hideSearch: !showSearch,
          stopHeightGrow,
          variant,
          warning,
          error,
        }),
        className,
      )}
    >
      {label && (
        <Box
          as='label'
          className={classNames(
            stitchesCss({
              backgroundColor: '$primaryAuraWhite',
              cursor: 'default',
              letterSpacing: '0px',
              color: '$labelBlue',
              fontWeight: '$medium',
              fontSize: `calc(${theme.fontSizes[fontSize]} * 0.85)`,
              zIndex: '998',
              variants: {
                variant: {
                  underline: {
                    position: 'absolute',
                    top: '-4px',
                  },
                },

                error: {
                  true: {
                    color: '$secondaryRed !important',
                  },
                },

                warning: {
                  true: {
                    color: '$warningYellowBorder !important',
                  },
                },
              },
            })({ variant, error, warning }),
          )}
        >
          {label}
        </Box>
      )}
      <RCSelect
        allowClear={allowClear}
        showArrow={showArrow}
        showSearch={showSearch}
        loading={loading}
        removeIcon={removeIcon}
        menuItemSelectedIcon={menuItemSelectedIcon}
        inputIcon={inputIcon}
        clearIcon={clearIcon}
        dropdownMatchSelectWidth={false}
        notFoundContent={createDropdownMessage({ loading, loadingMessage, notFoundContent })}
        dropdownRender={(menu) => (
          <Box
            className={classNames(
              stitchesCss({
                '.rc-select-item': {
                  cursor: 'pointer',
                  lineHeight: '1.5',
                  padding: '4px 16px',
                  margin: '0',
                },

                '.rc-virtual-list-holder': {
                  maxHeight: `${calculateMinHeight(fontSize)} !important`,
                  border: '1px solid $tertiaryMediumGray',
                  background: '$background',
                  borderRadius: '4px',

                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#c1c1c1',
                    border: '4px solid transparent',
                    borderRadius: '8px',
                    backgroundClip: 'padding-box',

                    '&:hover': {
                      backgroundColor: '#7d7d7d',
                    },
                  },

                  '&::-webkit-scrollbar': {
                    borderRadius: '0 3px 3px 0',
                    borderLeft: '1px solid #f4f4f4',
                    backgroundColor: '#fafafa',
                  },
                },

                '.rc-select-item-group': {
                  color: '$tertiaryMediumGray',
                  fontWeight: 'bold',
                  cursor: 'default',
                },

                '.rc-select-item-option-active': {
                  background: '$secondary',
                  color: '$background',
                },

                '.rc-select-item-option-disabled': {
                  color: '$tertiaryMediumGray',
                  cursor: 'not-allowed',
                },

                '.rc-select-item-empty': {
                  textAlign: 'center',
                  color: '$tertiaryMediumGray',
                },

                '.rc-select-item-option-state': {
                  marginRight: '10px',
                },

                '.rc-select-item-option-content': {
                  paddingRight: '10px',
                  minHeight: `calc(${theme.fontSizes[fontSize]})`,
                },

                '.rc-select-item-option .rc-select-item-option-state': {
                  position: 'absolute',
                  right: '0',
                  pointerEvents: 'none',
                },

                '.rc-select-item-option': {
                  position: 'relative',
                },

                '.rc-select-item-option-grouped': {
                  paddingLeft: '24px',
                },

                '.rc-select-item-option-active .rc-select-option-icon': {
                  fill: '$background',
                },

                variants: {
                  fontSize: _.zipObject(
                    ['sm', 'md', 'lg', 'xl'],
                    _.map<'sm' | 'md' | 'lg' | 'xl', CSS>(['sm', 'md', 'lg', 'xl'], (fontSize) => ({
                      '.rc-select-item': {
                        fontSize: theme.fontSizes[fontSize],
                      },

                      '.rc-select-option-icon': {
                        width: `calc(${theme.fontSizes[fontSize]})`,
                        height: `calc(${theme.fontSizes[fontSize]})`,
                      },

                      '.rc-select-item-group': {
                        fontSize: `calc(${theme.fontSizes[fontSize]} * 0.75)`,
                      },
                    })),
                  ),
                },
              })({ fontSize }),
            )}
          >
            {menu}
          </Box>
        )}
        {...props}
      >
        {children}
      </RCSelect>
    </Box>
  );
});
