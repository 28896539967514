import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { Contract, ReportDraftDTO } from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

const BASE_PATH = '/api/reports-draft';

export const reportsDraftApi = createApi({
  reducerPath: 'reportsDraft',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
  }),
  tagTypes: ['ReportsDraft'],
  endpoints: (builder) => ({
    prepareReport: builder.query<ReportDraftDTO, Contract['id']>({
      query: (id) => `${BASE_PATH}/prepare-report/${id}`,
      providesTags: ['ReportsDraft'],
    }),
    upsert: builder.mutation<ReportDraftDTO, ReportDraftDTO>({
      query: (payload) => ({
        url: `${BASE_PATH}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['ReportsDraft'],
    }),
  }),
});

export const { endpoints, middleware, reducer, reducerPath, usePrepareReportQuery, useUpsertMutation } =
  reportsDraftApi;
