import { AccountInfo, Configuration, PublicClientApplication } from '@azure/msal-browser';
import { AuthToken, ICrypto } from '@azure/msal-common';
import { errorify } from 'markets-lib';
import { z } from 'zod';

import { config } from './config';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginScopes: string[] = config.MSAL_SCOPES;

const ERRORS_FOR_REDIRECTION = ['InteractionRequiredAuthError', 'ClientAuthError'];

const msalConfig = {
  auth: {
    clientId: config.MSAL_CLIENT_ID,
    tenant: config.MSAL_TENANT,
    authority: `https://login.microsoftonline.com/${config.MSAL_TENANT}`,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
};

class ClientApplication extends PublicClientApplication {
  getBrowserCrypto(): ICrypto {
    return this.browserCrypto;
  }
}

// https://docs.microsoft.com/en-us/azure/active-directory/develop/access-tokens
// https://github.com/uglide/azure-content/blob/master/articles/active-directory/active-directory-token-and-claims.md
// https://github.com/fakecoinbase/DefinitelyTypedslashDefinitelyTyped/blob/master/types/passport-azure-ad/common.d.ts
export const TokenClaims = z.object({
  upn: z.string(),
  name: z.string(),
});
export type TokenClaims = z.infer<typeof TokenClaims>;

class Auth {
  clientApp: ClientApplication;

  constructor(msalConfiguration: Configuration) {
    this.clientApp = new ClientApplication(msalConfiguration);
  }

  getMsalInstance(): ClientApplication {
    return this.clientApp;
  }

  getAccount(): AccountInfo | undefined {
    return this.clientApp.getAllAccounts()[0];
  }

  extractTokenClaims(token: string): TokenClaims {
    return TokenClaims.parse(AuthToken.extractTokenClaims(token, this.clientApp.getBrowserCrypto()));
  }

  async getTokenClaims(): Promise<TokenClaims | undefined> {
    const token = await this.getToken();

    return token ? this.extractTokenClaims(token) : undefined;
  }

  async getToken(): Promise<string | undefined> {
    const account = this.getAccount();

    try {
      const token = await this.clientApp.acquireTokenSilent({
        scopes: loginScopes,
        account,
      });

      return token.accessToken;
    } catch (error) {
      console.groupCollapsed('Auth error');
      console.log(error);
      console.groupEnd();

      // FIXME: Double test this before release
      if (ERRORS_FOR_REDIRECTION.includes(errorify(error).name)) {
        // fallback to interaction when silent call fails
        this.clientApp.acquireTokenRedirect({ scopes: loginScopes });
      } else {
        throw errorify(error);
      }
    }
  }

  async getGraphToken(): Promise<string | undefined> {
    const account = this.getAccount();

    try {
      const token = await this.clientApp.acquireTokenSilent({
        scopes: ['User.ReadBasic.All'],
        account,
      });

      return token.accessToken;
    } catch (error) {
      console.groupCollapsed('Auth error');
      console.log(error);
      console.groupEnd();

      // FIXME: Double test this before release
      if (ERRORS_FOR_REDIRECTION.includes(errorify(error).name)) {
        // fallback to interaction when silent call fails
        this.clientApp.acquireTokenRedirect({ scopes: ['User.ReadBasic.All'] });
      } else {
        throw errorify(error);
      }
    }
  }
}

export const auth = new Auth(msalConfig);
