import { CSS } from '@stitches/react';
import classNames from 'classnames';
import _ from 'lodash';
import { forwardRef } from 'react';

import { css } from '../stitches.config';
import { theme } from '../theme';
import { HTMLTag } from '../types';
import { Box, BoxProps } from './Box';

export type PillProps<C extends HTMLTag = 'input'> = {
  checked: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
} & BoxProps<C>;

// Escape the # char and encode it
const encodeColor = (color: string) => {
  return '%23' + color.slice(1, color.length);
};

export const Pill = forwardRef<HTMLElement, PillProps<HTMLTag>>(function Pill(
  { as = 'input', size = 'lg', checked, ...props },
  ref,
) {
  return (
    <Box
      as={as}
      checked={checked}
      className={classNames(
        css({
          borderRadius: '2em',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          appearance: 'none',
          transition: 'background-position 0.15s ease-in-out',
          backgroundColor: '$tertiaryLightGray',
          backgroundPosition: '0%',
          backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='${encodeColor(
            theme.colors.primaryAuraWhite,
          )}' /%3E%3C/svg%3E")`,

          '&:hover&:not(:disabled)': {
            cursor: 'pointer',
          },

          '&:disabled': {
            opacity: '0.45',
            backgroundColor: '$tertiaryLightGray',
          },

          variants: {
            checked: {
              true: {
                backgroundPosition: '100%',
                backgroundColor: '$primaryOceanBlue',
              },
            },

            size: _.zipObject(
              ['sm', 'md', 'lg', 'xl'],
              _.map<'sm' | 'md' | 'lg' | 'xl', CSS>(['sm', 'md', 'lg', 'xl'], (size) => ({
                width: `calc(${theme.fontSizes[size]} * 3)`,
                height: `calc(${theme.fontSizes[size]} * 1.4)`,
              })),
            ),
          },
        })({ checked, size }),
      )}
      type='checkbox'
      ref={ref}
      {...props}
    />
  );
});
