import { Box, Button, Col, Fluid, Grid, H2, Row, Stack } from '@rcl/rcl';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const WelcomePage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Fluid axis='y'>
      <Box
        css={{
          padding: '10px',
        }}
      >
        <Stack axis='y'>
          <Grid>
            <Col>
              <Row>
                <H2>{t('welcome')}</H2>
              </Row>

              <Row>
                <Button data-testid='login-btn' onClick={() => history.push('/login')}>
                  {t('login')}
                </Button>
              </Row>
            </Col>
          </Grid>
        </Stack>
      </Box>
    </Fluid>
  );
};
