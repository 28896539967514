"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShareStagingForm = exports.ShareStagingDTO = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
var locationStaging_1 = require("./locationStaging");
var sectionStaging_1 = require("./sectionStaging");
var turbineStaging_1 = require("./turbineStaging");
exports.ShareStagingDTO = zod_1.z.object({
    id: util_1.uuid.optional(),
    percentage: zod_1.z
        .number({ required_error: 'commonErrors.empty' })
        .nonnegative('commonErrors.nonnegative')
        .gt(0, 'commonErrors.gt')
        .max(100, 'Max 100%'),
    location: locationStaging_1.LocationStagingDTO.optional().nullable(),
    section: sectionStaging_1.SectionStagingDTO.optional().nullable(),
    turbine: turbineStaging_1.TurbineStagingDTO.optional().nullable(),
    contractId: util_1.uuid.optional(),
    locationId: util_1.uuid.optional().nullable(),
    sectionId: util_1.uuid.optional().nullable(),
    turbineId: util_1.uuid.optional().nullable(),
});
exports.ShareStagingForm = exports.ShareStagingDTO.partial().superRefine(function (val, ctx) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var parseRes = exports.ShareStagingDTO.safeParse(val);
    if (!parseRes.success) {
        parseRes.error.issues.forEach(function (issue) {
            ctx.addIssue(issue);
        });
    }
    // NOTE: meteringPointId is not mandatory if the park unit has a commissioning in future
    if (((_a = val.turbine) === null || _a === void 0 ? void 0 : _a.commissioningDate) && new Date() > val.turbine.commissioningDate) {
        if (((_b = val.location) === null || _b === void 0 ? void 0 : _b.meteringPointId) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['location.meteringPointId'],
                message: 'commonErrors.empty',
            });
        }
        if (((_c = val.turbine) === null || _c === void 0 ? void 0 : _c.eegSystemKey) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['turbine.eegSystemKey'],
                message: 'commonErrors.empty',
            });
        }
        if (((_d = val.turbine) === null || _d === void 0 ? void 0 : _d.controllableResourceId) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['turbine.controllableResourceId'],
                message: 'commonErrors.empty',
            });
        }
        if (((_e = val.turbine) === null || _e === void 0 ? void 0 : _e.technicalResourceId) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['turbine.technicalResourceId'],
                message: 'commonErrors.empty',
            });
        }
        if (((_f = val.turbine) === null || _f === void 0 ? void 0 : _f.marketMasterDataRegisterNumberPerUnit) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['turbine.marketMasterDataRegisterNumberPerUnit'],
                message: 'commonErrors.empty',
            });
        }
        if (((_g = val.turbine) === null || _g === void 0 ? void 0 : _g.currentValueToBeApplied) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['turbine.currentValueToBeApplied'],
                message: 'commonErrors.empty',
            });
        }
    }
    if (((_h = val.section) === null || _h === void 0 ? void 0 : _h.commissioningDate) && new Date() > val.section.commissioningDate) {
        if (((_j = val.location) === null || _j === void 0 ? void 0 : _j.meteringPointId) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['location.meteringPointId'],
                message: 'commonErrors.empty',
            });
        }
        if (((_k = val.section) === null || _k === void 0 ? void 0 : _k.eegSystemKey) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['section.eegSystemKey'],
                message: 'commonErrors.empty',
            });
        }
        if (((_l = val.section) === null || _l === void 0 ? void 0 : _l.controllableResourceId) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['section.controllableResourceId'],
                message: 'commonErrors.empty',
            });
        }
        if (((_m = val.section) === null || _m === void 0 ? void 0 : _m.technicalResourceId) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['section.technicalResourceId'],
                message: 'commonErrors.empty',
            });
        }
        if (((_o = val.section) === null || _o === void 0 ? void 0 : _o.marketMasterDataRegisterNumberPerUnit) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['section.marketMasterDataRegisterNumberPerUnit'],
                message: 'commonErrors.empty',
            });
        }
        if (((_p = val.section) === null || _p === void 0 ? void 0 : _p.currentValueToBeApplied) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['section.currentValueToBeApplied'],
                message: 'commonErrors.empty',
            });
        }
    }
});
