import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { PricingInputDTO } from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

interface ExtraParameters {
  contractId: string;
}

export const pricingInputApi = createApi({
  reducerPath: 'pricingInput',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
  }),
  tagTypes: ['PricingInput'],
  endpoints: (builder) => ({
    upsert: builder.mutation<PricingInputDTO & ExtraParameters, PricingInputDTO>({
      query: (payload) => ({
        url: '/api/pricingInput',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['PricingInput'],
    }),
    getAll: builder.query<PricingInputDTO[], void>({
      query: () => '/api/pricingInput',
      providesTags: ['PricingInput'],
    }),
    getById: builder.query<PricingInputDTO, PricingInputDTO['id']>({
      query: (id) => `/api/pricingInput/${id}`,
      providesTags: ['PricingInput'],
    }),
    remove: builder.mutation<PricingInputDTO[], PricingInputDTO['id']>({
      query: (id) => ({
        url: `/api/pricingInput/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PricingInput'],
    }),
  }),
});

export const {
  endpoints,
  middleware,
  reducer,
  reducerPath,
  useUpsertMutation,
  useRemoveMutation,
  useGetAllQuery,
  useGetByIdQuery,
} = pricingInputApi;
