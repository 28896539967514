import { CSS } from '@stitches/react';
import classNames from 'classnames';
import _ from 'lodash';
import { forwardRef } from 'react';

import {
  calculateFormElementsHeight,
  calculateFormElementsLineHeight,
  calculateFormElementsPadding,
  calculateFormElementsPaddingVertical,
  HTMLTag,
} from '..';
import { calculateFormElementsPaddingHorizontal } from '../cssHelpers';
import { css as stitchesCss } from '../stitches.config';
import { theme } from '../theme';
import { mergeCss } from '../util';
import { BoxProps } from '.';
import { Box } from './Box';

export type TextareaProps<C extends HTMLTag = 'textarea'> = {
  detailedInformation?: string;
  error?: boolean;
  fontSize?: 'sm' | 'md' | 'lg' | 'xl';
  label?: string;
  maxRows?: number;
  minRows?: number;
  placeholder?: string;
  rows?: number;
  tight?: boolean;
  value: string;
  variant?: 'normal' | 'underline';
  warning?: boolean;
} & BoxProps<C>;

export const Textarea = forwardRef<HTMLElement, TextareaProps<HTMLTag>>(function Textarea(
  {
    css,
    detailedInformation,
    error,
    fontSize = 'lg',
    label,
    maxRows = 99999,
    minRows = 1,
    placeholder,
    rows,
    tight = false,
    value,
    variant = 'normal',
    warning,
    ...props
  },
  ref,
) {
  return (
    <Box
      css={mergeCss(
        {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        },
        css,
      )}
    >
      <Box
        as='textarea'
        placeholder={placeholder}
        className={classNames(
          stitchesCss({
            fontFamily: '$VattenfallHall',
            backgroundColor: '$background',
            boxSizing: 'border-box',
            color: '#222',
            borderRadius: '4px',
            border: '1px solid $tertiaryLightGray',
            lineHeight: '24px',
            outline: 'none',
            padding: '16px 20px',
            marginBottom: '4px',

            '&:hover, &:focus': {
              borderColor: '$tertiaryMediumGray',
            },

            '&:focus ~ label': {
              transition: 'all 0.2s ease-in-out, scale 1ms',
              transform: 'scale(0.85)',
              color: '$labelBlue',
              fontWeight: '$medium',
              fontSize: `${theme.fontSizes[fontSize]}`,
              transformOrigin: 'top left',
              top: `calc(-${theme.fontSizes[fontSize]} / 2)`,
              padding: '0 7px',
              width: 'fit-content',
              left: `calc(${calculateFormElementsPaddingVertical(theme.fontSizes[fontSize], tight)} - 2px)`,
            },

            '&:disabled': {
              color: '$tertiaryMediumDarkGray',
              backgroundColor: '$dropdownInputDisabled',
              borderColor: '$darkBorderGrey',

              '& ~ label': {
                backgroundColor: '$dropdownInputDisabled',
                color: '$tertiaryMediumDarkGray',
              },

              '&:hover': {
                borderColor: '$darkBorderGrey',
              },
            },

            variants: {
              fontSize: _.zipObject(
                ['sm', 'md', 'lg', 'xl'],
                _.map<'sm' | 'md' | 'lg' | 'xl', CSS>(['sm', 'md', 'lg', 'xl'], (fontSize) => ({
                  fontSize: `$${fontSize}`,
                  lineHeight: calculateFormElementsLineHeight(theme.fontSizes[fontSize]),
                  height: calculateFormElementsHeight({ fontSize: theme.fontSizes[fontSize], tight, rows }),
                  minHeight: calculateFormElementsHeight({ fontSize: theme.fontSizes[fontSize], tight, rows: minRows }),
                  maxHeight: calculateFormElementsHeight({ fontSize: theme.fontSizes[fontSize], tight, rows: maxRows }),
                  padding: calculateFormElementsPadding({ fontSize: theme.fontSizes[fontSize], tight }),

                  '&:placeholer': {
                    fontSize: `$${fontSize}`,
                  },

                  '~ label': {
                    fontSize: `$${fontSize}`,
                  },
                })),
              ),

              label: {
                true: {
                  '&:focus&::placeholder': {
                    opacity: '1',
                  },

                  '&::placeholder': {
                    opacity: '0',
                  },
                },
              },

              variant: {
                underline: {
                  borderLeft: 'none',
                  borderTop: 'none',
                  borderRight: 'none',
                  borderRadius: '0px',
                  paddingLeft: '0px',
                  paddingRight: '0px',

                  '&:hover': {
                    borderBottomColor: '$tertiaryMediumGray',
                  },

                  '&:focus ~ label': {
                    left: '-8px',
                  },
                },

                normal: {},
              },

              error: {
                true: {
                  border: '1px solid $errorRedBorder',
                  boxShadow: '0 0 $errorRedBorder',

                  '&:hover': {
                    borderColor: '$secondaryRed',
                  },

                  '&:focus': {
                    borderColor: '$secondaryRed',
                    boxShadow: '0 1px $secondaryRed',
                    transition: 'box-shadow 0.2s ease-in-out',
                  },

                  '&:focus ~ label': {
                    color: '$secondaryRed',
                  },
                },
              },

              warning: {
                true: {
                  border: '1px solid $errorRedBorder',
                  boxShadow: '0 0 $warningYellowBorder',

                  '&:hover': {
                    borderColor: '$warningYellowBorder',
                  },

                  '&:focus': {
                    borderColor: '$warningYellowBorder',
                    boxShadow: '0 1px $warningYellowBorder',
                    transition: 'box-shadow 0.2s ease-in-out',
                  },

                  '&:focus ~ label': {
                    color: '$warningYellowBorder',
                  },
                },
              },

              showLabel: {
                true: {
                  '~ label': {
                    transition: 'all 0.2s ease-in-out, scale 1ms',

                    transform: 'scale(0.85)',
                    color: '$labelBlue',
                    fontWeight: '$medium',
                    fontSize: `${theme.fontSizes[fontSize]}`,
                    transformOrigin: 'top left',
                    top: `calc(-${theme.fontSizes[fontSize]} / 2)`,
                    padding: '0 7px',
                    width: 'fit-content',
                    left: `calc(${calculateFormElementsPaddingVertical(theme.fontSizes[fontSize], tight)} - 2px)`,
                  },

                  '&::placeholder': {
                    opacity: '1',
                  },
                },
              },
            },

            compoundVariants: [
              {
                variant: 'underline',
                error: true,
                css: {
                  borderLeft: 'none',
                  borderTop: 'none',
                  borderRight: 'none',

                  '&:hover': {
                    borderBottomColor: '$secondaryRed',
                  },
                },
              },
              {
                variant: 'underline',
                showLabel: true,
                css: {
                  '~ label': {
                    left: '-8px',
                  },
                },
              },
              {
                variant: 'underline',
                warning: true,
                css: {
                  borderLeft: 'none',
                  borderTop: 'none',
                  borderRight: 'none',

                  '&:hover': {
                    borderBottomColor: '$warningYellowBorder',
                  },
                },
              },
            ],
          })({ showLabel: !!value, fontSize, error, variant, warning, label: !!label }),
        )}
        value={value}
        ref={ref}
        {...props}
      />
      {label && (
        <Box
          as='label'
          className={classNames(
            stitchesCss({
              transition: 'all 0.2s ease-in-out, scale 1ms',
              backgroundColor: '$primaryAuraWhite',
              color: '$tertiaryMediumDarkGray',
              cursor: 'default',
              letterSpacing: '0px',
              marginBottom: '0px',
              lineHeight: calculateFormElementsLineHeight(theme.fontSizes[fontSize]),
              top: `calc((${calculateFormElementsPaddingVertical(theme.fontSizes[fontSize], tight)} / 2) + calc(${
                theme.fontSizes[fontSize]
              } / ${tight ? '4' : '2'}))`,
              transformOrigin: 'top left',
              position: 'absolute',
              pointerEvents: 'none',

              variants: {
                variant: {
                  underline: {
                    left: '0px',
                    width: '100%',
                  },

                  normal: {
                    left: `${calculateFormElementsPaddingHorizontal(theme.fontSizes[fontSize], tight)}`,
                  },
                },

                error: {
                  true: {
                    color: '$secondaryRed !important',
                  },
                },

                warning: {
                  true: {
                    color: '$warningYellowBorder !important',
                  },
                },
              },
            })({ variant, error, warning }),
          )}
        >
          {label}
        </Box>
      )}
      {detailedInformation && (
        <Box as='span' css={{ color: '$tertiaryMediumDarkGray' }}>
          {detailedInformation}
        </Box>
      )}
    </Box>
  );
});
