"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Export = exports.ExportWithShortContract = exports.ExportDTO = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
var contract_1 = require("./contract");
exports.ExportDTO = zod_1.z.object({
    id: util_1.uuid.optional(),
    name: zod_1.z.string().nonempty('commonErrors.empty'),
    isDone: zod_1.z.boolean().optional(),
    contracts: zod_1.z.array(util_1.uuid).optional(),
});
exports.ExportWithShortContract = zod_1.z.object({
    id: util_1.uuid.optional(),
    name: zod_1.z.string().nonempty('commonErrors.empty'),
    isDone: zod_1.z.boolean(),
    createdDate: zod_1.z.preprocess(function (arg) {
        if (typeof arg == 'string' || arg instanceof Date) {
            return new Date(arg);
        }
    }, zod_1.z.date()),
    updatedDate: zod_1.z.preprocess(function (arg) {
        if (typeof arg == 'string' || arg instanceof Date) {
            return new Date(arg);
        }
    }, zod_1.z.date()),
    contracts: zod_1.z.array(zod_1.z.object({
        id: util_1.uuid,
        name: zod_1.z.string(),
    })),
});
exports.Export = zod_1.z.object({
    id: util_1.uuid.optional(),
    name: zod_1.z.string().nonempty('commonErrors.empty'),
    isDone: zod_1.z.boolean(),
    createdDate: zod_1.z.preprocess(function (arg) {
        if (typeof arg == 'string' || arg instanceof Date) {
            return new Date(arg);
        }
    }, zod_1.z.date()),
    updatedDate: zod_1.z.preprocess(function (arg) {
        if (typeof arg == 'string' || arg instanceof Date) {
            return new Date(arg);
        }
    }, zod_1.z.date()),
    contracts: zod_1.z.array(contract_1.ContractDTO),
});
