"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BillingStagingForm = exports.NewBillingStagingForm = exports.BillingStaging = void 0;
var ibantools_1 = require("ibantools");
var zod_1 = require("zod");
var util_1 = require("../util");
var billing_1 = require("./billing");
exports.BillingStaging = zod_1.z.object({
    id: util_1.uuid.optional(),
    invoiceRecipient: zod_1.z.enum(billing_1.invoiceRecipients),
    payee: zod_1.z.enum(billing_1.invoiceRecipients),
    iban: zod_1.z.string().nonempty('customerForm.errors.empty').refine(ibantools_1.isValidIBAN, 'customerForm.errors.iban'),
    bic: zod_1.z.string().nonempty('customerForm.errors.empty').refine(ibantools_1.isValidBIC, 'customerForm.errors.bic'),
    accountHolder: zod_1.z.string().optional().nullable(),
    bankName: zod_1.z.string().optional().nullable(),
    vatId: zod_1.z.string().optional().nullable(),
    taxId: zod_1.z.string().optional().nullable(),
    acerId: zod_1.z.string().optional().nullable(),
    commercialRegisterNumber: zod_1.z.string().optional().nullable(),
    invoiceDelivery: zod_1.z.enum(['post', 'mail', 'mailAndPost']),
    bankReceivables: zod_1.z.boolean().optional().nullable(),
    reverseCharge: zod_1.z.boolean().optional().nullable(),
    installationForRemoteControl: zod_1.z.boolean().optional().nullable(),
    parkReceipt: zod_1.z.boolean().optional().nullable(),
    reverseChargeNotice: zod_1.z.boolean().optional().nullable(),
    comment: zod_1.z.string().max(500, 'billingForm.errors.comment').optional().nullable(),
    // NOTE: Used to attach billing instance to contract
    contractId: util_1.uuid.optional(),
});
exports.NewBillingStagingForm = exports.BillingStaging.partial().superRefine(function (val, ctx) {
    var parseRes = exports.BillingStaging.safeParse(val);
    if (!parseRes.success) {
        parseRes.error.issues.forEach(function (issue) {
            ctx.addIssue(issue);
        });
    }
    if (!val.vatId && !val.taxId) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            path: ['vatId'],
            message: 'customerForm.errors.vatIdOrTaxIdMustBeFilled',
        });
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            path: ['taxId'],
            message: 'customerForm.errors.vatIdOrTaxIdMustBeFilled',
        });
    }
});
exports.BillingStagingForm = exports.BillingStaging.partial().superRefine(function (val, ctx) {
    var parseRes = exports.BillingStaging.safeParse(val);
    if (!parseRes.success) {
        parseRes.error.issues.forEach(function (issue) {
            ctx.addIssue(issue);
        });
    }
    if (!val.vatId && !val.taxId) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            path: ['vatId'],
            message: 'customerForm.errors.vatIdOrTaxIdMustBeFilled',
        });
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            path: ['taxId'],
            message: 'customerForm.errors.vatIdOrTaxIdMustBeFilled',
        });
    }
});
