import _ from 'lodash/fp';

import { Breakpoint, breakpointsNames } from './breakpoints';
import { CSS } from './stitches.config';

export type ValueByBreakpointFull<T> = Record<Breakpoint, T>;
export type ValueByBreakpointPartial<T> = Partial<Record<Breakpoint, T>>;
export type ValueByBreakpoint<T> = T | ValueByBreakpointPartial<T> | undefined;

export type ValueByBreakpointList<List> = List extends [infer Head, ...infer Tail]
  ? [ValueByBreakpoint<Head>, ...ValueByBreakpointList<Tail>]
  : [];
export type ValueByBreakpointFullList<List> = List extends [infer Head, ...infer Tail]
  ? [ValueByBreakpointFull<Head>, ...ValueByBreakpointFullList<Tail>]
  : [];

/**
 * createValueByBreakpointFull(v) = { xs: v, ... }
 * createValueByBreakpointFull(fn) = { xs: fn(xs), ... }
 */
export function createValueByBreakpointFull<T>(valueOrFunction: T | ((bp: Breakpoint) => T)): ValueByBreakpointFull<T> {
  const fn = _.isFunction(valueOrFunction) ? valueOrFunction : _.always(valueOrFunction);
  return _.pipe([_.map((bp: Breakpoint) => [bp, fn(bp)]), _.fromPairs])(breakpointsNames);
}

/**
 * fillValueByBreakpoint({}) = { xs: defaultValue[xs], sm: defaultValue[sm], ... }
 * fillValueByBreakpoint({ xs: 1 }) = { xs: 1, sm: 1, ... }
 * fillValueByBreakpoint({ sm: 1, lg: 2 }) = { xs: defaultValue[xs], sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }
 */
export function fillValueByBreakpoint<T>(
  defaultValue: ValueByBreakpointFull<T>,
  value_: ValueByBreakpoint<T>,
): ValueByBreakpointFull<T> {
  /**
   * undef -> {}
   * 1 -> { xs: X }
   * { md: 1 } -> { md: 1 }
   */
  const value = typeof value_ === 'object' ? value_ : value_ === undefined ? {} : { xs: value_ };

  /**
   * Populate higher breakpoints
   *
   * {} -> {}
   * { xs: 1 } -> { xs: 1, sm: 1, md: 1, ... }
   * { md: 2 } -> { md: 2, lg: 2, ... }
   * { xs: 1, md: 2 } -> { xs: 1, sm: 1, md: 2, lg: 2, ... }
   */
  const [almostFullValue] = _.reduce(
    ([acc, prev]: [ValueByBreakpointPartial<T>, T | undefined], bp) => {
      const v = Object(value)[bp] ?? prev;
      return [v ? { ...acc, [bp]: v } : acc, v];
    },
    [{}, undefined],
    breakpointsNames,
  );

  // Fill missing breakpoints with defaults
  return _.defaults(defaultValue, almostFullValue);
}

export function mapValueByBreakpointListToCss<T extends unknown[]>(
  fn: (values: T) => CSS,
  defaultValues: ValueByBreakpointFullList<T>,
  values: ValueByBreakpointList<T>,
): CSS {
  const fullValues = _.map(([dv, v]) => fillValueByBreakpoint(dv, v), _.zip(defaultValues, values));

  const multiValueByBreakpoint = _.pipe([_.map((bp: Breakpoint) => [bp, _.map(_.get(bp), fullValues)]), _.fromPairs])(
    breakpointsNames,
  );

  return _.pipe([_.map((bp: Breakpoint) => [`@${bp}`, fn(multiValueByBreakpoint[bp])]), _.fromPairs])(breakpointsNames);
}

export function mapValueByBreakpointToCss<T>(
  fn: (value: T) => CSS,
  defaultValue: ValueByBreakpointFull<T>,
  value: ValueByBreakpoint<T>,
): CSS {
  return mapValueByBreakpointListToCss<[T]>(([v]) => fn(v), [defaultValue], [value]);
}
