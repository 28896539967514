import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '../config';
import { prepHeaders } from '../util';

const BASE_PATH = '/api/reports';

export const reportsApi = createApi({
  reducerPath: 'reports',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
  }),
  tagTypes: ['Reports'],
  endpoints: (builder) => ({
    generateReport: builder.mutation<unknown, unknown>({
      query: (payload) => ({
        url: `${BASE_PATH}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Reports'],
    }),
  }),
});

export const { endpoints, middleware, reducer, reducerPath, useGenerateReportMutation } = reportsApi;
