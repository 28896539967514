import classNames from 'classnames';
import { forwardRef, ReactElement } from 'react';

import { css, DefaultProps } from '../stitches.config';
import { HTMLTag } from '../types';
import { mergeCss } from '../util';
import { Box, BoxProps } from './Box';
import { Stack } from './Stack';
import { Text } from './Text';

export const Navbar = ({ className, children, wide = false, ...props }: { wide?: boolean } & DefaultProps<'nav'>) => {
  return (
    <Box
      as='nav'
      className={classNames(
        css({
          background: '$primaryOceanBlue',
          height: '100%',
          width: wide ? '100%' : '75px',
        })(),
        className,
      )}
      {...props}
    >
      <Stack
        as='ul'
        axis='y'
        className={classNames(
          css({
            listStyle: 'none',
            margin: 0,
            padding: 0,
            li: {
              '&:hover': {
                background: '$secondaryDarkBlue',
              },
              cursor: 'pointer',
              paddingY: '16px',
              display: 'flex',
              alignItems: 'center',
              svg: {
                size: '24px',
              },
            },
            variants: {
              wide: {
                false: {
                  li: {
                    flexDirection: 'column',
                    gap: '10px',
                    '.vf-text': {
                      fontSize: '0.6rem',
                    },
                  },
                },
                true: {
                  li: {
                    flexDirection: 'row',
                    gap: '20px',
                    paddingX: '20px',
                    width: '100%',
                    '.vf-text': {
                      fontSize: '0.8rem',
                    },
                  },
                },
              },
            },
          })({ wide }),
        )}
      >
        {children}
      </Stack>
    </Box>
  );
};

export type NavbarItemProps<C extends HTMLTag = 'li'> = {
  icon: ReactElement;
  selected?: boolean;
  title: string;
} & BoxProps<C>;

Navbar.Item = forwardRef<HTMLElement, NavbarItemProps<HTMLTag>>(function NavbarItem(
  { css, children, icon, selected, title, ...props },
  ref,
) {
  return (
    <Box
      as='li'
      css={mergeCss(
        {
          background: selected ? '$secondaryDarkBlue' : 'none',
          svg: {
            fill: '$primaryAuraWhite',
          },
        },
        css,
      )}
      ref={ref}
      {...props}
    >
      {icon}
      <Text color='$primaryAuraWhite'>{children || title}</Text>
    </Box>
  );
});
