import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ComponentProps, ReactNode, Suspense, useMemo } from 'react';
import { Redirect, Route as RouteRRD, Switch, useLocation } from 'react-router-dom';

import { Layout } from './components/Layout';
import { PageLoader } from './components/pages/PageLoader';
import { reactPlugin } from './insights';
import type { RouteInfo } from './routes';
import { routes } from './routes';
import { useGetMeQuery } from './store/users';

const TrackedRouteRRD = withAITracking(
  reactPlugin,
  (props: ComponentProps<typeof RouteRRD>) => {
    const { data: me } = useGetMeQuery();
    const location = useLocation();

    const redirect = useMemo<boolean>(() => {
      if (location.pathname === '/401') {
        return false;
      }

      if (me) {
        // NOTE: check if user is super admin
        if (me.isAdmin) {
          return false;
        }

        // NOTE: check if user is normal application user
        if (me.isUser) {
          return false;
        }

        return true;
      }

      return false;
    }, [location.pathname, me]);

    return redirect ? <Redirect to='/401' /> : <RouteRRD {...props} />;
  },
  'Tracking',
  'tracking',
);

const WithLayout = ({ children, withLayout }: { children: ReactNode; withLayout: boolean }) =>
  withLayout ? <Layout>{children}</Layout> : <div className='here'>{children}</div>;

const Route = ({ component, exact, isPrivate, path, withLayout }: RouteInfo) => (
  <WithLayout withLayout={withLayout}>
    {isPrivate ? (
      <>
        <AuthenticatedTemplate>
          <TrackedRouteRRD component={component} exact={exact} path={path} />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Redirect to='/' />
        </UnauthenticatedTemplate>
      </>
    ) : (
      <TrackedRouteRRD component={component} exact={exact} path={path} />
    )}
  </WithLayout>
);

export const Router = () => (
  <Suspense fallback={<PageLoader />}>
    <Switch>
      {routes.map((route) => (
        <Route key={route.id} {...route} />
      ))}
    </Switch>
  </Suspense>
);
