import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { Export, ExportDTO, ExportWithShortContract, Park } from 'markets-lib';

import { config } from '../config';
import { ListResponse } from '../types';
import { prepHeaders } from '../util';

export const exportsApi = createApi({
  reducerPath: 'exports',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
  }),
  tagTypes: ['Exports'],
  endpoints: (builder) => ({
    upsert: builder.mutation<ExportWithShortContract, ExportDTO>({
      query: (payload) => ({
        url: '/api/exports',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Exports'],
    }),
    getAll: builder.query<ListResponse<ExportWithShortContract>, { page: number; search: string }>({
      query: ({ page, search }) =>
        page
          ? `/api/exports?page=${page}${search && `&search=${search}`}`
          : `/api/exports${search && `?search=${search}`}`,
      providesTags: ['Exports'],
    }),
    getById: builder.query<Export, ExportDTO['id']>({
      query: (id) => `/api/exports/${id}`,
      providesTags: ['Exports'],
    }),
    remove: builder.mutation<ExportWithShortContract[], ExportDTO['id']>({
      query: (id) => ({
        url: `/api/exports/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Exports'],
    }),
    getListForExport: builder.query<ListResponse<Park>, number | void>({
      query: (page) => (page ? `/api/exports/parks?page=${page}` : '/api/exports/parks'),
      providesTags: ['Exports'],
    }),
  }),
});

export const {
  endpoints,
  middleware,
  reducer,
  reducerPath,
  useUpsertMutation,
  useRemoveMutation,
  useGetAllQuery,
  useGetByIdQuery,
  useGetListForExportQuery,
} = exportsApi;
