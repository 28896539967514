import VattenfallHallBold from '!url-loader!./assets/fonts/VattenfallHall-Bold.woff2';
import VattenfallHallBoldItalic from '!url-loader!./assets/fonts/VattenfallHall-BoldItalic.woff2';
import VattenfallHallHeavy from '!url-loader!./assets/fonts/VattenfallHall-Heavy.woff2';
import VattenfallHallHeavyItalic from '!url-loader!./assets/fonts/VattenfallHall-HeavyItalic.woff2';
import VattenfallHallLight from '!url-loader!./assets/fonts/VattenfallHall-Light.woff2';
import VattenfallHallLightItalic from '!url-loader!./assets/fonts/VattenfallHall-LightItalic.woff2';
import VattenfallHallMedium from '!url-loader!./assets/fonts/VattenfallHall-Medium.woff2';
import VattenfallHallMediumItalic from '!url-loader!./assets/fonts/VattenfallHall-MediumItalic.woff2';
import VattenfallHallRegular from '!url-loader!./assets/fonts/VattenfallHall-Regular.woff2';
import VattenfallHallRegularItalic from '!url-loader!./assets/fonts/VattenfallHall-RegularItalic.woff2';
import VattenfallHallDisplayBold from '!url-loader!./assets/fonts/VattenfallHallDisplay-Bold.woff2';

import { GlobalCSS } from './stitches.config';

export const globalStyles: GlobalCSS = {
  '*, *::before, *::after': {
    boxSizing: 'border-box',
  },

  '*': {
    margin: 0,
  },

  'html, body': {
    backgroundColor: '$background',
    height: '100%',
    overflowX: 'hidden',
  },

  body: {
    color: '$text',
    fontFamily: '$default',
    fontSize: '$md',
    position: 'relative',
  },

  h1: { fontSize: '2em;', margin: '.67em 0' },
  h2: { fontSize: '1.5em', margin: '.75em 0' },
  h3: { fontSize: '1.17em', margin: '.83em 0' },
  'h4, p, blockquote, ul, fieldset, form, ol, dl, dir, menu': { margin: '1.12em 0' },
  h5: { fontSize: '.83em', margin: '1.5em 0' },
  h6: { fontSize: '.75em', margin: '1.67em 0' },

  '.rc-select-dropdown': {
    minHeight: '100px',
    position: 'absolute',
    zIndex: '9999',
  },

  '.rc-select-dropdown.rc-select-dropdown-empty': {
    minHeight: '100px',
  },

  '.rc-select-dropdown-hidden': {
    display: 'none',
  },

  '.rc-select-item-option .rc-select-item-option-state': {
    position: 'absolute',
    right: '0',
    top: '4px',
    pointerEvents: 'none',
  },

  '.rc-select-item-option': {
    position: 'relative',
  },

  '.rc-select-item-option-grouped': {
    paddingLeft: '24px',
  },

  '@font-face': [
    // Vattenfall Hall
    {
      fontFamily: 'Vattenfall Hall',
      src: `url(${VattenfallHallLight}) format("woff2")`,
      fontWeight: 300,
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Vattenfall Hall',
      src: `url(${VattenfallHallLightItalic}) format("woff2")`,
      fontWeight: 300,
      fontStyle: 'italic',
    },
    {
      fontFamily: 'Vattenfall Hall',
      src: `url(${VattenfallHallRegular}) format("woff2")`,
      fontWeight: 400,
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Vattenfall Hall',
      src: `url(${VattenfallHallRegularItalic}) format("woff2")`,
      fontWeight: 400,
      fontStyle: 'italic',
    },
    {
      fontFamily: 'Vattenfall Hall',
      src: `url(${VattenfallHallMedium}) format("woff2")`,
      fontWeight: 500,
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Vattenfall Hall',
      src: `url(${VattenfallHallMediumItalic}) format("woff2")`,
      fontWeight: 500,
      fontStyle: 'italic',
    },
    {
      fontFamily: 'Vattenfall Hall',
      src: `url(${VattenfallHallBold}) format("woff2")`,
      fontWeight: 600,
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Vattenfall Hall',
      src: `url(${VattenfallHallBoldItalic}) format("woff2")`,
      fontWeight: 600,
      fontStyle: 'italic',
    },
    {
      fontFamily: 'Vattenfall Hall',
      src: `url(${VattenfallHallHeavy}) format("woff2")`,
      fontWeight: 700,
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Vattenfall Hall',
      src: `url(${VattenfallHallHeavyItalic}) format("woff2")`,
      fontWeight: 700,
      fontStyle: 'italic',
    },
    // Vattenfall Hall Display
    {
      fontFamily: 'Vattenfall Hall Display',
      src: `url(${VattenfallHallDisplayBold}) format("woff2")`,
      fontWeight: 600,
      fontStyle: 'normal',
    },
    // // Vattenfall Hall
    // {
    //   fontFamily: 'Vattenfall Hall',
    //   src: `
    //     url(/assets/fonts/VattenfallHall-Light}) format("woff2"),
    //     url(/assets/fonts/VattenfallHall-Light.woff) format("woff"),
    //     url('/assets/fonts/VattenfallHall-Light.eot') format('embedded-opentype'),
    //     url('/assets/fonts/VattenfallHall-Light.ttf') format('truetype')
    //   `,
    //   fontWeight: 300,
    //   fontStyle: 'normal',
    // },
    // {
    //   fontFamily: 'Vattenfall Hall',
    //   src: `
    //     url(/assets/fonts/VattenfallHall-LightItalic}) format("woff2"),
    //     url(/assets/fonts/VattenfallHall-LightItalic.woff) format("woff"),
    //     url('/assets/fonts/VattenfallHall-LightItalic.eot') format('embedded-opentype'),
    //     url('/assets/fonts/VattenfallHall-LightItalic.ttf') format('truetype')
    //   `,
    //   fontWeight: 300,
    //   fontStyle: 'italic',
    // },
    // {
    //   fontFamily: 'Vattenfall Hall',
    //   src: `
    //     url(/assets/fonts/VattenfallHall-Regular}) format("woff2"),
    //     url(/assets/fonts/VattenfallHall-Regular.woff) format("woff"),
    //     url('/assets/fonts/VattenfallHall-Regular.eot') format('embedded-opentype'),
    //     url('/assets/fonts/VattenfallHall-Regular.ttf') format('truetype')
    //   `,
    //   fontWeight: 400,
    //   fontStyle: 'normal',
    // },
    // {
    //   fontFamily: 'Vattenfall Hall',
    //   src: `
    //     url(/assets/fonts/VattenfallHall-RegularItalic}) format("woff2"),
    //     url(/assets/fonts/VattenfallHall-RegularItalic.woff) format("woff"),
    //     url('/assets/fonts/VattenfallHall-RegularItalic.eot') format('embedded-opentype'),
    //     url('/assets/fonts/VattenfallHall-RegularItalic.ttf') format('truetype')
    //   `,
    //   fontWeight: 400,
    //   fontStyle: 'italic',
    // },
    // {
    //   fontFamily: 'Vattenfall Hall',
    //   src: `
    //     url(/assets/fonts/VattenfallHall-Medium}) format("woff2"),
    //     url(/assets/fonts/VattenfallHall-Medium.woff) format("woff"),
    //     url('/assets/fonts/VattenfallHall-Medium.eot') format('embedded-opentype'),
    //     url('/assets/fonts/VattenfallHall-Medium.ttf') format('truetype')
    //   `,
    //   fontWeight: 500,
    //   fontStyle: 'normal',
    // },
    // {
    //   fontFamily: 'Vattenfall Hall',
    //   src: `
    //     url(/assets/fonts/VattenfallHall-MediumItalic}) format("woff2"),
    //     url(/assets/fonts/VattenfallHall-MediumItalic.woff) format("woff"),
    //     url('/assets/fonts/VattenfallHall-MediumItalic.eot') format('embedded-opentype'),
    //     url('/assets/fonts/VattenfallHall-MediumItalic.ttf') format('truetype')
    //   `,
    //   fontWeight: 500,
    //   fontStyle: 'italic',
    // },
    // {
    //   fontFamily: 'Vattenfall Hall',
    //   src: `
    //     url(/assets/fonts/VattenfallHall-Bold}) format("woff2"),
    //     url(/assets/fonts/VattenfallHall-Bold.woff) format("woff"),
    //     url('/assets/fonts/VattenfallHall-Bold.eot') format('embedded-opentype'),
    //     url('/assets/fonts/VattenfallHall-Bold.ttf') format('truetype')
    //   `,
    //   fontWeight: 600,
    //   fontStyle: 'normal',
    // },
    // {
    //   fontFamily: 'Vattenfall Hall',
    //   src: `
    //     url(/assets/fonts/VattenfallHall-BoldItalic}) format("woff2"),
    //     url(/assets/fonts/VattenfallHall-BoldItalic.woff) format("woff"),
    //     url('/assets/fonts/VattenfallHall-BoldItalic.eot') format('embedded-opentype'),
    //     url('/assets/fonts/VattenfallHall-BoldItalic.ttf') format('truetype')
    //   `,
    //   fontWeight: 600,
    //   fontStyle: 'italic',
    // },
    // {
    //   fontFamily: 'Vattenfall Hall',
    //   src: `
    //     url(/assets/fonts/VattenfallHall-Heavy}) format("woff2"),
    //     url(/assets/fonts/VattenfallHall-Heavy.woff) format("woff"),
    //     url('/assets/fonts/VattenfallHall-Heavy.eot') format('embedded-opentype'),
    //     url('/assets/fonts/VattenfallHall-Heavy.ttf') format('truetype')
    //   `,
    //   fontWeight: 700,
    //   fontStyle: 'normal',
    // },
    // {
    //   fontFamily: 'Vattenfall Hall',
    //   src: `
    //     url(/assets/fonts/VattenfallHall-HeavyItalic}) format("woff2"),
    //     url(/assets/fonts/VattenfallHall-HeavyItalic.woff) format("woff"),
    //     url('/assets/fonts/VattenfallHall-HeavyItalic.eot') format('embedded-opentype'),
    //     url('/assets/fonts/VattenfallHall-HeavyItalic.ttf') format('truetype')
    //   `,
    //   fontWeight: 700,
    //   fontStyle: 'italic',
    // },
    // // Vattenfall Hall Display
    // {
    //   fontFamily: 'Vattenfall Hall Display',
    //   src: `
    //     url(/assets/fonts/VattenfallHallDisplay-Bold}) format("woff2"),
    //     url(/assets/fonts/VattenfallHallDisplay-Bold.woff) format("woff"),
    //     url('/assets/fonts/VattenfallHallDisplay-Bold.eot') format('embedded-opentype'),
    //     url('/assets/fonts/VattenfallHallDisplay-Bold.ttf') format('truetype')
    //   `,
    //   fontWeight: 600,
    //   fontStyle: 'normal',
    // },
  ] as {}, // XXX: no idea why an array doesn't conform to GlobalCSS['@font-face']
};
