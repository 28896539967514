"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PricingInputDTO = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
exports.PricingInputDTO = zod_1.z.object({
    id: util_1.uuid.optional(),
    vat: zod_1.z
        .string()
        .regex(/^[0-9]{8}$/, 'pricingInputForm.errors.vat')
        .optional()
        .nullable(),
    costs: zod_1.z.number().optional().nullable(),
    mw: zod_1.z.number().optional().nullable(),
    pmId: zod_1.z.string().optional().nullable(),
    offer: zod_1.z.number().optional().nullable(),
    targetMargin: zod_1.z.number().optional().nullable(),
    flexMargin: zod_1.z.number().optional().nullable(),
    // NOTE: Used to attach pricing input instance to contract
    contractId: util_1.uuid.optional(),
});
