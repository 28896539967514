import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useAccount, useMsal } from '@azure/msal-react';
import { AuthError, InteractionRequiredAuthError } from 'msal';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { loginScopes } from '../../auth';

const LoginError = ({ error }: { error: AuthError | null }) => <p>Error when login, {error}</p>;

const LoginSilent = ({ noRedirect }: { noRedirect?: boolean }) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    if (account && inProgress === 'none') {
      instance
        .acquireTokenSilent({
          scopes: loginScopes,
          account,
        })
        .catch((error: InteractionRequiredAuthError | string): void | PromiseLike<void> | Error => {
          // FIXME: check if there is correct redirect after token expiration
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect({ scopes: loginScopes });
          }

          return new Error(error);
        });
    }
  }, [account, inProgress, instance]);

  return noRedirect ? null : <Redirect to='/home' />;
};

export const LoginPage = () => {
  const authRequest = {
    scopes: loginScopes,
  };

  const Spinner = () => <div>Loading...</div>;

  return (
    <MsalAuthenticationTemplate
      authenticationRequest={authRequest}
      errorComponent={LoginError}
      interactionType={InteractionType.Redirect}
      loadingComponent={Spinner}
    >
      <LoginSilent />
    </MsalAuthenticationTemplate>
  );
};
