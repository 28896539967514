import { forwardRef, SyntheticEvent, useEffect } from 'react';

import { DefaultProps } from '../stitches.config';
import { theme } from '../theme';
import { HTMLTag } from '../types';
import { mergeCss } from '../util';
import { Box } from './Box';

export type ModalHeaderProps<C extends HTMLTag = 'div'> = {} & DefaultProps<C>;

export const ModalHeader = forwardRef<HTMLElement, ModalHeaderProps<HTMLTag>>(function Header(
  { as = 'div', children, css, ...props },
  ref,
) {
  return (
    <Box as={as} ref={ref} {...props} css={mergeCss({ padding: '20px 20px 0 20px' }, css)}>
      {children}
    </Box>
  );
});

export type ModalContentProps<C extends HTMLTag = 'div'> = {} & DefaultProps<C>;

export const ModalContent = forwardRef<HTMLElement, ModalContentProps<HTMLTag>>(function Header(
  { as = 'div', children, css, ...props },
  ref,
) {
  return (
    <Box
      as={as}
      ref={ref}
      {...props}
      css={mergeCss(
        {
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden auto',
          padding: '20px',
        },
        css,
      )}
    >
      {children}
    </Box>
  );
});

export type ModalFooterProps<C extends HTMLTag = 'div'> = {
  justify?: '' | 'center' | 'space-between' | 'space-around' | 'flex-end';
} & DefaultProps<C>;

export const ModalFooter = forwardRef<HTMLElement, ModalFooterProps<HTMLTag>>(function Footer(
  { as = 'div', children, justify = 'space-between', css, ...props },
  ref,
) {
  return (
    <Box
      as={as}
      ref={ref}
      {...props}
      css={mergeCss(
        {
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: justify,
          margin: '0 20px',
          padding: '0 20px 20px 20px',
          position: 'relative',
          width: '100%',
        },
        css,
      )}
    >
      {children}
    </Box>
  );
});

export type ModalProps<C extends HTMLTag = 'div'> = {
  onOutsideClick: () => void;
  show: boolean;
} & DefaultProps<C>;

export const Modal = forwardRef<HTMLElement, ModalProps<HTMLTag>>(function Modal(
  { as = 'div', children, onOutsideClick, show, css, ...props },
  ref,
) {
  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === 'Escape' ? onOutsideClick() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [onOutsideClick]);

  if (!show) {
    return null;
  }

  return (
    <Box
      as={as}
      ref={ref}
      {...props}
      css={mergeCss(
        {
          alignItems: 'center',
          background: '#00000080',
          display: 'flex',
          justifyContent: 'center',
          position: 'fixed',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 9999,
        },
        css,
      )}
      onClick={onOutsideClick}
    >
      <Box
        as={as}
        ref={ref}
        {...props}
        css={{
          alignItems: 'center',
          background: theme.colors.background,
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          margin: '20px',
          maxHeight: '90%',
          '@sm': {
            maxWidth: '90%',
          },
          '@md': {
            maxWidth: '70%',
          },
          '@lg': {
            maxWidth: '60%',
          },
          '@xl': {
            maxWidth: '50%',
          },
        }}
        onClick={(e: SyntheticEvent) => e.stopPropagation()}
      >
        {children}
      </Box>
    </Box>
  );
});
