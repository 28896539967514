"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationStagingDTO = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
var location_1 = require("./location");
exports.LocationStagingDTO = zod_1.z.object({
    id: util_1.uuid.optional(),
    meteringPointId: zod_1.z
        .string()
        .regex(/^[0-9]{11}$/, 'shareForm.errors.meteringPointId')
        .or(util_1.emptyStringToNull)
        .optional()
        .nullable(),
    location: location_1.LocationDTO.optional().nullable(),
    parkId: util_1.uuid.optional().nullable(),
});
