import { Select } from '@rcl/rcl';
import { GraphUser } from 'markets-lib';
import { useCallback, useMemo, useState } from 'react';

import { useDebounce } from '../hooks/useDebounce';
import { useGetGraphUsersQuery, useUpdateMutation } from '../store/users';

interface GraphUsersSelectProps {
  ignoreUserIds?: GraphUser['id'][];
  label?: string;
  onUserAdd?: (user: GraphUser) => void;
}

export const GraphUsersSelect = ({ ignoreUserIds, label, onUserAdd }: GraphUsersSelectProps) => {
  const [filter, setFilter] = useState('');
  const { value: debouncedFilter } = useDebounce(filter, 1000);
  const filterTooShort = debouncedFilter.length < 3;

  const { data, isFetching: isFetchingUsers } = useGetGraphUsersQuery(debouncedFilter, {
    skip: filterTooShort,
  });

  const options = useMemo(() => {
    const users = filterTooShort ? [] : data;

    return users
      ?.filter(({ id }) => !ignoreUserIds?.includes(id)) // do not show users that are already added
      .map((user) => ({
        value: user.id,
        label: user.displayName,
        ...user,
      }));
  }, [data, ignoreUserIds, filterTooShort]);

  const [addUser, { isLoading: isAddingUser }] = useUpdateMutation();

  const handleUserAdd = useCallback(
    (option, user) => {
      if (onUserAdd) {
        onUserAdd(user);
      }

      if (!isAddingUser) {
        addUser({
          email: user.mail,
          isAdmin: false,
          isUser: true,
          name: user.displayName,
          ...user,
        });
      }
    },
    [addUser, isAddingUser, onUserAdd],
  );

  const isLoading = isFetchingUsers || isAddingUser;

  const handleSearchOnChange = (value: string) => {
    setFilter(value);
  };

  const handleOnBlur = () => {
    setFilter('');
  };

  return (
    <Select
      defaultOpen={false}
      label={label}
      loading={isLoading}
      menuItemSelectedIcon={null}
      onBlur={handleOnBlur}
      onSearch={handleSearchOnChange}
      onSelect={handleUserAdd}
      optionFilterProp='label'
      options={options}
      searchValue={filter}
      showSearch
      tight
      value=''
    />
  );
};
