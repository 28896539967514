import { AppShell, IconAssortment, IconUser, Navbar } from '@rcl/rcl';
import { createElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { routes } from '../routes';
import { useGetMeQuery } from '../store/users';

export const Layout = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { data: me } = useGetMeQuery();

  return (
    <AppShell
      headerWidgets={<IconUser css={{ cursor: 'pointer' }} />}
      navbarItems={
        <>
          {routes
            .filter(({ hideInNav }) => !hideInNav)
            .map(({ icon, id, name, path, needsAdmin }) => {
              // NOTE: user is admin, he/she can see all menu items
              if (me?.isAdmin) {
                return (
                  <Navbar.Item
                    key={id}
                    icon={icon ? createElement(icon) : <IconAssortment />}
                    onClick={() => history.push(path)}
                    selected={history.location.pathname === path}
                    title={t(name)}
                  />
                );
              } else {
                // NOTE: normal user can only see non admin related menu items
                if (!needsAdmin) {
                  return (
                    <Navbar.Item
                      key={id}
                      icon={icon ? createElement(icon) : <IconAssortment />}
                      onClick={() => history.push(path)}
                      selected={history.location.pathname === path}
                      title={t(name)}
                    />
                  );
                }
              }
            })}
        </>
      }
    >
      {children}
    </AppShell>
  );
};
