"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OptionalParkStaging = exports.ParkStaging = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
var locationStaging_1 = require("./locationStaging");
var operatorStaging_1 = require("./operatorStaging");
var park_1 = require("./park");
exports.ParkStaging = zod_1.z.object({
    id: util_1.uuid.optional(),
    parkInformation: zod_1.z.object({
        parkName: zod_1.z.string().nonempty('customerForm.errors.empty'),
        eanCode: zod_1.z.string().nonempty('customerForm.errors.empty'),
        parkType: park_1.ParkType,
    }),
    address: park_1.ParkAddress,
    remoteControllability: zod_1.z.object({
        loadType: zod_1.z.string().optional().nullable(),
        controlType: zod_1.z.string().optional().nullable(),
        dialUpSoftware: zod_1.z.string().optional().nullable(),
        networkConnectionPointName: zod_1.z.string().optional().nullable(),
        distributionSignal: zod_1.z.string().optional().nullable(),
        installationSiteLoadManagement: zod_1.z.string().optional().nullable(),
        oldRemoteControlServiceProvider: zod_1.z.string().optional().nullable(),
    }),
    transmissionSystemOperator: operatorStaging_1.TransmissionSystemOperatorStaging,
    distributionSystemOperator: operatorStaging_1.DistributionSystemOperatorStaging,
    meterOperator: operatorStaging_1.MeterOperatorStaging.optional().nullable(),
    locations: zod_1.z.array(locationStaging_1.LocationStagingDTO).optional().nullable(),
    contracts: zod_1.z
        .array(zod_1.z.object({
        id: util_1.uuid,
        name: zod_1.z.string(),
        export: zod_1.z.object({ id: util_1.uuid }).nullable(),
    }))
        .optional(),
    // NOTE: Used to attach park instance to contract
    contractId: util_1.uuid.optional(),
    currentDirectMarketingForm: zod_1.z.string(),
    desiredDirectMarketingForm: zod_1.z.string(),
    currentAccountingProcedureAtTheGridOperator: zod_1.z.string(),
    desiredAccountingProcedureAtTheGridOperator: zod_1.z.string(),
    currentStatusTolerationCase: zod_1.z.string(),
    currentBalanceModel: zod_1.z.string(),
});
exports.OptionalParkStaging = exports.ParkStaging.deepPartial();
