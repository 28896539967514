// import { ConfigType } from '@stitches/core/types/config';
import { DetailedHTMLFactory, HTMLAttributes } from 'react';

// import { CSS } from './stitches.config';

// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Values_and_Units

export type AngleUnit = 'deg' | 'grad' | 'rad' | 'turn';
export type Angle = '0' | `${number}${AngleUnit}`;

export type Axis = 'x' | 'y';
export type Side = 'top' | 'right' | 'bottom' | 'left';

// export type AlignmentAgnostic = 'start' | 'center' | 'end';
export type AlignmentX = 'left' | 'center' | 'right';
// export type AlignmentY = 'top' | 'center' | 'bottom';

export const defaultFontSize = 16;

// export const alignmentMap: Record<AlignmentX | AlignmentY | AlignmentAgnostic, AlignmentAgnostic> = {
//   start: 'start',
//   left: 'start',
//   top: 'start',
//   center: 'center',
//   end: 'end',
//   right: 'end',
//   bottom: 'end',
// };

export type DistanceUnit = 'px' | 'em' | '%';
export type Distance = '0' | `${number}${DistanceUnit}`;

export type Color = string;

export type FontWeight = string;

// source csstype
export type LineStyle =
  | 'dashed'
  | 'dotted'
  | 'double'
  | 'groove'
  | 'hidden'
  | 'inset'
  | 'none'
  | 'outset'
  | 'ridge'
  | 'solid';

// export type HTMLTag = keyof ReactHTML; // e.g. 'div'
export type HTMLTag = keyof JSX.IntrinsicElements;
// export type HTMLComponentProps<T extends HTMLTag> = PropsWithChildren<ComponentPropsWithRef<T>>;
// export type HTMLComponentType<T extends HTMLTag> = ComponentType<HTMLComponentProps<T>>;
// e.g. HTMLElementType<'div'> = HTMLDivElement

export type HTMLElementType<T extends HTMLTag> = JSX.IntrinsicElements[T] extends DetailedHTMLFactory<
  HTMLAttributes<infer E>,
  infer E
>
  ? E
  : never;

// export type TokenType = keyof Required<ConfigType.Theme>;
// export interface VfTheme {}

// export type VfToken<TokenName extends TokenType> = keyof VfTheme extends never
//   ? `$${string}`
//   : TokenName extends keyof VfTheme
//   ? keyof VfTheme[TokenName] extends string | number
//     ? `$${keyof VfTheme[TokenName]}`
//     : never
//   : never;

// export type VfComponentProps<Props = {}> = {
//   className?: string;
//   children?: ReactNode;
//   css?: CSS;
// } & Props;
