"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PisaForm = exports.PisaInputDTO = exports.SteerFunction = exports.steerFunction = exports.FeeFunction = exports.feeFunction = exports.CustomerCompensationFunction = exports.customerCompensationFunction = exports.SteeringCompensationBase = exports.steeringCompensationBase = exports.CompensationForm = exports.compensationForm = exports.FeeBase = exports.feeBasis = exports.DirectMarketingForm = exports.directMarketingForms = exports.CaseOfTolerationStatus = exports.caseOfTolerationStatuses = exports.BalancingModel = exports.balancingModels = exports.RedispatchProcedure = exports.redispatchProcedures = exports.SteeringProcedure = exports.steeringProcedures = exports.FullYesNoOption = exports.fullYesNoOptions = exports.YesNoOption = exports.yesNoOptions = exports.yesNoNumbers = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
var park_1 = require("./park");
exports.yesNoNumbers = [1, 0];
exports.yesNoOptions = ['J', 'N'];
exports.YesNoOption = zod_1.z.enum(exports.yesNoOptions);
exports.fullYesNoOptions = ['Ja', 'Nein'];
exports.FullYesNoOption = zod_1.z.enum(exports.fullYesNoOptions);
exports.steeringProcedures = ['pauschal', 'spitz', 'spitz-light'];
exports.SteeringProcedure = zod_1.z.enum(exports.steeringProcedures);
exports.redispatchProcedures = ['Pauschal', 'Spitz', 'Spitz-light'];
exports.RedispatchProcedure = zod_1.z.enum(exports.redispatchProcedures);
exports.balancingModels = ['Prognosemodell', 'Planwertmodell'];
exports.BalancingModel = zod_1.z.enum(exports.balancingModels);
exports.caseOfTolerationStatuses = ['Duldungsfall', 'Aufforderungsfall'];
exports.CaseOfTolerationStatus = zod_1.z.enum(exports.caseOfTolerationStatuses);
exports.directMarketingForms = ['Geförderte DV (Marktprämie)', 'Sonstige DV', 'Weiterbetrieb'];
exports.DirectMarketingForm = zod_1.z.enum(exports.directMarketingForms);
exports.feeBasis = ['aw', 'mw', 'mw_avg', 'fix'];
exports.FeeBase = zod_1.z.enum(exports.feeBasis);
exports.compensationForm = ['Marktwertvergütung', 'Spotvergütung', 'Stufenmodell'];
exports.CompensationForm = zod_1.z.enum(exports.compensationForm);
exports.steeringCompensationBase = ['aw', 'mw', 'mw_avg'];
exports.SteeringCompensationBase = zod_1.z.enum(exports.steeringCompensationBase);
exports.customerCompensationFunction = ['min', 'max', 'avg'];
exports.CustomerCompensationFunction = zod_1.z.enum(exports.customerCompensationFunction);
exports.feeFunction = ['min', 'max', 'avg'];
exports.FeeFunction = zod_1.z.enum(exports.feeFunction);
exports.steerFunction = ['min', 'max', 'avg'];
exports.SteerFunction = zod_1.z.enum(exports.steerFunction);
exports.PisaInputDTO = zod_1.z.object({
    aneNumber: zod_1.z.string().optional().nullable(),
    balanceGroup: zod_1.z.string().min(1, 'customerForm.errors.empty'),
    balancingModel: exports.BalancingModel,
    bankAccountSince: util_1.zodDate,
    btr: zod_1.z.string().min(1, 'customerForm.errors.empty'),
    btrCode: zod_1.z.string().min(1, 'customerForm.errors.empty'),
    btrValidFrom: util_1.zodDate,
    bundlingOfInvoices: exports.FullYesNoOption,
    caseOfTolerationStatus: exports.CaseOfTolerationStatus,
    compensationFloor: zod_1.z.number().optional().nullable(),
    compensationForm: exports.CompensationForm,
    compensationShare: zod_1.z.number().optional().nullable(),
    compensationStages: zod_1.z.string().optional().nullable(),
    contactSince: util_1.zodDate,
    contractName: zod_1.z.string().nonempty('customerForm.errors.empty'),
    customer: zod_1.z.string().min(1, 'customerForm.errors.empty'),
    customerAddressCity: zod_1.z.string().min(1, 'customerForm.errors.empty'),
    customerAddressHouseNumber: zod_1.z.string().nonempty('customerForm.errors.empty'),
    customerAddressMailbox: zod_1.z.string().regex(new RegExp(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})((,| ,|, | , |){1}[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/), // https://regex101.com/r/6l030N/1
    'customerForm.errors.email'),
    customerAddressStreetName: zod_1.z.string().nonempty('customerForm.errors.empty'),
    customerAddressStreetNumberSuffix: zod_1.z.string().optional().nullable(),
    customerAddressZipCode: zod_1.z
        .string()
        .min(1, 'customerForm.errors.empty')
        .regex(new RegExp(/^[0-9]{5}$/), 'customerForm.errors.digitsLength'),
    customerCompanyName: zod_1.z.string().nonempty('customerForm.errors.empty'),
    customerCompensationFunction: exports.CustomerCompensationFunction.optional().nullable(),
    customerCompensationPercentage: zod_1.z.number().optional().nullable(),
    directMarketingForm: exports.DirectMarketingForm,
    eiv: zod_1.z.string().min(1, 'customerForm.errors.empty'),
    eivCode: zod_1.z.string().min(1, 'customerForm.errors.empty'),
    eivValidFrom: util_1.zodDate,
    endOfContract: util_1.zodDate,
    energySource: park_1.ParkType,
    feeBase: exports.FeeBase,
    feeBaseFix: zod_1.z.number().optional().nullable(),
    feeFunction: exports.FeeFunction.optional().nullable(),
    fixedServiceCharge: exports.FullYesNoOption,
    id: util_1.uuid.optional(),
    oneWayPaymentFlow: exports.YesNoOption,
    priceGuaranteeOfOrigin: zod_1.z.number().optional().nullable(),
    redispatchAccountingProcedure: exports.RedispatchProcedure,
    salesPartner: zod_1.z.string().min(1, 'customerForm.errors.empty'),
    serviceFee: zod_1.z.number().optional().nullable(),
    startOfContract: util_1.zodDate,
    steerFunction: exports.SteerFunction.optional().nullable(),
    steeringAccountingProcedure: exports.SteeringProcedure,
    steeringCompensationBase: exports.SteeringCompensationBase.optional().nullable(),
    steeringFloor: zod_1.z.number().optional().nullable(),
    supplier: zod_1.z.string().min(1, 'customerForm.errors.empty'),
    supplierCode: zod_1.z.string().min(1, 'customerForm.errors.empty'),
    supplierValidFrom: util_1.zodDate,
    vatId: zod_1.z.number({ required_error: 'customerForm.errors.empty' }).lte(99999999, 'pisaForm.errors.vatId'),
    vdnAbbreviation: zod_1.z.string().nonempty('customerForm.errors.empty'),
    whitelabelCompensationFloor: zod_1.z.number().optional().nullable(),
    whitelabelCompensationShare: zod_1.z.number().optional().nullable(),
    whitelabelProvision: zod_1.z.number().optional().nullable(),
    wlPartner: zod_1.z.string().optional().nullable(),
    // NOTE: Used to attach pisaInput instance to contract
    contractId: util_1.uuid.optional(),
});
var NoServiceFee = exports.PisaInputDTO.omit({ serviceFee: true });
exports.PisaForm = NoServiceFee.extend({
    serviceFee: zod_1.z.number().nullable(),
})
    .partial()
    .superRefine(function (val, ctx) {
    var parseRes = exports.PisaInputDTO.safeParse(val);
    if (!parseRes.success) {
        parseRes.error.issues.forEach(function (issue) {
            ctx.addIssue(issue);
        });
    }
    if (val.fixedServiceCharge === 'Ja' && !Number.isFinite(val.serviceFee)) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            path: ['serviceFee'],
            message: 'commonErrors.empty',
        });
    }
    // NOTE: if compensationForm is set as 'Stufenmodell' compensationStages should be required
    if (val.compensationForm === 'Stufenmodell' && val.compensationStages === (undefined || null || '')) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            path: ['compensationStages'],
            message: 'commonErrors.empty',
        });
    }
    // NOTE: if feeBase is set as 'fix' feeBaseFix should be required
    if (val.feeBase === 'fix' && val.feeBaseFix === (undefined || null)) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            path: ['feeBaseFix'],
            message: 'commonErrors.empty',
        });
    }
});
