import { Box } from '@rcl/rcl';
import { ReactNode } from 'react';

export const Page = ({ children }: { children: ReactNode }) => (
  <Box
    css={{
      height: 'inherit',
    }}
  >
    {children}
  </Box>
);
