"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyDTO = exports.Contact = exports.Company = exports.Address = exports.Bundesland = exports.bundeslands = exports.titles = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
exports.titles = ['Mr', 'Mrs', 'notSpecified', 'diverse'];
exports.bundeslands = [
    'BB',
    'BE',
    'BW',
    'BY',
    'HB',
    'HE',
    'HH',
    'MV',
    'NI',
    'NW',
    'RP',
    'SH',
    'SL',
    'SN',
    'ST',
    'TH',
];
exports.Bundesland = zod_1.z.enum(exports.bundeslands);
exports.Address = zod_1.z.object({
    streetName: zod_1.z.string().nonempty('customerForm.errors.empty'),
    houseNumber: zod_1.z.string().nonempty('customerForm.errors.empty'),
    zipCode: zod_1.z
        .string()
        .nonempty('customerForm.errors.empty')
        .regex(new RegExp(/^[0-9]{5}$/), 'customerForm.errors.digitsLength'),
    city: zod_1.z.string().nonempty('customerForm.errors.empty'),
});
exports.Company = zod_1.z.object({
    id: util_1.uuid.optional(),
    name: zod_1.z.string().nonempty('customerForm.errors.empty'),
    addressStreetName: zod_1.z.string().nonempty('customerForm.errors.empty'),
    addressHouseNumber: zod_1.z.string().nonempty('customerForm.errors.empty'),
    addressZipCode: zod_1.z
        .string()
        .nonempty('customerForm.errors.empty')
        .regex(new RegExp(/^[0-9]{5}$/), 'customerForm.errors.digitsLength'),
    addressCity: zod_1.z.string().nonempty('customerForm.errors.empty'),
    contactTitle: zod_1.z.enum(exports.titles),
    contactFirstName: zod_1.z.string().nonempty('customerForm.errors.empty'),
    contactLastName: zod_1.z.string().nonempty('customerForm.errors.empty'),
    contactPhone: zod_1.z
        .string()
        .nonempty('customerForm.errors.empty')
        // eslint-disable-next-line no-useless-escape
        .regex(new RegExp(/^(\(?([\d \-\)\–\+\/\(]+)\)?([ .\-–\/]?)([\d]+))$/), 'customerForm.errors.noLetters'),
    contactEmail: zod_1.z.string().regex(new RegExp(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})((,| ,|, | , |){1}[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/), // https://regex101.com/r/6l030N/1
    'customerForm.errors.email'),
    contactApiAddress: zod_1.z.string().nullable().optional(),
    contactFax: zod_1.z.string().nullable().optional(),
});
exports.Contact = zod_1.z.object({
    title: zod_1.z.enum(exports.titles),
    firstName: zod_1.z.string().nonempty('customerForm.errors.empty'),
    lastName: zod_1.z.string().nonempty('customerForm.errors.empty'),
    phone: zod_1.z
        .string()
        .nonempty('customerForm.errors.empty')
        // eslint-disable-next-line no-useless-escape
        .regex(new RegExp(/^(\(?([\d \-\)\–\+\/\(]+)\)?([ .\-–\/]?)([\d]+))$/), 'customerForm.errors.noLetters'),
    email: zod_1.z.string().regex(new RegExp(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})((,| ,|, | , |){1}[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/), // https://regex101.com/r/6l030N/1
    'customerForm.errors.email'),
    apiAddress: zod_1.z.string().nullable().optional(),
    fax: zod_1.z.string().nullable().optional(),
});
exports.CompanyDTO = zod_1.z.object({
    id: util_1.uuid.optional(),
    name: zod_1.z.string().nonempty('customerForm.errors.empty'),
    address: exports.Address,
    contact: exports.Contact,
});
