import { CSS } from '@stitches/react';
import classNames from 'classnames';
import _ from 'lodash';
import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  CellProps,
  Column,
  ColumnInstance,
  Row,
  SortingRule,
  TableInstance,
  TableOptions,
  useGlobalFilter,
  UseGlobalFiltersInstanceProps,
  UseGlobalFiltersOptions,
  useRowSelect,
  UseRowSelectInstanceProps,
  UseRowSelectRowProps,
  useSortBy,
  UseSortByColumnProps,
  UseSortByInstanceProps,
  UseSortByOptions,
  useTable,
} from 'react-table';

import { css as stitchesCss } from '../stitches.config';
import { theme } from '../theme';
import { HTMLTag } from '../types';
import { mergeCss } from '../util';
import { Box, BoxProps } from './Box';
import { Checkbox, CheckboxProps } from './Checkbox';
import { ColSpan } from './Grid';
import { IconFACaretDown, IconFACaretUp } from './icons';
import { Overlay } from './Overlay';
import { Spinner } from './Spinner';
import { Text } from './Text';

export interface TableData {
  id?: string | number;
}

export type TableColumn<D extends TableData> = {
  alignHorizontal?: 'left' | 'center' | 'right';
  headerHidden?: boolean;
  size?: ColSpan | 'fit';
  isSortable?: boolean;
} & Column<D>;

export type TableDefaultCellProps<D extends TableData> = {
  value?: CellProps<D>['value'];
  row: CellProps<D>['row'];
  alignHorizontal?: 'left' | 'center' | 'right';
} & BoxProps;

type TableOptionsWithPlugins<D extends TableData> = TableOptions<D> & UseGlobalFiltersOptions<D> & UseSortByOptions<D>;
type TableInstanceWithPlugins<D extends TableData> = TableInstance<D> &
  UseGlobalFiltersInstanceProps<D> &
  UseSortByInstanceProps<D> &
  UseRowSelectInstanceProps<D>;
const useTableWithPlugins = <D extends TableData>(options: TableOptionsWithPlugins<D>) =>
  useTable(options, useGlobalFilter, useSortBy, useRowSelect) as TableInstanceWithPlugins<D>;

const TableOverlayCover = ({
  isLoading,
  noData,
  noDataMessage,
}: {
  isLoading?: boolean;
  noData: boolean;
  noDataMessage?: string | ReactElement;
}) => {
  if (isLoading) {
    return <Spinner />;
  } else if (noData && typeof noDataMessage === 'string') {
    return <Text>{noDataMessage}</Text>;
  } else if (noData && noDataMessage) {
    return noDataMessage as ReactElement;
  } else {
    return null;
  }
};

const ThSortIcon = ({
  isSortable,
  isSorted,
  isSortedDesc,
  css,
}: {
  isSortable?: boolean;
  isSorted: boolean;
  isSortedDesc?: boolean;
  css: CSS;
}) => {
  if (isSorted && isSortedDesc) {
    return <IconFACaretDown css={css} />;
  } else if (isSorted && !isSortedDesc) {
    return <IconFACaretUp css={css} />;
  } else if (isSortable) {
    return <IconFACaretDown visibility='hidden' css={css} />;
  } else {
    return null;
  }
};

export const TableDefaultCell = <D extends TableData>({
  children,
  title,
  value,
  alignHorizontal,
  css,
  onClick,
}: TableDefaultCellProps<D>) => (
  <Box css={mergeCss({ display: 'flex', justifyContent: alignHorizontal }, css)} title={title} onClick={onClick}>
    {children || value}
  </Box>
);

const Th = <D extends TableData>({
  children,
  column,
  setSortBy,
  userColumn,
  fontSize,
  ...props
}: ComponentPropsWithoutRef<'th'> & {
  column: ColumnInstance<D> & UseSortByColumnProps<D>;
  setSortBy: (sortBy: SortingRule<D>[]) => void;
  userColumn: TableColumn<D>;
  fontSize: 'sm' | 'md' | 'lg' | 'xl';
}) => {
  const { isSortable, alignHorizontal, headerHidden, size } = userColumn;
  const { id, isSorted, isSortedDesc } = column;
  const toggleSort = useCallback(() => {
    if (isSorted && isSortedDesc) {
      setSortBy([]);
    } else if (isSorted && !isSortedDesc) {
      setSortBy([{ id, desc: true }]);
    } else {
      setSortBy([{ id, desc: false }]);
    }
  }, [id, isSorted, isSortedDesc, setSortBy]);

  return (
    <Box
      {...props}
      as='th'
      className={classNames(
        stitchesCss({
          fontWeight: '$bold',
          cursor: isSortable ? 'pointer' : undefined,
          '> *': {
            display: 'flex',
            justifyContent: alignHorizontal ?? 'left',
          },

          variants: {
            size: _.zipObject(
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'fit'],
              _.map<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'fit', CSS>(
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'fit'],
                (colSize) => {
                  if (colSize === 'fit') {
                    return { width: '1px', whiteSpace: 'nowrap' };
                  }

                  return { width: `calc(${colSize} * 100% / 12)` };
                },
              ),
            ),
          },
        })({ size }),
      )}
      onClick={isSortable ? toggleSort : undefined}
      visibility={headerHidden ? 'hidden' : 'visible'}
    >
      <Box
        css={{
          visibility: headerHidden ? 'hidden' : 'visible',
          display: 'flex',
          alignItems: 'center',

          '* >': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        {children}
        <ThSortIcon
          isSortable={isSortable}
          isSorted={isSorted}
          isSortedDesc={isSortedDesc}
          css={{
            width: theme.fontSizes[fontSize],
            height: theme.fontSizes[fontSize],
            marginLeft: '6px',
          }}
        />
      </Box>
    </Box>
  );
};

const Td = <D extends TableData>({
  children,
  fontSize,
  userColumn,
  ...props
}: ComponentPropsWithoutRef<'td'> & {
  userColumn: TableColumn<D>;
  fontSize: 'sm' | 'md' | 'lg' | 'xl';
}) => {
  const { alignHorizontal, size } = userColumn;

  return (
    <Box
      {...props}
      as='td'
      className={classNames(
        stitchesCss({
          '> *': {
            display: 'flex',
            justifyContent: alignHorizontal ?? 'left',
          },

          svg: {
            width: `calc(${theme.fontSizes[fontSize]} * 1.5)`,
            height: `calc(${theme.fontSizes[fontSize]} * 1.5)`,
          },

          variants: {
            size: _.zipObject(
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'fit'],
              _.map<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'fit', CSS>(
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'fit'],
                (colSize) => {
                  if (colSize === 'fit') {
                    return { width: '1px', whiteSpace: 'nowrap' };
                  }

                  return { width: `calc(${colSize} * 100% / 12)` };
                },
              ),
            ),
          },
        })({ size }),
      )}
    >
      {children}
    </Box>
  );
};

const RowCheckbox = ({
  indeterminate,
  checked,
  ...rest
}: {
  indeterminate?: boolean;
} & Partial<CheckboxProps<HTMLTag>>) => {
  const ref = useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current === null) {
      return;
    }
    ref.current.indeterminate = indeterminate as boolean;
  }, [indeterminate, ref]);

  return <Checkbox ref={ref} size='md' checked={checked || false} {...rest} />;
};

export type TableProps<D extends TableData> = {
  autoResetGlobalFilter?: boolean;
  autoResetSortBy?: boolean;
  columns: TableColumn<D>[];
  data?: D[];
  filter?: string;
  fontSize?: 'sm' | 'md' | 'lg' | 'xl';
  isLoading?: boolean;
  noDataMessage?: string | ReactElement;
  noHeader?: boolean;
  isRowSelectable?: boolean;
  selectedRows?: (props: Row<D>[]) => void;
  toggleSelectAllRows?: (props: () => void) => void;
} & BoxProps;

const TableBody = <D extends TableData>(
  {
    autoResetGlobalFilter = false,
    autoResetSortBy = false,
    columns,
    data: tableData,
    filter,
    fontSize = 'lg',
    isLoading,
    noDataMessage,
    noHeader,
    isRowSelectable,
    selectedRows,
    toggleSelectAllRows,
    ...props
  }: TableProps<D>,
  ref?: ForwardedRef<HTMLElement>,
) => {
  const reactTableColumns: Column<D>[] = useMemo(() => {
    if (columns.find((c) => c.id === 'select')) {
      columns.shift();
    }
    if (isRowSelectable && !columns.find((c) => c.id === 'select')) {
      columns.unshift({
        id: 'select',
        Header: ({ getToggleAllRowsSelectedProps }: UseRowSelectInstanceProps<D>) =>
          !noHeader && <RowCheckbox {...getToggleAllRowsSelectedProps()} size={fontSize} />,
        Cell: ({ row }: { row: UseRowSelectRowProps<D> }) => (
          <RowCheckbox {...row.getToggleRowSelectedProps()} size={fontSize} />
        ),
      });
    }

    return columns.map((column) => {
      return {
        ...column,
        Cell: 'Cell' in column ? column.Cell : TableDefaultCell,
      };
    });
  }, [columns, fontSize, isRowSelectable, noHeader]);

  const data = useMemo(() => tableData || [], [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setGlobalFilter,
    setSortBy,
    selectedFlatRows,
    toggleAllRowsSelected,
  } = useTableWithPlugins<D>({
    autoResetGlobalFilter,
    autoResetSortBy,
    columns: reactTableColumns,
    data,
  });

  useEffect((): void => {
    setGlobalFilter(filter);
  }, [filter, setGlobalFilter]);

  useEffect(() => {
    selectedRows && selectedRows(selectedFlatRows);
  }, [selectedRows, selectedFlatRows]);

  useEffect(() => {
    toggleSelectAllRows && toggleSelectAllRows(() => toggleAllRowsSelected);
  }, [toggleSelectAllRows, toggleAllRowsSelected]);

  const noData = rows.length === 0;

  return (
    <Overlay
      cover={<TableOverlayCover isLoading={isLoading} noData={noData} noDataMessage={noDataMessage} />}
      on={isLoading || noData}
      css={{
        minHeight: '125px',
        height: 'inherit',
      }}
    >
      <Box
        {...getTableProps()}
        {...props}
        ref={ref}
        as='table'
        className={classNames(
          stitchesCss({
            tableLayout: 'auto',
            width: '100%',
            marginBottom: '2rem',
            color: 'black',
            borderSpacing: '0px',
            fontFamily: '$VattenfallHall',

            'tr:first-of-type > td, tr:first-of-type > th': {
              borderTop: noHeader ? '1px solid $itemBorder' : 'none',
            },
            'td, th': {
              borderBottom: '1px solid $itemBorder',
              padding: '15px 10px',

              '&:first-child': { paddingLeft: '0' },
              '&:last-child': { paddingRight: '0' },
            },

            variants: {
              fontSize: _.zipObject(
                ['sm', 'md', 'lg', 'xl'],
                _.map<'sm' | 'md' | 'lg' | 'xl', CSS>(['sm', 'md', 'lg', 'xl'], (fontSize) => ({
                  fontSize: `$${fontSize}`,
                })),
              ),
            },
          })({ fontSize }),
        )}
      >
        <Box as='thead' css={{ overflowWrap: 'break-word' }}>
          {headerGroups.map((headerGroup, idx) => (
            <Box
              as='tr'
              {...headerGroup.getHeaderGroupProps()}
              key={idx}
              css={{
                borderBottom: '1px solid $itemBorder',
                visibility: noHeader ? 'collapse' : 'visible',
              }}
            >
              {headerGroup.headers.map((column, columnIdx) => (
                // eslint-disable-next-line react/jsx-key
                <Th
                  {...column.getHeaderProps()}
                  column={column as unknown as ColumnInstance<D> & UseSortByColumnProps<D>}
                  setSortBy={setSortBy}
                  userColumn={columns[columnIdx]}
                  fontSize={fontSize}
                >
                  {column.render('Header')}
                </Th>
              ))}
            </Box>
          ))}
        </Box>

        <Box as='tbody' {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              // eslint-disable-next-line react/jsx-key
              <Box as='tr' {...row.getRowProps()}>
                {row.cells.map((cell, cellIdx) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <Td {...cell.getCellProps()} fontSize={fontSize} userColumn={columns[cellIdx]}>
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Overlay>
  );
};

export const Table = forwardRef(TableBody) as <D extends TableData>(
  props: TableProps<D> & BoxProps & { ref?: ForwardedRef<HTMLElement> },
) => ReturnType<typeof TableBody>;
