"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShareForm = exports.ShareDTO = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
var location_1 = require("./location");
var section_1 = require("./section");
var turbine_1 = require("./turbine");
exports.ShareDTO = zod_1.z.object({
    id: util_1.uuid.optional(),
    percentage: zod_1.z
        .number({ required_error: 'commonErrors.empty' })
        .nonnegative('commonErrors.nonnegative')
        .gt(0, 'commonErrors.gt')
        .max(100, 'Max 100%'),
    location: location_1.LocationDTO.optional().nullable(),
    section: section_1.SectionDTO.optional().nullable(),
    turbine: turbine_1.TurbineDTO.optional().nullable(),
    contractId: util_1.uuid.optional(),
    locationId: util_1.uuid.optional().nullable(),
    sectionId: util_1.uuid.optional().nullable(),
    turbineId: util_1.uuid.optional().nullable(),
});
exports.ShareForm = exports.ShareDTO.partial().superRefine(function (val, ctx) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    var parseRes = exports.ShareDTO.safeParse(val);
    if (!parseRes.success) {
        parseRes.error.issues.forEach(function (issue) {
            ctx.addIssue(issue);
        });
    }
    if (((_a = val.section) === null || _a === void 0 ? void 0 : _a.emsysPlantId) === '') {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            path: ['section.emsysPlantId'],
            message: 'commonErrors.empty',
        });
    }
    if (((_b = val.turbine) === null || _b === void 0 ? void 0 : _b.emsysPlantId) === '') {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            path: ['turbine.emsysPlantId'],
            message: 'commonErrors.empty',
        });
    }
    // NOTE: meteringPointId is not mandatory if the park unit has a commissioning in future
    if (((_c = val.turbine) === null || _c === void 0 ? void 0 : _c.commissioningDate) && new Date() > val.turbine.commissioningDate) {
        if (((_d = val.location) === null || _d === void 0 ? void 0 : _d.meteringPointId) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['location.meteringPointId'],
                message: 'commonErrors.empty',
            });
        }
        if (((_e = val.turbine) === null || _e === void 0 ? void 0 : _e.eegSystemKey) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['turbine.eegSystemKey'],
                message: 'commonErrors.empty',
            });
        }
        if (((_f = val.turbine) === null || _f === void 0 ? void 0 : _f.controllableResourceId) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['turbine.controllableResourceId'],
                message: 'commonErrors.empty',
            });
        }
        if (((_g = val.turbine) === null || _g === void 0 ? void 0 : _g.technicalResourceId) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['turbine.technicalResourceId'],
                message: 'commonErrors.empty',
            });
        }
        if (((_h = val.turbine) === null || _h === void 0 ? void 0 : _h.marketMasterDataRegisterNumberPerUnit) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['turbine.marketMasterDataRegisterNumberPerUnit'],
                message: 'commonErrors.empty',
            });
        }
        if (((_j = val.turbine) === null || _j === void 0 ? void 0 : _j.currentValueToBeApplied) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['turbine.currentValueToBeApplied'],
                message: 'commonErrors.empty',
            });
        }
    }
    if (((_k = val.section) === null || _k === void 0 ? void 0 : _k.commissioningDate) && new Date() > val.section.commissioningDate) {
        if (((_l = val.location) === null || _l === void 0 ? void 0 : _l.meteringPointId) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['location.meteringPointId'],
                message: 'commonErrors.empty',
            });
        }
        if (((_m = val.section) === null || _m === void 0 ? void 0 : _m.eegSystemKey) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['section.eegSystemKey'],
                message: 'commonErrors.empty',
            });
        }
        if (((_o = val.section) === null || _o === void 0 ? void 0 : _o.controllableResourceId) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['section.controllableResourceId'],
                message: 'commonErrors.empty',
            });
        }
        if (((_p = val.section) === null || _p === void 0 ? void 0 : _p.technicalResourceId) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['section.technicalResourceId'],
                message: 'commonErrors.empty',
            });
        }
        if (((_q = val.section) === null || _q === void 0 ? void 0 : _q.marketMasterDataRegisterNumberPerUnit) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['section.marketMasterDataRegisterNumberPerUnit'],
                message: 'commonErrors.empty',
            });
        }
        if (((_r = val.section) === null || _r === void 0 ? void 0 : _r.currentValueToBeApplied) === null) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ['section.currentValueToBeApplied'],
                message: 'commonErrors.empty',
            });
        }
    }
});
