import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { CompanyDTO } from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

export type CompanyWithName = {
  fullName: string;
} & CompanyDTO;

export const companiesApi = createApi({
  reducerPath: 'companies',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
  }),
  tagTypes: ['Companies'],
  endpoints: (builder) => ({
    upsert: builder.mutation<CompanyDTO, CompanyDTO>({
      query: (company) => ({
        url: '/api/companies',
        method: 'POST',
        body: company,
      }),
      invalidatesTags: ['Companies'],
    }),
    getAll: builder.query<CompanyWithName[], void>({
      query: () => '/api/companies',
      providesTags: ['Companies'],
      transformResponse: (companies: CompanyDTO[]) => {
        return companies.map((contactCompany) => ({
          ...contactCompany,
          fullName: `${contactCompany.contact.firstName} ${contactCompany.contact.lastName}`,
        }));
      },
    }),
    getById: builder.query<CompanyDTO, CompanyDTO['id']>({
      query: (id) => `/api/companies/${id}`,
      providesTags: ['Companies'],
    }),
    remove: builder.mutation<boolean, CompanyDTO['id']>({
      query: (id) => ({
        url: `/api/companies/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Companies'],
    }),
  }),
});

export const {
  endpoints,
  middleware,
  reducer,
  reducerPath,
  useUpsertMutation,
  useRemoveMutation,
  useGetAllQuery,
  useGetByIdQuery,
} = companiesApi;
