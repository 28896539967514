import { Box, Spinner } from '@rcl/rcl';

import { Layout } from '../Layout';

export const PageLoader = ({ withLayout = true }: { withLayout?: boolean }) => {
  if (withLayout) {
    return (
      <Layout>
        <Box css={{ height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spinner />
        </Box>
      </Layout>
    );
  }

  return (
    <Box css={{ height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Spinner />
    </Box>
  );
};
