import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { SuperAdmin } from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

export const adminsApi = createApi({
  reducerPath: 'admins',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
  }),
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getAll: builder.query<SuperAdmin[], void>({
      query: () => '/api/admins',
      providesTags: ['Users'],
    }),
  }),
});

export const { useGetAllQuery } = adminsApi;
