"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SingleOrMultipleContractIds = exports.MultipleContractIds = exports.SingleContractId = exports.ReportsWithContractIds = exports.ReportDTO = exports.S1FileColumnName = exports.s1FileColumnNames = exports.FlexibleResourcesColumnName = exports.flexibleResourcesColumnNames = exports.PerformanceNameEnum = exports.performanceNames = exports.PerformanceColumnName = exports.performanceColumnNames = exports.BankDetailsColumnName = exports.bankDetailsColumnNames = exports.SharesContractsVTP = exports.InvestmentAndUnitsColumnName = exports.investmentsAndUnitsColumnNames = exports.SheetNameEnum = exports.SheetNames = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
var SheetNames;
(function (SheetNames) {
    SheetNames["InvestmentsAndUnits"] = "Anlagen, Einheiten";
    SheetNames["SharesContractsVTP"] = "Anteile, Vertr\u00E4ge, VTP";
    SheetNames["BankDetails"] = "Bankverbindungen";
    SheetNames["Performance"] = "Leistungen";
    SheetNames["FlexibleResources"] = "flexible Ressourcen";
    SheetNames["S1File"] = "S1-Import-File";
})(SheetNames = exports.SheetNames || (exports.SheetNames = {}));
exports.SheetNameEnum = zod_1.z.nativeEnum(SheetNames);
exports.investmentsAndUnitsColumnNames = [
    'ANLAGE_EXID',
    'ANLAGE_TYP',
    'ANLAGE_BEZEICHNUNG',
    'ANLAGE_KURZBEZEICHNUNG',
    'ANLAGE_VON',
    'ANLAGE_BIS',
    'A_DETAIL_BEDEUTUNG_01',
    'A_DETAIL_WERT_01',
    'A_DETAIL_VON_01',
    'A_DETAIL_BIS_01',
    'A_DETAIL_BEMERKUNG_01',
    'A_DETAIL_BEDEUTUNG_02',
    'A_DETAIL_WERT_02',
    'A_DETAIL_VON_02',
    'A_DETAIL_BIS_02',
    'A_DETAIL_BEMERKUNG_02',
    'A_DETAIL_BEDEUTUNG_03',
    'A_DETAIL_WERT_03',
    'A_DETAIL_VON_03',
    'A_DETAIL_BIS_03',
    'A_DETAIL_BEMERKUNG_03',
    'A_DETAIL_BEDEUTUNG_04',
    'A_DETAIL_WERT_04',
    'A_DETAIL_VON_04',
    'A_DETAIL_BIS_04',
    'A_DETAIL_BEMERKUNG_04',
    'A_DETAIL_BEDEUTUNG_05',
    'A_DETAIL_WERT_05',
    'A_DETAIL_VON_05',
    'A_DETAIL_BIS_05',
    'A_DETAIL_BEMERKUNG_05',
    'A_DETAIL_BEDEUTUNG_06',
    'A_DETAIL_WERT_06',
    'A_DETAIL_VON_06',
    'A_DETAIL_BIS_06',
    'A_DETAIL_BEMERKUNG_06',
    'A_DETAIL_BEDEUTUNG_07',
    'A_DETAIL_WERT_07',
    'A_DETAIL_VON_07',
    'A_DETAIL_BIS_07',
    'A_DETAIL_BEMERKUNG_07',
    'A_DETAIL_BEDEUTUNG_08',
    'A_DETAIL_WERT_08',
    'A_DETAIL_VON_08',
    'A_DETAIL_BIS_08',
    'A_DETAIL_BEMERKUNG_08',
    'A_DETAIL_BEDEUTUNG_09',
    'A_DETAIL_WERT_09',
    'A_DETAIL_VON_09',
    'A_DETAIL_BIS_09',
    'A_DETAIL_BEMERKUNG_09',
    'A_DETAIL_BEDEUTUNG_10',
    'A_DETAIL_WERT_10',
    'A_DETAIL_VON_10',
    'A_DETAIL_BIS_10',
    'A_DETAIL_BEMERKUNG_10',
    'A_DETAIL_BEDEUTUNG_11',
    'A_DETAIL_WERT_11',
    'A_DETAIL_VON_11',
    'A_DETAIL_BIS_11',
    'A_DETAIL_BEMERKUNG_11',
    'A_DETAIL_BEDEUTUNG_12',
    'A_DETAIL_WERT_12',
    'A_DETAIL_VON_12',
    'A_DETAIL_BIS_12',
    'A_DETAIL_BEMERKUNG_12',
    'A_DETAIL_BEDEUTUNG_13',
    'A_DETAIL_WERT_13',
    'A_DETAIL_VON_13',
    'A_DETAIL_BIS_13',
    'A_DETAIL_BEMERKUNG_13',
    'A_DETAIL_BEDEUTUNG_14',
    'A_DETAIL_WERT_14',
    'A_DETAIL_VON_14',
    'A_DETAIL_BIS_14',
    'A_DETAIL_BEMERKUNG_14',
    'A_DETAIL_BEDEUTUNG_15',
    'A_DETAIL_WERT_15',
    'A_DETAIL_VON_15',
    'A_DETAIL_BIS_15',
    'A_DETAIL_BEMERKUNG_15',
    'A_DETAIL_BEDEUTUNG_16',
    'A_DETAIL_WERT_16',
    'A_DETAIL_VON_16',
    'A_DETAIL_BIS_16',
    'A_DETAIL_BEMERKUNG_16',
    'A_DETAIL_BEDEUTUNG_17',
    'A_DETAIL_WERT_17',
    'A_DETAIL_VON_17',
    'A_DETAIL_BIS_17',
    'A_DETAIL_BEMERKUNG_17',
    'A_DETAIL_BEDEUTUNG_18',
    'A_DETAIL_WERT_18',
    'A_DETAIL_VON_18',
    'A_DETAIL_BIS_18',
    'A_DETAIL_BEMERKUNG_18',
    'A_DETAIL_BEDEUTUNG_19',
    'A_DETAIL_WERT_19',
    'A_DETAIL_VON_19',
    'A_DETAIL_BIS_19',
    'A_DETAIL_BEMERKUNG_19',
    'A_DETAIL_BEDEUTUNG_20',
    'A_DETAIL_WERT_20',
    'A_DETAIL_VON_20',
    'A_DETAIL_BIS_20',
    'A_DETAIL_BEMERKUNG_20',
    'A_DETAIL_BEDEUTUNG_21',
    'A_DETAIL_WERT_21',
    'A_DETAIL_VON_21',
    'A_DETAIL_BIS_21',
    'A_DETAIL_BEMERKUNG_21',
    'A_DETAIL_BEDEUTUNG_22',
    'A_DETAIL_WERT_22',
    'A_DETAIL_VON_22',
    'A_DETAIL_BIS_22',
    'A_DETAIL_BEMERKUNG_22',
    'A_DETAIL_BEDEUTUNG_23',
    'A_DETAIL_WERT_23',
    'A_DETAIL_VON_23',
    'A_DETAIL_BIS_23',
    'A_DETAIL_BEMERKUNG_23',
    'A_DETAIL_BEDEUTUNG_24',
    'A_DETAIL_WERT_24',
    'A_DETAIL_VON_24',
    'A_DETAIL_BIS_24',
    'A_DETAIL_BEMERKUNG_24',
    'A_DETAIL_BEDEUTUNG_25',
    'A_DETAIL_WERT_25',
    'A_DETAIL_VON_25',
    'A_DETAIL_BIS_25',
    'A_DETAIL_BEMERKUNG_25',
    'A_DETAIL_BEDEUTUNG_26',
    'A_DETAIL_WERT_26',
    'A_DETAIL_VON_26',
    'A_DETAIL_BIS_26',
    'A_DETAIL_BEMERKUNG_26',
    'A_DETAIL_BEDEUTUNG_27',
    'A_DETAIL_WERT_27',
    'A_DETAIL_VON_27',
    'A_DETAIL_BIS_27',
    'A_DETAIL_BEMERKUNG_27',
    'A_DETAIL_BEDEUTUNG_28',
    'A_DETAIL_WERT_28',
    'A_DETAIL_VON_28',
    'A_DETAIL_BIS_28',
    'A_DETAIL_BEMERKUNG_28',
    'A_DETAIL_BEDEUTUNG_29',
    'A_DETAIL_WERT_29',
    'A_DETAIL_VON_29',
    'A_DETAIL_BIS_29',
    'A_DETAIL_BEMERKUNG_29',
    'A_DETAIL_BEDEUTUNG_30',
    'A_DETAIL_WERT_30',
    'A_DETAIL_VON_30',
    'A_DETAIL_BIS_30',
    'A_DETAIL_BEMERKUNG_30',
    'A_DETAIL_BEDEUTUNG_31',
    'A_DETAIL_WERT_31',
    'A_DETAIL_VON_31',
    'A_DETAIL_BIS_31',
    'A_DETAIL_BEMERKUNG_31',
    'A_DETAIL_BEDEUTUNG_32',
    'A_DETAIL_WERT_32',
    'A_DETAIL_VON_32',
    'A_DETAIL_BIS_32',
    'A_DETAIL_BEMERKUNG_32',
    'A_DETAIL_BEDEUTUNG_33',
    'A_DETAIL_WERT_33',
    'A_DETAIL_VON_33',
    'A_DETAIL_BIS_33',
    'A_DETAIL_BEMERKUNG_33',
    'A_DETAIL_BEDEUTUNG_34',
    'A_DETAIL_WERT_34',
    'A_DETAIL_VON_34',
    'A_DETAIL_BIS_34',
    'A_DETAIL_BEMERKUNG_34',
    'A_DETAIL_BEDEUTUNG_35',
    'A_DETAIL_WERT_35',
    'A_DETAIL_VON_35',
    'A_DETAIL_BIS_35',
    'A_DETAIL_BEMERKUNG_35',
    'A_DETAIL_BEDEUTUNG_36',
    'A_DETAIL_WERT_36',
    'A_DETAIL_VON_36',
    'A_DETAIL_BIS_36',
    'A_DETAIL_BEMERKUNG_36',
    'A_DETAIL_BEDEUTUNG_37',
    'A_DETAIL_WERT_37',
    'A_DETAIL_VON_37',
    'A_DETAIL_BIS_37',
    'A_DETAIL_BEMERKUNG_37',
    'A_DETAIL_BEDEUTUNG_38',
    'A_DETAIL_WERT_38',
    'A_DETAIL_VON_38',
    'A_DETAIL_BIS_38',
    'A_DETAIL_BEMERKUNG_38',
    'A_DETAIL_BEDEUTUNG_39',
    'A_DETAIL_WERT_39',
    'A_DETAIL_VON_39',
    'A_DETAIL_BIS_39',
    'A_DETAIL_BEMERKUNG_39',
    'A_DETAIL_BEDEUTUNG_40',
    'A_DETAIL_WERT_40',
    'A_DETAIL_VON_40',
    'A_DETAIL_BIS_40',
    'A_DETAIL_BEMERKUNG_40',
    'A_DETAIL_BEDEUTUNG_41',
    'A_DETAIL_WERT_41',
    'A_DETAIL_VON_41',
    'A_DETAIL_BIS_41',
    'A_DETAIL_BEMERKUNG_41',
    'A_DETAIL_BEDEUTUNG_42',
    'A_DETAIL_WERT_42',
    'A_DETAIL_VON_42',
    'A_DETAIL_BIS_42',
    'A_DETAIL_BEMERKUNG_42',
    'A_DETAIL_BEDEUTUNG_43',
    'A_DETAIL_WERT_43',
    'A_DETAIL_VON_43',
    'A_DETAIL_BIS_43',
    'A_DETAIL_BEMERKUNG_43',
    'A_DETAIL_BEDEUTUNG_44',
    'A_DETAIL_WERT_44',
    'A_DETAIL_VON_44',
    'A_DETAIL_BIS_44',
    'A_DETAIL_BEMERKUNG_44',
    'A_DETAIL_BEDEUTUNG_45',
    'A_DETAIL_WERT_45',
    'A_DETAIL_VON_45',
    'A_DETAIL_BIS_45',
    'A_DETAIL_BEMERKUNG_45',
    'A_DETAIL_BEDEUTUNG_46',
    'A_DETAIL_WERT_46',
    'A_DETAIL_VON_46',
    'A_DETAIL_BIS_46',
    'A_DETAIL_BEMERKUNG_46',
    'A_DETAIL_BEDEUTUNG_47',
    'A_DETAIL_WERT_47',
    'A_DETAIL_VON_47',
    'A_DETAIL_BIS_47',
    'A_DETAIL_BEMERKUNG_47',
    'A_DETAIL_BEDEUTUNG_48',
    'A_DETAIL_WERT_48',
    'A_DETAIL_VON_48',
    'A_DETAIL_BIS_48',
    'A_DETAIL_BEMERKUNG_48',
    'A_DETAIL_BEDEUTUNG_49',
    'A_DETAIL_WERT_49',
    'A_DETAIL_VON_49',
    'A_DETAIL_BIS_49',
    'A_DETAIL_BEMERKUNG_49',
    'A_DETAIL_BEDEUTUNG_50',
    'A_DETAIL_WERT_50',
    'A_DETAIL_VON_50',
    'A_DETAIL_BIS_50',
    'A_DETAIL_BEMERKUNG_50',
    'A_DETAIL_BEDEUTUNG_51',
    'A_DETAIL_WERT_51',
    'A_DETAIL_VON_51',
    'A_DETAIL_BIS_51',
    'A_DETAIL_BEMERKUNG_51',
    'A_DETAIL_BEDEUTUNG_52',
    'A_DETAIL_WERT_52',
    'A_DETAIL_VON_52',
    'A_DETAIL_BIS_52',
    'A_DETAIL_BEMERKUNG_52',
    'A_DETAIL_BEDEUTUNG_53',
    'A_DETAIL_WERT_53',
    'A_DETAIL_VON_53',
    'A_DETAIL_BIS_53',
    'A_DETAIL_BEMERKUNG_53',
    'A_DETAIL_BEDEUTUNG_54',
    'A_DETAIL_WERT_54',
    'A_DETAIL_VON_54',
    'A_DETAIL_BIS_54',
    'A_DETAIL_BEMERKUNG_54',
    'A_DETAIL_BEDEUTUNG_55',
    'A_DETAIL_WERT_55',
    'A_DETAIL_VON_55',
    'A_DETAIL_BIS_55',
    'A_DETAIL_BEMERKUNG_55',
    'A_DETAIL_BEDEUTUNG_56',
    'A_DETAIL_WERT_56',
    'A_DETAIL_VON_56',
    'A_DETAIL_BIS_56',
    'A_DETAIL_BEMERKUNG_56',
    'A_DETAIL_BEDEUTUNG_57',
    'A_DETAIL_WERT_57',
    'A_DETAIL_VON_57',
    'A_DETAIL_BIS_57',
    'A_DETAIL_BEMERKUNG_57',
    'A_DETAIL_BEDEUTUNG_58',
    'A_DETAIL_WERT_58',
    'A_DETAIL_VON_58',
    'A_DETAIL_BIS_58',
    'A_DETAIL_BEMERKUNG_58',
    'A_DETAIL_BEDEUTUNG_59',
    'A_DETAIL_WERT_59',
    'A_DETAIL_VON_59',
    'A_DETAIL_BIS_59',
    'A_DETAIL_BEMERKUNG_59',
    'A_DETAIL_BEDEUTUNG_60',
    'A_DETAIL_WERT_60',
    'A_DETAIL_VON_60',
    'A_DETAIL_BIS_60',
    'A_DETAIL_BEMERKUNG_60',
    'A_DETAIL_BEDEUTUNG_61',
    'A_DETAIL_WERT_61',
    'A_DETAIL_VON_61',
    'A_DETAIL_BIS_61',
    'A_DETAIL_BEMERKUNG_61',
    'A_DETAIL_BEDEUTUNG_62',
    'A_DETAIL_WERT_62',
    'A_DETAIL_VON_62',
    'A_DETAIL_BIS_62',
    'A_DETAIL_BEMERKUNG_62',
    'A_DETAIL_BEDEUTUNG_63',
    'A_DETAIL_WERT_63',
    'A_DETAIL_VON_63',
    'A_DETAIL_BIS_63',
    'A_DETAIL_BEMERKUNG_63',
    'A_DETAIL_BEDEUTUNG_64',
    'A_DETAIL_WERT_64',
    'A_DETAIL_VON_64',
    'A_DETAIL_BIS_64',
    'A_DETAIL_BEMERKUNG_64',
    'A_DETAIL_BEDEUTUNG_65',
    'A_DETAIL_WERT_65',
    'A_DETAIL_VON_65',
    'A_DETAIL_BIS_65',
    'A_DETAIL_BEMERKUNG_65',
    'A_DETAIL_BEDEUTUNG_66',
    'A_DETAIL_WERT_66',
    'A_DETAIL_VON_66',
    'A_DETAIL_BIS_66',
    'A_DETAIL_BEMERKUNG_66',
    'A_DETAIL_BEDEUTUNG_67',
    'A_DETAIL_WERT_67',
    'A_DETAIL_VON_67',
    'A_DETAIL_BIS_67',
    'A_DETAIL_BEMERKUNG_67',
    'A_DETAIL_BEDEUTUNG_68',
    'A_DETAIL_WERT_68',
    'A_DETAIL_VON_68',
    'A_DETAIL_BIS_68',
    'A_DETAIL_BEMERKUNG_68',
    'A_DETAIL_BEDEUTUNG_69',
    'A_DETAIL_WERT_69',
    'A_DETAIL_VON_69',
    'A_DETAIL_BIS_69',
    'A_DETAIL_BEMERKUNG_69',
    'A_DETAIL_BEDEUTUNG_70',
    'A_DETAIL_WERT_70',
    'A_DETAIL_VON_70',
    'A_DETAIL_BIS_70',
    'A_DETAIL_BEMERKUNG_70',
    'ZPT_EXID',
    'ZPT_BEZEICHNUNG',
    'ZPT_TYP',
    'ZPT_VON',
    'ZPT_BIS',
    'EINHEIT_EXID',
    'EINHEIT_BEZEICHNUNG',
    'EINHEIT_ANLAGENSCHLUESSEL',
    'EINHEIT_STANDORT',
    'EINHEIT_HOEHE_U_MEER',
    'EINHEIT_VON',
    'EINHEIT_BIS',
    'EINHEIT_INBETRIEBNAHME',
    'EINHEIT_FOERDERUNG_VON',
    'EINHEIT_FOERDERUNG_BIS',
    'EINHEIT_HERSTELLER',
    'EINHEIT_TYP',
    'EINHEIT_BEMESSUNGSLEISTUNG',
    'EINHEIT_INST_LEISTUNG',
    'EINHEIT_REFERENZERTRAG',
    'EINHEIT_MIN_LEISTUNG',
    'EINHEIT_VIRT_KW_VON',
    'EINHEIT_VIRT_KW_BIS',
    'EINHEIT_KOORD_X',
    'EINHEIT_KOORD_Y',
    'EINHEIT_KOORDINATENSYSTEM',
    'EINHEIT_S_STANDORT',
    'EINHEIT_S_ANZ_PANEL',
    'EINHEIT_S_AUSRICHTUNG',
    'EINHEIT_S_NEIGUNGSWINKEL',
    'EINHEIT_W_NABENHOEHE',
    'EINHEIT_W_ROTORDURCHMESSER',
    'EINHEIT_W_ROTORKREISFLAECHE',
    'E_DETAIL_BEDEUTUNG_01',
    'E_DETAIL_WERT_01',
    'E_DETAIL_VON_01',
    'E_DETAIL_BIS_01',
    'E_DETAIL_BEMERKUNG_01',
    'E_DETAIL_BEDEUTUNG_02',
    'E_DETAIL_WERT_02',
    'E_DETAIL_VON_02',
    'E_DETAIL_BIS_02',
    'E_DETAIL_BEMERKUNG_02',
    'E_DETAIL_BEDEUTUNG_03',
    'E_DETAIL_WERT_03',
    'E_DETAIL_VON_03',
    'E_DETAIL_BIS_03',
    'E_DETAIL_BEMERKUNG_03',
    'E_DETAIL_BEDEUTUNG_04',
    'E_DETAIL_WERT_04',
    'E_DETAIL_VON_04',
    'E_DETAIL_BIS_04',
    'E_DETAIL_BEMERKUNG_04',
    'E_DETAIL_BEDEUTUNG_05',
    'E_DETAIL_WERT_05',
    'E_DETAIL_VON_05',
    'E_DETAIL_BIS_05',
    'E_DETAIL_BEMERKUNG_05',
    'E_DETAIL_BEDEUTUNG_06',
    'E_DETAIL_WERT_06',
    'E_DETAIL_VON_06',
    'E_DETAIL_BIS_06',
    'E_DETAIL_BEMERKUNG_06',
    'E_DETAIL_BEDEUTUNG_07',
    'E_DETAIL_WERT_07',
    'E_DETAIL_VON_07',
    'E_DETAIL_BIS_07',
    'E_DETAIL_BEMERKUNG_07',
    'E_DETAIL_BEDEUTUNG_08',
    'E_DETAIL_WERT_08',
    'E_DETAIL_VON_08',
    'E_DETAIL_BIS_08',
    'E_DETAIL_BEMERKUNG_08',
    'E_DETAIL_BEDEUTUNG_09',
    'E_DETAIL_WERT_09',
    'E_DETAIL_VON_09',
    'E_DETAIL_BIS_09',
    'E_DETAIL_BEMERKUNG_09',
    'E_DETAIL_BEDEUTUNG_10',
    'E_DETAIL_WERT_10',
    'E_DETAIL_VON_10',
    'E_DETAIL_BIS_10',
    'E_DETAIL_BEMERKUNG_10',
    'E_DETAIL_BEDEUTUNG_11',
    'E_DETAIL_WERT_11',
    'E_DETAIL_VON_11',
    'E_DETAIL_BIS_11',
    'E_DETAIL_BEMERKUNG_11',
    'E_DETAIL_BEDEUTUNG_12',
    'E_DETAIL_WERT_12',
    'E_DETAIL_VON_12',
    'E_DETAIL_BIS_12',
    'E_DETAIL_BEMERKUNG_12',
    'E_DETAIL_BEDEUTUNG_13',
    'E_DETAIL_WERT_13',
    'E_DETAIL_VON_13',
    'E_DETAIL_BIS_13',
    'E_DETAIL_BEMERKUNG_13',
    'E_DETAIL_BEDEUTUNG_14',
    'E_DETAIL_WERT_14',
    'E_DETAIL_VON_14',
    'E_DETAIL_BIS_14',
    'E_DETAIL_BEMERKUNG_14',
    'E_DETAIL_BEDEUTUNG_15',
    'E_DETAIL_WERT_15',
    'E_DETAIL_VON_15',
    'E_DETAIL_BIS_15',
    'E_DETAIL_BEMERKUNG_15',
    'E_DETAIL_BEDEUTUNG_16',
    'E_DETAIL_WERT_16',
    'E_DETAIL_VON_16',
    'E_DETAIL_BIS_16',
    'E_DETAIL_BEMERKUNG_16',
    'E_DETAIL_BEDEUTUNG_17',
    'E_DETAIL_WERT_17',
    'E_DETAIL_VON_17',
    'E_DETAIL_BIS_17',
    'E_DETAIL_BEMERKUNG_17',
    'E_DETAIL_BEDEUTUNG_18',
    'E_DETAIL_WERT_18',
    'E_DETAIL_VON_18',
    'E_DETAIL_BIS_18',
    'E_DETAIL_BEMERKUNG_18',
    'E_DETAIL_BEDEUTUNG_19',
    'E_DETAIL_WERT_19',
    'E_DETAIL_VON_19',
    'E_DETAIL_BIS_19',
    'E_DETAIL_BEMERKUNG_19',
    'E_DETAIL_BEDEUTUNG_20',
    'E_DETAIL_WERT_20',
    'E_DETAIL_VON_20',
    'E_DETAIL_BIS_20',
    'E_DETAIL_BEMERKUNG_20',
    'E_DETAIL_BEDEUTUNG_21',
    'E_DETAIL_WERT_21',
    'E_DETAIL_VON_21',
    'E_DETAIL_BIS_21',
    'E_DETAIL_BEMERKUNG_21',
    'E_DETAIL_BEDEUTUNG_22',
    'E_DETAIL_WERT_22',
    'E_DETAIL_VON_22',
    'E_DETAIL_BIS_22',
    'E_DETAIL_BEMERKUNG_22',
    'E_DETAIL_BEDEUTUNG_23',
    'E_DETAIL_WERT_23',
    'E_DETAIL_VON_23',
    'E_DETAIL_BIS_23',
    'E_DETAIL_BEMERKUNG_23',
    'E_DETAIL_BEDEUTUNG_24',
    'E_DETAIL_WERT_24',
    'E_DETAIL_VON_24',
    'E_DETAIL_BIS_24',
    'E_DETAIL_BEMERKUNG_24',
    'E_DETAIL_BEDEUTUNG_25',
    'E_DETAIL_WERT_25',
    'E_DETAIL_VON_25',
    'E_DETAIL_BIS_25',
    'E_DETAIL_BEMERKUNG_25',
    'VERGUETUNG_VON',
    'VERGUETUNG_BIS',
    'VERGUETUNG_BEMERKUNG',
    'VERGUETUNG_DETAIL_BEDEUTUNG_01',
    'VERGUETUNG_DETAIL_WERT_01',
    'VERGUETUNG_DETAIL_BEDEUTUNG_02',
    'VERGUETUNG_DETAIL_WERT_02',
    'VERGUETUNG_DETAIL_BEDEUTUNG_03',
    'VERGUETUNG_DETAIL_WERT_03',
    'VERGUETUNG_DETAIL_BEDEUTUNG_04',
    'VERGUETUNG_DETAIL_WERT_04',
    'VERGUETUNG_DETAIL_BEDEUTUNG_05',
    'VERGUETUNG_DETAIL_WERT_05',
    'VERGUETUNG_DETAIL_BEDEUTUNG_06',
    'VERGUETUNG_DETAIL_WERT_06',
    'VERGUETUNG_DETAIL_BEDEUTUNG_07',
    'VERGUETUNG_DETAIL_WERT_07',
    'VERGUETUNG_DETAIL_BEDEUTUNG_08',
    'VERGUETUNG_DETAIL_WERT_08',
    'VERGUETUNG_DETAIL_BEDEUTUNG_09',
    'VERGUETUNG_DETAIL_WERT_09',
    'VERGUETUNG_DETAIL_BEDEUTUNG_10',
    'VERGUETUNG_DETAIL_WERT_10',
    'VERGUETUNG_DETAIL_BEDEUTUNG_11',
    'VERGUETUNG_DETAIL_WERT_11',
    'VERGUETUNG_DETAIL_BEDEUTUNG_12',
    'VERGUETUNG_DETAIL_WERT_12',
    'VERGUETUNG_DETAIL_BEDEUTUNG_13',
    'VERGUETUNG_DETAIL_WERT_13',
    'VERGUETUNG_DETAIL_BEDEUTUNG_14',
    'VERGUETUNG_DETAIL_WERT_14',
    'VERGUETUNG_DETAIL_BEDEUTUNG_15',
    'VERGUETUNG_DETAIL_WERT_15',
    'VERGUETUNG_DETAIL_BEDEUTUNG_16',
    'VERGUETUNG_DETAIL_WERT_16',
    'VERGUETUNG_DETAIL_BEDEUTUNG_17',
    'VERGUETUNG_DETAIL_WERT_17',
    'VERGUETUNG_DETAIL_BEDEUTUNG_18',
    'VERGUETUNG_DETAIL_WERT_18',
    'VERGUETUNG_DETAIL_BEDEUTUNG_19',
    'VERGUETUNG_DETAIL_WERT_19',
    'VERGUETUNG_DETAIL_BEDEUTUNG_20',
    'VERGUETUNG_DETAIL_WERT_20',
    'E_PARAMETER_VON',
    'E_PARAMETER_BIS',
    'E_PARAMETER_NAME_01',
    'E_PARAMETER_WERT_01',
    'E_PARAMETER_NAME_02',
    'E_PARAMETER_WERT_02',
    'E_PARAMETER_NAME_03',
    'E_PARAMETER_WERT_03',
    'E_PARAMETER_NAME_04',
    'E_PARAMETER_WERT_04',
    'E_PARAMETER_NAME_05',
    'E_PARAMETER_WERT_05',
    'EINHEIT_ENERGIERICHTUNG',
    'EINHEIT_INBETRIEBNAHME_TECHNISCH',
    'EINHEIT_STILLLEGUNG_ENDGUELTIG',
    'EINHEIT_NB_IDENTIFIKATOR',
    'EINHEIT_SCHWARZSTARTFAEHIGKEIT_J_N',
    'EINHEIT_INSELBETRIEBSFAEHIGKEIT_J_N',
    'EINHEIT_FERNSTEUERBARKEIT_NB_J_N',
    'EINHEIT_FERNSTEUERBARKEIT_DV_J_N',
    'EINHEIT_FERNSTEUERBARKEIT_DRITT_J_N',
    'EINHEIT_G1_GASTECHNOLOGIE',
    'EINHEIT_G2_GASSPEICHER_ART',
    'EINHEIT_G2_ARBEITSGASVOLUMEN_MAX',
    'EINHEIT_G2_SPEICHERKAPAZITAET_KWH',
    'EINHEIT_B1_BIOMASSE_ART',
    'EINHEIT_B1_BIO_FLEXI_IA_NAHME_J_N',
    'EINHEIT_B1_BIO_FLEXIBILITAET_VON',
    'EINHEIT_B1_BIO_FLEXIBILITAET_BIS',
    'EINHEIT_K1_EXID_VTP_BRENNGAS_NB',
    'EINHEIT_K1_KONVENTIONELL_ART',
    'EINHEIT_S1_SPEICHERKAPAZITAET_KWH',
    'EINHEIT_UTM_ZONE',
    'EINHEIT_GEMEINDESCHLUESSEL',
    'EINHEIT_GEMARKUNG',
    'EINHEIT_GEMARKUNG_BEZEICHNUNG',
    'EINHEIT_FLUR',
    'EINHEIT_FLUR_BEZEICHNUNG',
    'EINHEIT_FLURSTUECK',
    'EINHEIT_FLURSTUECK_BEZEICHNUNG',
    'MALO_IDENTIFIKATOR',
    'MALO_VON',
    'MALO_BIS',
    'ANLAGE_SUBTYP',
    'ANLAGE_LAENDERKUERZEL',
    'ANLAGE_PLZ',
    'ANLAGE_ORT',
    'ANLAGE_STRASSE',
    'ANLAGE_HAUSNR',
    'ANLAGE_HAUSNRZS',
    'ANLAGE_GEBIET',
    'STANDORT_EXID',
    'MALO_KATEGORIE',
    'MEDIENART',
    'ZPT_ART',
    'EINHEIT_W_PILOTWINDANLAGE_J_N',
    'EINHEIT_W_PROTOTYP_J_N',
    'EINHEIT_S_WIRKUNGSGRAD',
    'EINHEIT_S_WIRKUNGSGRAD_VON',
    'EINHEIT_S_WIRKUNGSGRAD_BIS',
    'EINHEIT_S_GENERATORFLAECHE',
    'EINHEIT_S_GENERATORFLAECHE_VON',
    'EINHEIT_S_GENERATORFLAECHE_BIS',
    'EINHEIT_FLXRES_EXID',
    'EINHEIT_FLXRES_VON',
    'EINHEIT_FLXRES_BIS',
    'EINHEIT_ZPT_EXID',
    'EINHEIT_ZPT_MET_CODE',
    'EINHEIT_ZPT_ZUO_TYP',
    'EINHEIT_ZPT_ART',
    'EINHEIT_ZPT_VON',
    'EINHEIT_ZPT_BIS',
    'EINHEIT_MEDIENART',
    'EINHEIT_EIC_CODE_W',
    'EINHEIT_MASTR_ID',
    'MALO_BILANZKREIS',
    'MALO_LIEFERANT',
    'MALO_MELOS',
    'MALO_IDENTIFIKATOR_2',
    'MALO_VON_2',
    'MALO_BIS_2',
    'MALO_KATEGORIE_2',
    'MALO_BILANZKREIS_2',
    'MALO_LIEFERANT_2',
    'MALO_MELOS_2',
    'EINHEIT_STILLLEGUNG_VORLAEUFIG',
    'EINHEIT_SPEICHER_EXID',
    'EINHEIT_SPEICHER_VON',
    'EINHEIT_SPEICHER_BIS',
    'IDENTIFIKATOR',
];
exports.InvestmentAndUnitsColumnName = zod_1.z.enum(exports.investmentsAndUnitsColumnNames);
exports.SharesContractsVTP = zod_1.z.object({
    VTR_DETAIL_BEDEUTUNG_01: zod_1.z.union([zod_1.z.literal('Gebühr_Dienstleistung'), zod_1.z.literal('Zusatzgebühr')]),
    VTR_DETAIL_WERT_01: zod_1.z.number(),
    VTR_DETAIL_VON_01: util_1.zodDate,
    VTR_DETAIL_BIS_01: util_1.zodDate.nullable(),
    VTR_DETAIL_BEDEUTUNG_02: zod_1.z.literal('VDN-Kürzel'),
    VTR_DETAIL_WERT_02: zod_1.z.string(),
    VTR_DETAIL_VON_02: util_1.zodDate,
    VTR_DETAIL_BIS_02: util_1.zodDate.nullable(),
    VTR_DETAIL_BEDEUTUNG_03: zod_1.z.literal('VAT-Eindeutigkeitsmerkmal'),
    VTR_DETAIL_WERT_03: zod_1.z.number(),
    VTR_DETAIL_VON_03: util_1.zodDate,
    VTR_DETAIL_BIS_03: util_1.zodDate.nullable(),
    VTR_DETAIL_BEDEUTUNG_04: zod_1.z.literal('ANE_Nummer'),
    VTR_DETAIL_WERT_04: zod_1.z.string(),
    VTR_DETAIL_VON_04: util_1.zodDate,
    VTR_DETAIL_BIS_04: util_1.zodDate.nullable(),
    VTR_DETAIL_BEDEUTUNG_05: zod_1.z.literal('REM_E-Mail'),
    VTR_DETAIL_WERT_05: zod_1.z.string(),
    VTR_DETAIL_VON_05: util_1.zodDate,
    VTR_DETAIL_BIS_05: util_1.zodDate.nullable(),
    VTR_DETAIL_BEDEUTUNG_06: zod_1.z.literal('REM_Firmierung'),
    VTR_DETAIL_WERT_06: zod_1.z.string(),
    VTR_DETAIL_VON_06: util_1.zodDate,
    VTR_DETAIL_BIS_06: util_1.zodDate.nullable(),
    VTR_DETAIL_BEDEUTUNG_07: zod_1.z.literal('POS_Firmierung'),
    VTR_DETAIL_WERT_07: zod_1.z.string(),
    VTR_DETAIL_VON_07: util_1.zodDate,
    VTR_DETAIL_BIS_07: util_1.zodDate.nullable(),
    VTR_DETAIL_BEDEUTUNG_08: zod_1.z.literal('Bündelrechnung'),
    VTR_DETAIL_WERT_08: zod_1.z.string(),
    VTR_DETAIL_VON_08: util_1.zodDate,
    VTR_DETAIL_BIS_08: util_1.zodDate.nullable(),
    VTR_DETAIL_BEDEUTUNG_09: zod_1.z.literal('Vertriebspartner'),
    VTR_DETAIL_WERT_09: zod_1.z.string(),
    VTR_DETAIL_VON_09: util_1.zodDate,
    VTR_DETAIL_BIS_09: util_1.zodDate.nullable(),
});
exports.bankDetailsColumnNames = [
    'VTP_EXID',
    'VTP_VERTRAGSPARTNERART',
    'VTR_EXID',
    'VTR_ART',
    'VTR_MEDIENART',
    'VTR_ZUO_VON',
    'VTR_ZUO_BIS',
    'BVD_EXID',
    'BVD_BANK',
    'BVD_BLZ',
    'BVD_KTO',
    'BVD_IBAN',
    'BVD_SWIFT',
    'BVD_WIRT_IDENT',
    'BVD_UMSATZ_IDENT',
    'BVD_STEUER_IDENT',
    'BVD_ABW_KTOINH',
    'BVD_VON',
    'BVD_BIS',
    'BVD_VTP_MODUS',
];
exports.BankDetailsColumnName = zod_1.z.enum(exports.bankDetailsColumnNames);
exports.performanceColumnNames = [
    'EINHEITEN_EXID',
    'NAME',
    'WERT',
    'MASSEINHEIT',
    'GUELTIG_VON',
    'GUELTIG_BIS',
];
exports.PerformanceColumnName = zod_1.z.enum(exports.performanceColumnNames);
exports.performanceNames = ['P_MIN', 'P_INST', 'REF_ERTRAG'];
exports.PerformanceNameEnum = zod_1.z.enum(exports.performanceNames);
exports.flexibleResourcesColumnNames = [
    'FLXRES_EXID',
    'IDENTIFIKATOR',
    'BEZEICHNUNG',
    'TYP',
    'FLXRES_VON',
    'FLXRES_BIS',
    'FLXRES_EXID_M',
    'FLXRES_VON_M',
    'FLXRES_BIS_M',
    'ZPT_EXID',
    'ZPT_MET_CODE',
    'ZPT_ZUO_TYP',
    'ZPT_ART',
    'ZPT_VON',
    'ZPT_BIS',
    'MEDIENART',
    'F_DETAIL_BEDEUTUNG_01',
    'F_DETAIL_WERT_01',
    'F_DETAIL_VON_01',
    'F_DETAIL_BIS_01',
    'F_DETAIL_BEMERKUNG_01',
    'F_DETAIL_BEDEUTUNG_02',
    'F_DETAIL_WERT_02',
    'F_DETAIL_VON_02',
    'F_DETAIL_BIS_02',
    'F_DETAIL_BEMERKUNG_02',
    'F_DETAIL_BEDEUTUNG_03',
    'F_DETAIL_WERT_03',
    'F_DETAIL_VON_03',
    'F_DETAIL_BIS_03',
    'F_DETAIL_BEMERKUNG_03',
    'F_DETAIL_BEDEUTUNG_04',
    'F_DETAIL_WERT_04',
    'F_DETAIL_VON_04',
    'F_DETAIL_BIS_04',
    'F_DETAIL_BEMERKUNG_04',
    'F_DETAIL_BEDEUTUNG_05',
    'F_DETAIL_WERT_05',
    'F_DETAIL_VON_05',
    'F_DETAIL_BIS_05',
    'F_DETAIL_BEMERKUNG_05',
    'REGELZONE',
];
exports.FlexibleResourcesColumnName = zod_1.z.enum(exports.flexibleResourcesColumnNames);
exports.s1FileColumnNames = [
    'Nr',
    'BILANZKREIS',
    'VERTRAGSNUMMER',
    'VERTRAGSART',
    'VERTRAG_VON',
    'VERTRAG_BIS',
    'VTP_NAME',
    'VTP_NAME2',
    'VTP_ID',
    'VTP_KUNDENNUMMER',
    'KUNDE_VTP_ART',
    'VTP_STRASSE',
    'VTP_HAUSNR',
    'VTP_HAUSNRZS',
    'VTP_PLZ',
    'VTP_ORT',
    'VTP_LAND',
    'VTP_POSTFACH',
    'NA_BEZEICHNUNG',
    'NA_STRASSE',
    'NA_HAUSNR',
    'NA_HAUSNRZS',
    'NA_PLZ',
    'NA_ORT',
    'NA_LAND',
    'NA_NETZEBENE_ENT',
    'MEDIENART',
    'LIEFERANT',
    'ZAEHLPUNKT',
    'BILANZIERUNGSGEBIET',
    'NETZBETREIBER',
    'REGELZONE',
    'LASTPROFIL',
    'LASTPROFIL_VON',
    'BILANZIERUNGSKENNZEICHEN',
    'BILANZIERUNG_VON',
    'EE-Status',
    'EE-STATUS VON',
    'Status der Fernsteuerbarkeit',
    'EEG-KENNZEICHEN',
    'EEG-KENNZEICHEN VON',
    'Empfänger Vergütung',
    'Zahler Netznutzung',
    'Nicht Abmelden',
    'Nicht Anmelden',
    'NNV_NUMMER',
    'NNV_ART',
    'NNV_VON',
    'NNV_BIS',
    'NNV_ZAHLER_NN',
    'LIN_BEZEICHNUNG',
    'LIN_OBIS',
    'LIN_EINHEIT',
    'LIN_DATENQUELLENTYP',
    'LIN_PERIODE',
    'LIN_TYP',
    'LIN_KLV',
    'MARKTLOKATION',
    'DETAIL1_TYP',
    'DETAIL1_BEDEUTUNG',
    'DETAIL1_WERT',
    'DETAIL1_VON',
    'DETAIL2_TYP',
    'DETAIL2_BEDEUTUNG',
    'DETAIL2_WERT',
    'DETAIL2_VON',
];
exports.S1FileColumnName = zod_1.z.enum(exports.s1FileColumnNames);
exports.ReportDTO = zod_1.z.object({
    id: util_1.uuid.optional(),
    sharesContractsVTP: exports.SharesContractsVTP.merge(zod_1.z.object({})),
    contractId: util_1.uuid.optional(),
});
exports.ReportsWithContractIds = zod_1.z.object({ contractIds: zod_1.z.array(util_1.uuid) });
exports.SingleContractId = zod_1.z.object({ contractId: util_1.uuid });
exports.MultipleContractIds = zod_1.z.object({ contractId: zod_1.z.array(util_1.uuid) });
exports.SingleOrMultipleContractIds = zod_1.z.object({ contractId: zod_1.z.union([util_1.uuid, zod_1.z.array(util_1.uuid)]) });
