// https://digitaldesign.vattenfall.com/designers/components/typography/typography-introduction

import classNames from 'classnames';
import { forwardRef } from 'react';

import { css, CSSValue, PropertyValue, ScaleValue } from '../stitches.config';
import { AlignmentX, Color, FontWeight, HTMLTag } from '../types';
import { Box, BoxProps } from './Box';

export type TextProps<C extends HTMLTag = 'span'> = {
  align?: AlignmentX;
  bold?: boolean;
  size?: CSSValue<'fontSize' | 'fontSizes'>;
  color?: Color;
  font?: PropertyValue<'fontFamily'> | ScaleValue<'fonts'>;
  underline?: boolean;
  weight?: FontWeight;
} & BoxProps<C>;

export const Text = forwardRef<HTMLElement, TextProps<HTMLTag>>(function Text(
  {
    align,
    as = 'span',
    bold = false,
    className,
    color = '$text',
    font = '$default',
    size = '$md',
    underline = false,
    weight,
    ...props
  },
  ref,
) {
  return (
    <Box
      as={as}
      className={classNames(
        'vf-text',
        css({
          color,
          fontFamily: font,
          fontSize: size,
          fontWeight: weight,
          textAlign: align,
          variants: {
            bold: {
              true: {
                fontWeight: '$bold',
              },
            },
            underline: {
              true: {
                textDecoration: 'underline',
              },
            },
          },
        })({ bold, underline }),
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});

// export const Heading = forwardRef<HTMLElement, TextProps<HTMLTag>>(function Heading(
//   { bold = true, className, component = 'h1', ...props },
//   ref,
// ) {
//   return (
//     <Text
//       bold={bold}
//       className={classNames(
//         css({
//           variants: {
//             component: {
//               h1: {},
//             },
//           },
//         })({ component }),
//         className,
//       )}
//       component={component}
//       ref={ref}
//       {...props}
//     />
//   );
// });

// TODO use responsive tokens when possible https://github.com/modulz/stitches/discussions/284
// TODO why px not rem?
// TODO style headings globally?

export const Heading = forwardRef<HTMLElement, { level: 1 | 2 | 3 | 4 } & TextProps<HTMLTag>>(function Heading(
  { as, bold = true, className, level, ...props },
  ref,
) {
  return (
    <Text
      as={as || `h${level}`}
      bold={bold}
      className={classNames(
        css({
          variants: {
            level: {
              1: {
                fontFamily: '$VattenfallHallDisplay',
                fontSize: '34px',
                lineHeight: '40px',
                '@md': {
                  fontSize: '52px',
                  lineHeight: '62px',
                },
              },
              2: {
                fontFamily: '$VattenfallHallDisplay',
                fontSize: '28px',
                lineHeight: '32px',
                '@md': {
                  fontSize: '36px',
                  lineHeight: '42px',
                },
              },
              3: {
                fontSize: '22px',
                lineHeight: '28px',
                '@md': {
                  fontSize: '28px',
                  lineHeight: '36px',
                },
              },
              4: {
                fontSize: '18px',
                lineHeight: '24px',
                '@md': {
                  fontSize: '22px',
                  lineHeight: '28px',
                },
              },
            },
          },
        })({ level }),
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});

export const H1 = forwardRef<HTMLElement, TextProps<HTMLTag>>(function H1({ ...props }, ref) {
  return <Heading level={1} ref={ref} {...props} />;
});

export const H2 = forwardRef<HTMLElement, TextProps<HTMLTag>>(function H2({ ...props }, ref) {
  return <Heading level={2} ref={ref} {...props} />;
});

export const H3 = forwardRef<HTMLElement, TextProps<HTMLTag>>(function H3({ ...props }, ref) {
  return <Heading level={3} ref={ref} {...props} />;
});

export const H4 = forwardRef<HTMLElement, TextProps<HTMLTag>>(function H4({ ...props }, ref) {
  return <Heading level={4} ref={ref} {...props} />;
});
