"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TurbineStagingDTO = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
var turbine_1 = require("./turbine");
exports.TurbineStagingDTO = zod_1.z.object({
    id: util_1.uuid.optional(),
    unitName: zod_1.z.string().nonempty('commonErrors.empty'),
    commissioningDate: util_1.zodDate,
    dateOfReceivedAward: util_1.zodDate,
    eegSystemKey: zod_1.z
        .string()
        .regex(/^E[a-zA-Z0-9]{32}$/, 'shareForm.errors.eegSystemKey')
        .optional()
        .or(util_1.emptyStringToNull)
        .nullable(),
    controllableResourceId: zod_1.z
        .string()
        .regex(/^C[a-zA-Z0-9]{10}$/, 'shareForm.errors.controllableResourceId')
        .optional()
        .or(util_1.emptyStringToNull)
        .nullable(),
    srEanCode: zod_1.z.string().optional().nullable(),
    technicalResourceId: zod_1.z
        .string()
        .regex(/^D[a-zA-Z0-9]{10}$/, 'shareForm.errors.technicalResourceId')
        .optional()
        .or(util_1.emptyStringToNull)
        .nullable(),
    marketMasterDataRegisterNumberPerUnit: zod_1.z
        .string()
        .regex(/^SEE[a-zA-Z0-9]{12}$/, 'shareForm.errors.marketMasterDataRegisterNumberPerUnit')
        .optional()
        .or(util_1.emptyStringToNull)
        .nullable(),
    currentValueToBeApplied: zod_1.z.number().optional().nullable(),
    futureValueToBeApplied: zod_1.z.number().optional().nullable(),
    beginningOfValidityOfTheNewAW: zod_1.z
        .preprocess(function (arg) {
        if (typeof arg == 'string' || arg instanceof Date) {
            return new Date(arg);
        }
    }, zod_1.z.date({ required_error: 'commonErrors.selectDate' }))
        .optional()
        .nullable(),
    modelSeries: zod_1.z.string().nonempty('commonErrors.empty'),
    manufacturer: zod_1.z.string().nonempty('commonErrors.empty'),
    ratedPower: zod_1.z
        .number({ required_error: 'commonErrors.empty' })
        .nonnegative('commonErrors.nonnegative')
        .max(20000, 'Max 20000 kW'),
    hubHeight: zod_1.z
        .number({ required_error: 'commonErrors.empty' })
        .nonnegative('commonErrors.nonnegative')
        .max(500, 'Max 500m'),
    rotorDiameter: zod_1.z
        .number({ required_error: 'commonErrors.empty' })
        .nonnegative('commonErrors.nonnegative')
        .max(500, 'Max 500m'),
    serialNumber: zod_1.z.string().optional().nullable(),
    referenceYieldPerTurbine: zod_1.z.number({ required_error: 'commonErrors.empty' }).nonnegative('commonErrors.nonnegative'),
    latitude: zod_1.z
        .number({ required_error: 'commonErrors.empty' })
        .nonnegative('commonErrors.nonnegative')
        .max(99.999999, 'Max 99,999999'),
    longitude: zod_1.z
        .number({ required_error: 'commonErrors.empty' })
        .nonnegative('commonErrors.nonnegative')
        .max(99.999999, 'Max 99,999999'),
    isNightReduction: zod_1.z.boolean().nullable().optional(),
    powerReductionToMax: zod_1.z.string().nullable().optional(),
    turbine: turbine_1.TurbineDTO.optional().nullable(),
    locationId: util_1.uuid.optional().nullable(),
    parkId: util_1.uuid.optional().nullable(),
});
