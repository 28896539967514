import {
  IconBehaviour,
  IconBilling,
  IconContractDetails,
  IconHouse,
  IconLayers,
  IconListView,
  IconPricesRise,
  IconSolarPanel,
  IconSummary,
  IconUser,
} from '@rcl/rcl';
import { ComponentType, lazy } from 'react';

import { ErrorPage } from './components/pages/ErrorPage';
import { HomePage } from './components/pages/HomePage';
import { LoginPage } from './components/pages/LoginPage';
import { UsersPage } from './components/pages/Users';
import { WelcomePage } from './components/pages/WelcomePage';

const AddContract = lazy(() =>import('./components/pages/contracts/AddContract').then(({ AddContract }) => ({ default: AddContract }))) // prettier-ignore
const AddSection = lazy(() => import('./components/pages/contracts/AddSection').then(({ AddSection }) => ({ default: AddSection }))) // prettier-ignore
const AddTurbine = lazy(() => import('./components/pages/contracts/AddTurbine').then(({ AddTurbine }) => ({ default: AddTurbine }))) // prettier-ignore
const ContractsList = lazy(() => import('./components/pages/contracts/ContractsList').then(({ ContractsList }) => ({ default: ContractsList }))) // prettier-ignore
const CustomersList = lazy(() => import('./components/pages/CustomersList').then(({ CustomersList }) => ({ default: CustomersList }))) // prettier-ignore
const EditContract = lazy(() => import('./components/pages/contracts/EditContract').then(({ EditContract }) => ({ default: EditContract }))) // prettier-ignore
const EditSection = lazy(() => import('./components/pages/contracts/EditSection').then(({ EditSection }) => ({ default: EditSection }))) // prettier-ignore
const EditTurbine = lazy(() => import('./components/pages/contracts/EditTurbine').then(({ EditTurbine }) => ({ default: EditTurbine }))) // prettier-ignore
const ExportsList = lazy(() => import('./components/pages/ExportsList').then(({ ExportsList }) => ({ default: ExportsList }))) // prettier-ignore
const ReviewBilling = lazy(() => import('./components/pages/contracts/ReviewBilling').then(({ ReviewBilling }) => ({ default: ReviewBilling }))) // prettier-ignore
const ReviewContract = lazy(() =>import('./components/pages/contracts/ReviewContract').then(({ ReviewContract }) => ({ default: ReviewContract }))) // prettier-ignore
const ReviewPark = lazy(() => import('./components/pages/contracts/ReviewPark').then(({ ReviewPark }) => ({ default: ReviewPark }))) // prettier-ignore
const ReviewSharesList = lazy(() => import('./components/pages/contracts/ReviewSharesList').then(({ ReviewSharesList }) => ({ default: ReviewSharesList }))) // prettier-ignore
const SharesList = lazy(() => import('./components/pages/contracts/SharesList').then(({ SharesList }) => ({ default: SharesList }))) // prettier-ignore
const UpsertExport = lazy(() => import('./components/pages/contracts/UpsertExport').then(({ UpsertExport }) => ({ default: UpsertExport }))) // prettier-ignore
const UpsertBilling = lazy(() => import('./components/pages/contracts/UpsertBilling').then(({ UpsertBilling }) => ({ default: UpsertBilling }))) // prettier-ignore
const UpsertPark = lazy(() => import('./components/pages/contracts/UpsertPark').then(({ UpsertPark }) => ({ default: UpsertPark }))) // prettier-ignore
const UpsertPisaInput = lazy(() => import('./components/pages/contracts/UpsertPisaInput').then(({ UpsertPisaInput }) => ({ default: UpsertPisaInput }))) // prettier-ignore
const UpsertPricingInput = lazy(() =>import('./components/pages/contracts/UpsertPricingInput').then(({ UpsertPricingInput }) => ({ default: UpsertPricingInput }))) // prettier-ignore
const UpsertReportDraft = lazy(() =>import('./components/pages/exports/UpsertReportDraft').then(({ UpsertReportDraft }) => ({ default: UpsertReportDraft }))) // prettier-ignore

// Admin lists
const ListsData = lazy(() =>import('./components/pages/listsData/ListsDataPage').then(({ ListsDataPage }) => ({ default: ListsDataPage }))) // prettier-ignore
const BalanceAreaList = lazy(() =>import('./components/pages/listsData/BalanceAreaList').then(({ BalanceAreaList }) => ({ default: BalanceAreaList }))) // prettier-ignore
const BalanceGroupList = lazy(() =>import('./components/pages/listsData/BalanceGroupList').then(({ BalanceGroupList }) => ({ default: BalanceGroupList }))) // prettier-ignore
const DSOList = lazy(() =>import('./components/pages/listsData/DSOList').then(({ DSOList }) => ({ default: DSOList }))) // prettier-ignore
const TSOList = lazy(() =>import('./components/pages/listsData/TSOList').then(({ TSOList }) => ({ default: TSOList }))) // prettier-ignore
const WLPartnerList = lazy(() =>import('./components/pages/listsData/WLPartnerList').then(({ WLPartnerList }) => ({ default: WLPartnerList }))) // prettier-ignore
const EIVList = lazy(() =>import('./components/pages/listsData/EIVList').then(({ EIVList }) => ({ default: EIVList }))) // prettier-ignore
const BTRList = lazy(() =>import('./components/pages/listsData/BTRList').then(({ BTRList }) => ({ default: BTRList }))) // prettier-ignore

export interface RouteInfo {
  component: ComponentType;
  exact: boolean;
  hideInNav: boolean;
  icon?: ComponentType;
  id: string;
  isPrivate: boolean;
  name: string; // page name declared in locales/${translation}.json
  needsAdmin: boolean;
  path: string;
  withLayout: boolean;
}

const ReviewSection = HomePage;
const ReviewTurbine = HomePage;

export const routes: RouteInfo[] = [
  // #region Basic navigation
  {
    component: WelcomePage,
    exact: true,
    hideInNav: true,
    id: 'welcome',
    name: 'Welcome',
    needsAdmin: false,
    isPrivate: false,
    path: '/',
    withLayout: false,
  },
  {
    component: LoginPage,
    exact: false,
    hideInNav: true,
    id: 'login',
    name: 'Login',
    needsAdmin: false,
    isPrivate: false,
    path: '/login',
    withLayout: false,
  },
  {
    component: HomePage,
    exact: true,
    hideInNav: false,
    id: 'home',
    icon: IconHouse,
    name: 'pages.home',
    needsAdmin: false,
    isPrivate: true,
    path: '/home',
    withLayout: true,
  },
  {
    component: ErrorPage,
    exact: true,
    hideInNav: true,
    id: 'error401',
    name: 'pages.error401',
    needsAdmin: false,
    isPrivate: false,
    path: '/401',
    withLayout: false,
  },
  // #endregion
  // #region Customers
  {
    component: CustomersList,
    exact: true,
    hideInNav: false,
    id: 'customers',
    name: 'pages.customers',
    needsAdmin: false,
    isPrivate: true,
    path: '/customers',
    withLayout: true,
    icon: IconBehaviour,
  },
  {
    component: CustomersList,
    exact: true,
    hideInNav: true,
    id: 'customers',
    name: 'pages.customers',
    needsAdmin: false,
    isPrivate: true,
    path: '/customers/add-partner',
    withLayout: true,
  },
  {
    component: ReviewContract,
    exact: true,
    hideInNav: true,
    id: 'contracts-review',
    name: 'pages.contracts-review',
    needsAdmin: false,
    isPrivate: true,
    path: '/customers/:customerId/contracts-review/:contractId',
    withLayout: true,
  },
  {
    component: ReviewBilling,
    exact: true,
    hideInNav: true,
    id: 'edit-billing',
    name: 'pages.edit-billing',
    needsAdmin: false,
    isPrivate: true,
    path: '/customers/:customerId/contracts-review/:contractId/billing',
    withLayout: true,
    icon: IconBilling,
  },
  {
    component: ReviewPark,
    exact: true,
    hideInNav: true,
    id: 'edit-park',
    name: 'pages.edit-park',
    needsAdmin: false,
    isPrivate: true,
    path: '/customers/:customerId/contracts-review/:contractId/park',
    withLayout: true,
    icon: IconSolarPanel,
  },
  {
    component: ReviewSharesList,
    exact: true,
    hideInNav: true,
    id: 'shares-list',
    name: 'pages.shares-list',
    needsAdmin: false,
    isPrivate: true,
    path: '/customers/:customerId/contracts-review/:contractId/shares',
    withLayout: true,
    icon: IconSolarPanel,
  },
  {
    component: ReviewSection,
    exact: true,
    hideInNav: true,
    id: 'add-section',
    name: 'pages.add-section',
    needsAdmin: false,
    isPrivate: true,
    path: '/customers/:customerId/contracts-review/:contractId/section/:shareId',
    withLayout: true,
  },
  {
    component: ReviewTurbine,
    exact: true,
    hideInNav: true,
    id: 'add-turbine',
    name: 'pages.add-turbine',
    needsAdmin: false,
    isPrivate: true,
    path: '/customers/:customerId/contracts-review/:contractId/turbine/:shareId',
    withLayout: true,
  },
  // #endregion
  // #region Contracts
  {
    component: ContractsList,
    exact: true,
    hideInNav: false,
    id: 'contracts-list',
    name: 'pages.contracts',
    needsAdmin: false,
    isPrivate: true,
    path: '/contracts',
    withLayout: true,
    icon: IconListView,
  },
  {
    component: AddContract,
    exact: true,
    hideInNav: true,
    id: 'add-contract',
    name: 'pages.add-contract',
    needsAdmin: false,
    isPrivate: true,
    path: '/contracts/add-contract',
    withLayout: true,
    icon: IconContractDetails,
  },
  {
    component: EditContract,
    exact: true,
    hideInNav: true,
    id: 'edit-contract',
    name: 'pages.edit-contract',
    needsAdmin: false,
    isPrivate: true,
    path: '/contracts/:contractId',
    withLayout: true,
    icon: IconContractDetails,
  },
  {
    component: UpsertBilling,
    exact: true,
    hideInNav: true,
    id: 'edit-billing',
    name: 'pages.edit-billing',
    needsAdmin: false,
    isPrivate: true,
    path: '/contracts/:contractId/billing',
    withLayout: true,
    icon: IconBilling,
  },
  {
    component: UpsertPark,
    exact: true,
    hideInNav: true,
    id: 'edit-park',
    name: 'pages.edit-park',
    needsAdmin: false,
    isPrivate: true,
    path: '/contracts/:contractId/park',
    withLayout: true,
    icon: IconSolarPanel,
  },
  {
    component: SharesList,
    exact: true,
    hideInNav: true,
    id: 'shares-list',
    name: 'pages.shares-list',
    needsAdmin: false,
    isPrivate: true,
    path: '/contracts/:contractId/shares',
    withLayout: true,
    icon: IconSolarPanel,
  },
  {
    component: AddSection,
    exact: true,
    hideInNav: true,
    id: 'add-section',
    name: 'pages.add-section',
    needsAdmin: false,
    isPrivate: true,
    path: '/contracts/:contractId/add-section',
    withLayout: true,
  },
  {
    component: EditSection,
    exact: true,
    hideInNav: true,
    id: 'edit-section',
    name: 'pages.edit-section',
    needsAdmin: false,
    isPrivate: true,
    path: '/contracts/:contractId/section/:shareId',
    withLayout: true,
  },
  {
    component: AddTurbine,
    exact: true,
    hideInNav: true,
    id: 'add-turbine',
    name: 'pages.add-turbine',
    needsAdmin: false,
    isPrivate: true,
    path: '/contracts/:contractId/add-turbine',
    withLayout: true,
  },
  {
    component: EditTurbine,
    exact: true,
    hideInNav: true,
    id: 'edit-turbine',
    name: 'pages.edit-turbine',
    needsAdmin: false,
    isPrivate: true,
    path: '/contracts/:contractId/turbine/:shareId',
    withLayout: true,
  },
  {
    component: UpsertPisaInput,
    exact: true,
    hideInNav: true,
    id: 'edit-pisa',
    name: 'pages.edit-pisa',
    needsAdmin: false,
    isPrivate: true,
    path: '/contracts/:contractId/pisa',
    withLayout: true,
  },
  {
    component: UpsertPricingInput,
    exact: true,
    hideInNav: true,
    id: 'edit-pricing',
    name: 'pages.edit-pricing',
    needsAdmin: false,
    isPrivate: true,
    path: '/contracts/:contractId/pricing',
    withLayout: true,
    icon: IconPricesRise,
  },
  // #endregion
  // #region Exports
  {
    component: ExportsList,
    exact: true,
    hideInNav: false,
    id: 'exports',
    name: 'pages.exports',
    needsAdmin: false,
    isPrivate: true,
    path: '/exports',
    withLayout: true,
    icon: IconSummary,
  },
  {
    component: UpsertExport,
    exact: true,
    hideInNav: true,
    id: 'exports',
    name: 'pages.exports',
    needsAdmin: false,
    isPrivate: true,
    path: '/exports/add-export',
    withLayout: true,
  },
  {
    component: UpsertReportDraft,
    exact: true,
    hideInNav: true,
    id: 'exports',
    name: 'pages.exports',
    needsAdmin: false,
    isPrivate: true,
    path: '/exports/:exportId/prepare-report/:contractId',
    withLayout: true,
  },
  // #endregion
  // #region Users management
  {
    component: UsersPage,
    exact: true,
    hideInNav: false,
    id: 'users',
    name: 'pages.users',
    needsAdmin: true,
    isPrivate: true,
    path: '/users',
    withLayout: true,
    icon: IconUser,
  },
  // #endregion
  // #region Data Lists
  {
    component: ListsData,
    exact: true,
    hideInNav: false,
    id: 'lists',
    icon: IconLayers,
    name: 'pages.lists',
    needsAdmin: true,
    isPrivate: true,
    path: '/lists',
    withLayout: true,
  },
  {
    component: BalanceAreaList,
    exact: true,
    hideInNav: true,
    id: 'balance-area-list',
    name: 'pages.lists.area',
    needsAdmin: true,
    isPrivate: true,
    path: '/lists/balance-areas',
    withLayout: true,
  },
  {
    component: BalanceGroupList,
    exact: true,
    hideInNav: true,
    id: 'balance-group-list',
    name: 'pages.lists.group',
    needsAdmin: true,
    isPrivate: true,
    path: '/lists/balance-groups',
    withLayout: true,
  },
  {
    component: DSOList,
    exact: true,
    hideInNav: true,
    id: 'dso-list',
    name: 'pages.lists.dso',
    needsAdmin: true,
    isPrivate: true,
    path: '/lists/dsos',
    withLayout: true,
  },
  {
    component: TSOList,
    exact: true,
    hideInNav: true,
    id: 'tso-list',
    name: 'pages.lists.tso',
    needsAdmin: true,
    isPrivate: true,
    path: '/lists/tsos',
    withLayout: true,
  },
  {
    component: WLPartnerList,
    exact: true,
    hideInNav: true,
    id: 'wl-partner-list',
    name: 'pages.lists.wpPartner',
    needsAdmin: true,
    isPrivate: true,
    path: '/lists/wl-partners',
    withLayout: true,
  },
  {
    component: EIVList,
    exact: true,
    hideInNav: true,
    id: 'eiv-list',
    name: 'pages.lists',
    needsAdmin: true,
    isPrivate: true,
    path: '/lists/eivs',
    withLayout: true,
  },
  {
    component: BTRList,
    exact: true,
    hideInNav: true,
    id: 'btr-list',
    name: 'pages.lists',
    needsAdmin: true,
    isPrivate: true,
    path: '/lists/btrs',
    withLayout: true,
  },
  // #endregion
];
