"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OptionalContract = exports.Contract = exports.ContractDTO = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
var billing_1 = require("./billing");
var company_1 = require("./company");
var export_1 = require("./export");
var park_1 = require("./park");
var pisaInput_1 = require("./pisaInput");
var pricingInput_1 = require("./pricingInput");
var report_1 = require("./report");
var reportDraft_1 = require("./reportDraft");
var share_1 = require("./share");
exports.ContractDTO = zod_1.z.object({
    id: util_1.uuid.optional(),
    name: zod_1.z.string().nonempty('commonErrors.empty'),
    contractualPartner: company_1.CompanyDTO,
    commercialManagement: company_1.CompanyDTO,
    invoice: company_1.CompanyDTO,
    technicalManagement: company_1.CompanyDTO,
    billing: billing_1.Billing.optional().nullable(),
    park: park_1.Park.optional().nullable(),
    shares: zod_1.z.array(share_1.ShareDTO).optional().nullable(),
    pisaInput: pisaInput_1.PisaInputDTO.optional().nullable(),
    pricingInput: pricingInput_1.PricingInputDTO.optional().nullable(),
    report: report_1.ReportDTO.optional().nullable(),
    reportDraft: reportDraft_1.ReportDraftDTO.optional().nullable(),
    export: export_1.ExportDTO.optional().nullable(),
});
exports.Contract = zod_1.z.object({
    id: util_1.uuid.optional(),
    name: zod_1.z.string().nonempty('commonErrors.empty'),
    contractualPartner: company_1.Company,
    commercialManagement: company_1.Company,
    invoice: company_1.Company,
    technicalManagement: company_1.Company,
    billing: billing_1.Billing.optional().nullable(),
    park: park_1.Park.optional().nullable(),
    shares: zod_1.z.array(share_1.ShareDTO).optional().nullable(),
    pisaInput: pisaInput_1.PisaInputDTO.optional().nullable(),
    pricingInput: pricingInput_1.PricingInputDTO.optional().nullable(),
    report: report_1.ReportDTO.optional().nullable(),
    reportDraft: reportDraft_1.ReportDraftDTO.optional().nullable(),
    export: export_1.ExportDTO.optional().nullable(),
});
exports.OptionalContract = exports.Contract.deepPartial();
