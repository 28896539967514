import './index.css';
import './i18n';

import { MsalProvider } from '@azure/msal-react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { globalStyles, ThemeProvider } from '@rcl/rcl';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReactReduxProvider } from 'react-redux';
import { Router as RouterRRD } from 'react-router-dom';

import { auth } from './auth';
import { ErrorBoundary } from './components/wrappers/ErrorBoundary';
import { TabsProvider } from './context';
import { history, reactPlugin } from './insights';
import reportWebVitals from './reportWebVitals';
import { Router } from './Router';
import { store } from './store';

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary>
      <ThemeProvider css={[globalStyles, { '#root': { fill: true } }]}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <ReactReduxProvider store={store}>
            <RouterRRD history={history}>
              <TabsProvider>
                {auth ? (
                  <MsalProvider instance={auth.getMsalInstance()}>
                    <Router />
                  </MsalProvider>
                ) : (
                  <p>No auth instance</p>
                )}
              </TabsProvider>
            </RouterRRD>
          </ReactReduxProvider>
        </AppInsightsContext.Provider>
      </ThemeProvider>
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
