import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { CSS } from '@stitches/react';
import classNames from 'classnames';
import _ from 'lodash';
import { forwardRef } from 'react';

import { css as stitchesCss, keyframes } from '../stitches.config';
import { theme } from '../theme';
import { Spinner } from '.';
import { Box, BoxProps } from './Box';
import { IconDown as IconChevron } from './icons';
import { Overlay } from './Overlay';

type AccordionRootProps = {
  fontSize?: 'sm' | 'md' | 'lg' | 'xl';
  isLoading?: boolean;
  leftChevron?: boolean;
  focus?: 'border' | 'padding';
} & BoxProps &
  AccordionPrimitive.AccordionSingleProps;

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'calc(var(--radix-accordion-content-height) - 1px)' },
});

const slideUp = keyframes({
  from: { height: 'calc(var(--radix-accordion-content-height) - 1px)' },
  to: { height: 0 },
});

const AccordionOverlayCover = ({ isLoading }: { isLoading?: boolean }) => {
  if (isLoading) {
    return <Spinner />;
  } else {
    return null;
  }
};

export const AccordionContentText = ({ children, ...props }: BoxProps) => (
  <Box className='rc-accordion-item-content-text' {...props}>
    {children}
  </Box>
);

export const AccordionContent = ({ children, ...props }: AccordionPrimitive.AccordionContentProps) => (
  <AccordionPrimitive.Content className='rc-accordion-item-content' {...props}>
    {children}
  </AccordionPrimitive.Content>
);

export const AccordionTrigger = ({ children, ...props }: AccordionPrimitive.AccordionTriggerProps) => (
  <AccordionPrimitive.Trigger className='rc-accordion-item-trigger' {...props}>
    {children}
    <IconChevron aria-hidden className='rc-accordion-item-trigger-chevron' />
  </AccordionPrimitive.Trigger>
);

export const AccordionHeader = ({ children, ...props }: AccordionPrimitive.AccordionHeaderProps) => (
  <AccordionPrimitive.Header className='rc-accordion-item-header' {...props}>
    {children}
  </AccordionPrimitive.Header>
);

export const AccordionItem = ({ children, ...props }: AccordionPrimitive.AccordionItemProps) => (
  <AccordionPrimitive.Item className='rc-accordion-item' {...props}>
    {children}
  </AccordionPrimitive.Item>
);

export const AccordionRoot = forwardRef<HTMLElement, AccordionRootProps>(function Accordion(
  {
    leftChevron = false,
    fontSize = 'md',
    className,
    css,
    type = 'single',
    children,
    isLoading,
    focus = 'border',
    ...props
  },
  ref,
) {
  return (
    <Overlay
      cover={<AccordionOverlayCover isLoading={isLoading} />}
      on={isLoading || false}
      css={isLoading ? { minHeight: '125px', height: 'inherit' } : { overflow: 'unset' }}
    >
      <Box
        ref={ref}
        css={css}
        className={classNames(
          stitchesCss({
            width: '100%',
            '.rc-accordion': {
              width: '100%',
            },
            '.rc-accordion-item': {
              marginTop: 1,
              paddingTop: 0,
              borderBottom: '1px solid $itemBorder',
              transition: 'padding-top 600ms cubic-bezier(0.87, 0, 0.13, 1)',
              '&:first-child': {
                marginTop: 0,
                borderTop: '1px solid $itemBorder',
              },
              '&:focus-within': {
                position: 'relative',
                zIndex: 1,
              },
            },
            '.rc-accordion-item-header': {
              all: 'unset',
              display: 'flex',
              '& > svg': {
                width: `calc(${theme.fontSizes[fontSize]} * 1.5)`,
                height: `calc(${theme.fontSizes[fontSize]} * 1.5)`,
                alignSelf: 'center',
                paddingRight: 20,
              },
            },
            '.rc-accordion-item-trigger': {
              all: 'unset',
              width: '100%',
              fontFamily: 'inherit',
              backgroundColor: 'transparent',
              padding: '0 20px',
              height: `calc(${theme.fontSizes[fontSize]} + 40px)`,
              flex: 1,
              display: 'grid',
              gridGap: '10px',
              alignItems: 'center',
              fontSize: 'inherit',
              lineHeight: 1,
              cursor: 'pointer',
              '&[data-state="closed"], &[data-state="open"]': { backgroundColor: '$primaryAuraWhite' },
              '&-chevron': {
                width: theme.fontSizes[fontSize],
                height: theme.fontSizes[fontSize],
                gridArea: 'chevron',
                transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
              },
            },
            '.rc-accordion-item-content': {
              overflow: 'hidden',
              padding: '0 20px',
              fontSize: 'inherit',
              '&[data-state="open"]': {
                animation: `${slideDown} .2s cubic-bezier(0.87, 0, 0.13, 1) forwards`,
              },
              '&[data-state="closed"]': {
                animation: `${slideUp} .2s cubic-bezier(0.87, 0, 0.13, 1) forwards`,
              },
              '&-text': {
                paddingBottom: '30px',
              },
              '& > div': {
                minHeight: 'unset', // Patch for (Table)Overlay element passed as a child of AccordionContent
              },
            },
            variants: {
              fontSize: _.zipObject(
                ['sm', 'md', 'lg', 'xl'],
                _.map<'sm' | 'md' | 'lg' | 'xl', CSS>(['sm', 'md', 'lg', 'xl'], (fontSize) => ({
                  fontSize: `$${fontSize}`,
                })),
              ),
              focus: {
                // ACCESSIBILITY: outline for focused items
                border: {
                  '.rc-accordion-item:focus-within': {
                    boxShadow: '0 0 0 1px $colors$tertiaryMediumDarkGray',
                  },
                },
                padding: {
                  '.rc-accordion-item[data-state="open"], .rc-accordion-item:focus-within': {
                    paddingTop: 16,
                    paddingBottom: 20,
                  },
                },
              },
              leftChevron: {
                true: {
                  '.rc-accordion-item-trigger': {
                    gridTemplateAreas: '"chevron text"',
                    gridTemplateColumns: 'auto 1fr',
                    '&[data-state="open"] .rc-accordion-item-trigger-chevron': { transform: 'rotate(-180deg)' },
                  },
                  '.rc-accordion-item-content': {
                    padding:
                      fontSize === 'sm'
                        ? `0 20px 0 calc(${theme.fontSizes[fontSize]} + 30px)`
                        : fontSize === 'md'
                        ? `0 20px 0 calc(${theme.fontSizes[fontSize]} + 30px)`
                        : fontSize === 'lg'
                        ? `0 20px 0 calc(${theme.fontSizes[fontSize]} + 30px)`
                        : `0 20px 0 calc(${theme.fontSizes[fontSize]} + 30px)`,
                  },
                },
                false: {
                  '.rc-accordion-item-trigger': {
                    gridTemplateAreas: '"text chevron"',
                    gridTemplateColumns: '1fr auto',
                    '&[data-state="open"] .rc-accordion-item-trigger-chevron': { transform: 'rotate(180deg)' },
                  },
                },
              },
            },
          })({ fontSize, focus, leftChevron }),
          className,
        )}
      >
        <AccordionPrimitive.Root {...props} className='.rc-accordion' type={type} collapsible>
          {children}
        </AccordionPrimitive.Root>
      </Box>
    </Overlay>
  );
});
