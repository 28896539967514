import { Angle, Color, Distance, LineStyle } from './types';

export const backgroundStriped = (args?: { angle?: Angle; color?: Color; space?: Distance; width?: Distance }) => {
  const angle = args?.angle ?? '135deg';
  const color = args?.color ?? '$border';
  const space = args?.space ?? '10px';
  const width = args?.width ?? '1px';

  // https://css-tricks.com/stripes-css
  return `repeating-linear-gradient(${angle}, transparent, transparent ${space}, ${color} ${space}, ${color} calc(${width} + ${space}))`;
};

export const border = (args?: { color?: Color; style?: LineStyle; width?: Distance }) => {
  const color = args?.color ?? '$border';
  const style = args?.style ?? 'solid';
  const width = args?.width ?? '1px';

  return `${width} ${style} ${color}`;
};

export const calculateFormElementsPaddingHorizontal = (fontSize: string, tight: boolean) => {
  return tight ? `calc(${fontSize} / 2)` : `calc(${fontSize})`;
};

export const calculateFormElementsPaddingVertical = (fontSize: string, tight: boolean) => {
  return tight ? `calc(${fontSize} / 1.25 / 2)` : `calc(${fontSize} / 1.25)`;
};

export const calculateFormElementsLineHeight = (fontSize: string) => {
  return `calc(${fontSize} + 4px)`;
};

export const calculateFormElementsHeight = (args: {
  fontSize: string;
  tight?: boolean;
  rows?: number;
  inputBorderSize?: string;
}) => {
  return args?.tight
    ? `calc( ${calculateFormElementsLineHeight(args.fontSize)} * ${
        args?.rows || 1
      } + 2 * ${calculateFormElementsPaddingVertical(args.fontSize, true)} + 2 * ${args.inputBorderSize || '1px'} )`
    : `calc( ${calculateFormElementsLineHeight(args.fontSize)} * ${
        args?.rows || 1
      } + 2 * ${calculateFormElementsPaddingVertical(args.fontSize, false)} + 2 * ${args.inputBorderSize || '1px'})`;
};

export const calculateFormElementsPadding = (args: { fontSize: string; tight?: boolean }) => {
  return args?.tight
    ? `${calculateFormElementsPaddingVertical(args.fontSize, true)} ${calculateFormElementsPaddingHorizontal(
        args.fontSize,
        true,
      )}`
    : `${calculateFormElementsPaddingVertical(args.fontSize, false)} ${calculateFormElementsPaddingHorizontal(
        args.fontSize,
        false,
      )}`;
};

// TODO: replace with CSS some day
// https://developer.mozilla.org/en-US/docs/Web/CSS/color_value/color-mix()
// export const colorMix = (args: {}) => {

//   return ''
// }
