import classNames from 'classnames';
import { FC, forwardRef, ReactElement, RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { css, DefaultProps, keyframes, PropertyValue, theme } from '../stitches.config';
import { mergeRefs } from '../util';
import { Box } from './Box';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useOutsideClick(ref: RefObject<any>, callback: () => void) {
  const handleClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
}

const opacityHide = keyframes({
  from: {
    opacity: '1',
  },
  to: {
    opacity: '0',
  },
});

const opacityShow = keyframes({
  from: {
    opacity: '0',
  },
  to: {
    opacity: '1',
  },
});

export type PopoverProps = {
  className?: string;
  closeDropdown: () => void;
  component: ReactElement;
  fontSize?: 'sm' | 'md' | 'lg' | 'xl';
  id?: string;
  isOpen?: boolean;
  anchorPosition?: 'left' | 'right';
  margin?: PropertyValue<'margin'>;
  onClick?: () => void;
} & DefaultProps;

export const Popover = forwardRef<HTMLElement, PopoverProps>(function Popover(
  {
    as = 'div',
    children,
    closeDropdown,
    component,
    fontSize = 'md',
    isOpen,
    anchorPosition = 'left',
    margin = '0',
    onClick,
    ...props
  },
  ref,
) {
  const localRef = useRef(null);

  useOutsideClick(localRef, () => {
    closeDropdown();
  });

  return (
    <Box as={as} ref={mergeRefs([ref, localRef])} css={{ position: 'relative' }} onClick={onClick} {...props}>
      {component}
      <Box
        className={classNames(
          css({
            margin,
            display: 'none',
            position: 'absolute',
            top: '120%',
            minWidth: '250px',
            transition: 'visibility 0s linear 0.2s, opacity 0.2s',
            border: '1px solid $light-grey',
            borderRadius: '6px',
            background: '$primaryAuraWhite',
            opacity: 0,
            zIndex: '100',
            filter: 'drop-shadow(0 2px 10px rgba(0, 0, 0, 0.2))',
            animation: `${opacityHide} 0.2s`,
            boxSizing: 'border-box',
            fontSize: theme.fontSizes[fontSize],

            variants: {
              anchorPosition: {
                right: {
                  right: '0',
                  '&::after, &::before': {
                    left: '90% !important',
                  },
                },
                left: {
                  left: '0',
                  '&::after, &::before': {
                    right: '90% !important',
                  },
                },
              },
              isOpen: {
                true: {
                  display: 'block',
                  opacity: '1',
                  animation: `${opacityShow} 0.2s`,
                },
              },
            },
          })({ anchorPosition, isOpen }),
        )}
      >
        {children}
      </Box>
    </Box>
  );
});

export type VfAutoPopoverProps = Omit<PopoverProps, 'closeDropdown' | 'onClick'>;

export const AutoPopover: FC<VfAutoPopoverProps> = ({ isOpen: initialIsOpen = false, ...props }) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const onClick = useCallback(() => setIsOpen((currentIsOpen) => !currentIsOpen), [setIsOpen]);
  const closeDropdown = useCallback(() => setIsOpen(false), [setIsOpen]);

  return <Popover closeDropdown={closeDropdown} isOpen={isOpen} onClick={onClick} {...props} />;
};

export interface PopoverMenuItem<T> {
  id: T;
  label: string;
}

export const VfPopoverMenu = <T extends string | number>({
  items,
  onSelect,
  ...props
}: {
  items: PopoverMenuItem<T>[];
  onSelect?: (id: T) => void;
} & DefaultProps) => (
  <Box
    css={{
      display: 'flex',
      flexDirection: 'column',
    }}
    {...props}
  >
    {items.map((item) => (
      <Box
        key={item.id}
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          minHeight: '3em',
          borderBottom: '1px solid $lightBorderGrey',

          '&:hover': {
            backgroundColor: '$dropdownInputDisabled',
          },

          '&:last-child': {
            borderBottom: 'none',
          },
        }}
        onClick={() => onSelect?.(item.id)}
      >
        {item.label}
      </Box>
    ))}
  </Box>
);
