// @vf-alchemy/vattenfall-design-system/scss/mixins/_breakpoints.scss
// https://getbootstrap.com/docs/5.0/layout/breakpoints/#available-breakpoints

import { ValuesType } from 'utility-types';

export const breakpointsNames = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as const;
export type Breakpoint = ValuesType<typeof breakpointsNames>;

export const breakpoints = new Map<Breakpoint, number>([
  ['xs', 0],
  ['sm', 576],
  ['md', 768],
  ['lg', 992],
  ['xl', 1200],
  ['xxl', 1400],
]);
