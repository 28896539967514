/* eslint-disable import/newline-after-import */
/* eslint-disable import/first */

import { styled } from '../stitches.config';

const defaultCss = { fill: '$text', size: '24px' };

import { default as Icon24h_ } from '../assets/icons/VF_24h_rgb.svg';
export const Icon24h = styled(Icon24h_, defaultCss);
import { default as IconActivityLog_ } from '../assets/icons/VF_activity_log_rgb.svg';
export const IconActivityLog = styled(IconActivityLog_, defaultCss);
import { default as IconAddCard_ } from '../assets/icons/VF_add_card_rgb.svg';
export const IconAddCard = styled(IconAddCard_, defaultCss);
import { default as IconAddContact_ } from '../assets/icons/VF_add_contact_rgb.svg';
export const IconAddContact = styled(IconAddContact_, defaultCss);
import { default as IconAdventCalendar_ } from '../assets/icons/VF_advent_calendar_rgb.svg';
export const IconAdventCalendar = styled(IconAdventCalendar_, defaultCss);
import { default as IconAgriculture_ } from '../assets/icons/VF_agriculture_rgb.svg';
export const IconAgriculture = styled(IconAgriculture_, defaultCss);
import { default as IconAidedAwareness_ } from '../assets/icons/VF_aided_awareness_rgb.svg';
export const IconAidedAwareness = styled(IconAidedAwareness_, defaultCss);
import { default as IconAirCondition_ } from '../assets/icons/VF_air_condition_rgb.svg';
export const IconAirCondition = styled(IconAirCondition_, defaultCss);
import { default as IconAirplane1_ } from '../assets/icons/VF_airplane_rgb-1.svg';
export const IconAirplane1 = styled(IconAirplane1_, defaultCss);
import { default as IconAirplane_ } from '../assets/icons/VF_airplane_rgb.svg';
export const IconAirplane = styled(IconAirplane_, defaultCss);
import { default as IconAirQuality_ } from '../assets/icons/VF_air_quality_rgb.svg';
export const IconAirQuality = styled(IconAirQuality_, defaultCss);
import { default as IconAlarm_ } from '../assets/icons/VF_alarm_rgb.svg';
export const IconAlarm = styled(IconAlarm_, defaultCss);
import { default as IconAlertCmyk_ } from '../assets/icons/VF_alert_cmyk_rgb.svg';
export const IconAlertCmyk = styled(IconAlertCmyk_, defaultCss);
import { default as IconAlignCenter_ } from '../assets/icons/VF_align_center_rgb.svg';
export const IconAlignCenter = styled(IconAlignCenter_, defaultCss);
import { default as IconAlignLeft_ } from '../assets/icons/VF_align_left_rgb.svg';
export const IconAlignLeft = styled(IconAlignLeft_, defaultCss);
import { default as IconAlignRight_ } from '../assets/icons/VF_align_right_rgb.svg';
export const IconAlignRight = styled(IconAlignRight_, defaultCss);
import { default as IconAllGender_ } from '../assets/icons/VF_all gender_rgb.svg';
export const IconAllGender = styled(IconAllGender_, defaultCss);
import { default as IconApartmentHouse_ } from '../assets/icons/VF_apartment_house_rgb.svg';
export const IconApartmentHouse = styled(IconApartmentHouse_, defaultCss);
import { default as IconApple_ } from '../assets/icons/VF_apple_rgb.svg';
export const IconApple = styled(IconApple_, defaultCss);
import { default as IconAppSettings_ } from '../assets/icons/VF_app_settings_rgb.svg';
export const IconAppSettings = styled(IconAppSettings_, defaultCss);
import { default as IconArea02_ } from '../assets/icons/VF_area_02_rgb.svg';
export const IconArea02 = styled(IconArea02_, defaultCss);
import { default as IconArea03_ } from '../assets/icons/VF_area_03_rgb.svg';
export const IconArea03 = styled(IconArea03_, defaultCss);
import { default as IconArea01_ } from '../assets/icons/VF_area_rgb_area.svg';
export const IconArea01 = styled(IconArea01_, defaultCss);
import { default as IconArrowCorner_ } from '../assets/icons/VF_arrow_corner_rgb.svg';
export const IconArrowCorner = styled(IconArrowCorner_, defaultCss);
import { default as IconArrowCornerTwo_ } from '../assets/icons/VF_arrow_corner_two_rgb.svg';
export const IconArrowCornerTwo = styled(IconArrowCornerTwo_, defaultCss);
import { default as IconArrowDownLeft_ } from '../assets/icons/VF_arrow_down_left_rgb.svg';
export const IconArrowDownLeft = styled(IconArrowDownLeft_, defaultCss);
import { default as IconArrowDown_ } from '../assets/icons/VF_arrow_down_rgb.svg';
export const IconArrowDown = styled(IconArrowDown_, defaultCss);
import { default as IconArrowDownRight_ } from '../assets/icons/VF_arrow_down_right_rgb.svg';
export const IconArrowDownRight = styled(IconArrowDownRight_, defaultCss);
import { default as IconArrowLeft_ } from '../assets/icons/VF_arrow_left_rgb.svg';
export const IconArrowLeft = styled(IconArrowLeft_, defaultCss);
import { default as IconArrowRight_ } from '../assets/icons/VF_arrow_right_rgb.svg';
export const IconArrowRight = styled(IconArrowRight_, defaultCss);
import { default as IconArrowUpLeft_ } from '../assets/icons/VF_arrow_up_left_rgb.svg';
export const IconArrowUpLeft = styled(IconArrowUpLeft_, defaultCss);
import { default as IconArrowUp_ } from '../assets/icons/VF_arrow_up_rgb.svg';
export const IconArrowUp = styled(IconArrowUp_, defaultCss);
import { default as IconArrowUpRight_ } from '../assets/icons/VF_arrow_up_right_rgb.svg';
export const IconArrowUpRight = styled(IconArrowUpRight_, defaultCss);
import { default as IconAssortment_ } from '../assets/icons/VF_assortment_rgb.svg';
export const IconAssortment = styled(IconAssortment_, defaultCss);
import { default as IconAutostart_ } from '../assets/icons/VF_autostart_rgb.svg';
export const IconAutostart = styled(IconAutostart_, defaultCss);
import { default as IconBabyChangingRoom_ } from '../assets/icons/VF_baby_changing_room_rgb.svg';
export const IconBabyChangingRoom = styled(IconBabyChangingRoom_, defaultCss);
import { default as IconBalance_ } from '../assets/icons/VF_balance_rgb.svg';
export const IconBalance = styled(IconBalance_, defaultCss);
import { default as IconBalloon_ } from '../assets/icons/VF_balloon_rgb.svg';
export const IconBalloon = styled(IconBalloon_, defaultCss);
import { default as IconBaseUtility_ } from '../assets/icons/VF_base_utility_rgb.svg';
export const IconBaseUtility = styled(IconBaseUtility_, defaultCss);
import { default as IconBath_ } from '../assets/icons/VF_bath_rgb.svg';
export const IconBath = styled(IconBath_, defaultCss);
import { default as IconBattery_ } from '../assets/icons/VF_battery_rgb.svg';
export const IconBattery = styled(IconBattery_, defaultCss);
import { default as IconBeautyPersonalCare_ } from '../assets/icons/VF_beauty_personal_care_rgb.svg';
export const IconBeautyPersonalCare = styled(IconBeautyPersonalCare_, defaultCss);
import { default as IconBehaviour_ } from '../assets/icons/VF_behaviour_rgb.svg';
export const IconBehaviour = styled(IconBehaviour_, defaultCss);
import { default as IconBenchmarkCo2_ } from '../assets/icons/VF_benchmark_co2_rgb.svg';
export const IconBenchmarkCo2 = styled(IconBenchmarkCo2_, defaultCss);
import { default as IconBenchmarkCockpit_ } from '../assets/icons/VF_benchmark_cockpit_rgb.svg';
export const IconBenchmarkCockpit = styled(IconBenchmarkCockpit_, defaultCss);
import { default as IconBenchmark_ } from '../assets/icons/VF_benchmark_rgb.svg';
export const IconBenchmark = styled(IconBenchmark_, defaultCss);
import { default as IconBenchmarkTemperature_ } from '../assets/icons/VF_benchmark_temperature_rgb.svg';
export const IconBenchmarkTemperature = styled(IconBenchmarkTemperature_, defaultCss);
import { default as IconBenchmarkTwo_ } from '../assets/icons/VF_benchmark_two_rgb.svg';
export const IconBenchmarkTwo = styled(IconBenchmarkTwo_, defaultCss);
import { default as IconBenefit_ } from '../assets/icons/VF_benefit_rgb.svg';
export const IconBenefit = styled(IconBenefit_, defaultCss);
import { default as IconBike_ } from '../assets/icons/VF_bike_rgb.svg';
export const IconBike = styled(IconBike_, defaultCss);
import { default as IconBilling_ } from '../assets/icons/VF_billing_rgb.svg';
export const IconBilling = styled(IconBilling_, defaultCss);
import { default as IconBioDieselGenerator_ } from '../assets/icons/VF_bio_diesel_generator_rgb.svg';
export const IconBioDieselGenerator = styled(IconBioDieselGenerator_, defaultCss);
import { default as IconBiofuelBiomass_ } from '../assets/icons/VF_biofuel_biomass_rgb.svg';
export const IconBiofuelBiomass = styled(IconBiofuelBiomass_, defaultCss);
import { default as IconBlog_ } from '../assets/icons/VF_blog_rgb.svg';
export const IconBlog = styled(IconBlog_, defaultCss);
import { default as IconBookRoom_ } from '../assets/icons/VF_book_room_rgb.svg';
export const IconBookRoom = styled(IconBookRoom_, defaultCss);
import { default as IconBooksMagazines_ } from '../assets/icons/VF_books_magazines_rgb.svg';
export const IconBooksMagazines = styled(IconBooksMagazines_, defaultCss);
import { default as IconBottle_ } from '../assets/icons/VF_bottle_rgb.svg';
export const IconBottle = styled(IconBottle_, defaultCss);
import { default as IconBrain_ } from '../assets/icons/VF_brain_rgb.svg';
export const IconBrain = styled(IconBrain_, defaultCss);
import { default as IconBrainstorm_ } from '../assets/icons/VF_brainstorm_rgb.svg';
export const IconBrainstorm = styled(IconBrainstorm_, defaultCss);
import { default as IconBrandAwareness_ } from '../assets/icons/VF_brand_awareness_rgb.svg';
export const IconBrandAwareness = styled(IconBrandAwareness_, defaultCss);
import { default as IconBusCharging_ } from '../assets/icons/VF_bus_charging_rgb.svg';
export const IconBusCharging = styled(IconBusCharging_, defaultCss);
import { default as IconCalculator_ } from '../assets/icons/VF_calculator_rgb.svg';
export const IconCalculator = styled(IconCalculator_, defaultCss);
import { default as IconCalendar01_ } from '../assets/icons/VF_calendar_01_rgb.svg';
export const IconCalendar01 = styled(IconCalendar01_, defaultCss);
import { default as IconCalendar02_ } from '../assets/icons/VF_calendar_02_rgb.svg';
export const IconCalendar02 = styled(IconCalendar02_, defaultCss);
import { default as IconCalendar03_ } from '../assets/icons/VF_calendar_03_rgb.svg';
export const IconCalendar03 = styled(IconCalendar03_, defaultCss);
import { default as IconCalendar04_ } from '../assets/icons/VF_calendar_04_rgb.svg';
export const IconCalendar04 = styled(IconCalendar04_, defaultCss);
import { default as IconCalendar05_ } from '../assets/icons/VF_calendar_05_rgb.svg';
export const IconCalendar05 = styled(IconCalendar05_, defaultCss);
import { default as IconCalendar06_ } from '../assets/icons/VF_calendar_06_rgb.svg';
export const IconCalendar06 = styled(IconCalendar06_, defaultCss);
import { default as IconCalendar08_ } from '../assets/icons/VF_calendar_08_rgb.svg';
export const IconCalendar08 = styled(IconCalendar08_, defaultCss);
import { default as IconCalendar09_ } from '../assets/icons/VF_calendar_09_rgb.svg';
export const IconCalendar09 = styled(IconCalendar09_, defaultCss);
import { default as IconCalendar10_ } from '../assets/icons/VF_calendar_10_rgb.svg';
export const IconCalendar10 = styled(IconCalendar10_, defaultCss);
import { default as IconCalendar11_ } from '../assets/icons/VF_calendar_11_rgb.svg';
export const IconCalendar11 = styled(IconCalendar11_, defaultCss);
import { default as IconCalendar12_ } from '../assets/icons/VF_calendar_12_rgb.svg';
export const IconCalendar12 = styled(IconCalendar12_, defaultCss);
import { default as IconCalendar13_ } from '../assets/icons/VF_calendar_13_rgb.svg';
export const IconCalendar13 = styled(IconCalendar13_, defaultCss);
import { default as IconCalendar14_ } from '../assets/icons/VF_calendar_14_rgb.svg';
export const IconCalendar14 = styled(IconCalendar14_, defaultCss);
import { default as IconCalendar15_ } from '../assets/icons/VF_calendar_15_rgb.svg';
export const IconCalendar15 = styled(IconCalendar15_, defaultCss);
import { default as IconCalendar16_ } from '../assets/icons/VF_calendar_16_rgb.svg';
export const IconCalendar16 = styled(IconCalendar16_, defaultCss);
import { default as IconCalendar17_ } from '../assets/icons/VF_calendar_17_rgb.svg';
export const IconCalendar17 = styled(IconCalendar17_, defaultCss);
import { default as IconCalendar18_ } from '../assets/icons/VF_calendar_18_rgb.svg';
export const IconCalendar18 = styled(IconCalendar18_, defaultCss);
import { default as IconCalendar19_ } from '../assets/icons/VF_calendar_19_rgb.svg';
export const IconCalendar19 = styled(IconCalendar19_, defaultCss);
import { default as IconCalendar20_ } from '../assets/icons/VF_calendar_20_rgb.svg';
export const IconCalendar20 = styled(IconCalendar20_, defaultCss);
import { default as IconCalendar21_ } from '../assets/icons/VF_calendar_21_rgb.svg';
export const IconCalendar21 = styled(IconCalendar21_, defaultCss);
import { default as IconCalendar22_ } from '../assets/icons/VF_calendar_22_rgb.svg';
export const IconCalendar22 = styled(IconCalendar22_, defaultCss);
import { default as IconCalendar23_ } from '../assets/icons/VF_calendar_23_rgb.svg';
export const IconCalendar23 = styled(IconCalendar23_, defaultCss);
import { default as IconCalendar24_ } from '../assets/icons/VF_calendar_24_rgb.svg';
export const IconCalendar24 = styled(IconCalendar24_, defaultCss);
import { default as IconCalendar25_ } from '../assets/icons/VF_calendar_25_rgb.svg';
export const IconCalendar25 = styled(IconCalendar25_, defaultCss);
import { default as IconCalendar26_ } from '../assets/icons/VF_calendar_26_rgb.svg';
export const IconCalendar26 = styled(IconCalendar26_, defaultCss);
import { default as IconCalendar27_ } from '../assets/icons/VF_calendar_27_rgb.svg';
export const IconCalendar27 = styled(IconCalendar27_, defaultCss);
import { default as IconCalendar28_ } from '../assets/icons/VF_calendar_28_rgb.svg';
export const IconCalendar28 = styled(IconCalendar28_, defaultCss);
import { default as IconCalendar29_ } from '../assets/icons/VF_calendar_29_rgb.svg';
export const IconCalendar29 = styled(IconCalendar29_, defaultCss);
import { default as IconCalendar30_ } from '../assets/icons/VF_calendar_30_rgb.svg';
export const IconCalendar30 = styled(IconCalendar30_, defaultCss);
import { default as IconCalendarSeven_ } from '../assets/icons/VF_calendar_seven_rgb.svg';
export const IconCalendarSeven = styled(IconCalendarSeven_, defaultCss);
import { default as IconCalendarThirtyOne_ } from '../assets/icons/VF_calendar_thirty_one_rgb.svg';
export const IconCalendarThirtyOne = styled(IconCalendarThirtyOne_, defaultCss);
import { default as IconCallback_ } from '../assets/icons/VF_callback_rgb.svg';
export const IconCallback = styled(IconCallback_, defaultCss);
import { default as IconCallCenter_ } from '../assets/icons/VF_call_center_rgb.svg';
export const IconCallCenter = styled(IconCallCenter_, defaultCss);
import { default as IconCall_ } from '../assets/icons/VF_call_rgb.svg';
export const IconCall = styled(IconCall_, defaultCss);
import { default as IconCamera_ } from '../assets/icons/VF_camera_rgb.svg';
export const IconCamera = styled(IconCamera_, defaultCss);
import { default as IconCardAndTag_ } from '../assets/icons/VF_card_and_tag_rgb.svg';
export const IconCardAndTag = styled(IconCardAndTag_, defaultCss);
import { default as IconCareerAndDevelopment_ } from '../assets/icons/VF_career_and_development_rgb.svg';
export const IconCareerAndDevelopment = styled(IconCareerAndDevelopment_, defaultCss);
import { default as IconCar_ } from '../assets/icons/VF_car_rgb.svg';
export const IconCar = styled(IconCar_, defaultCss);
import { default as IconCashPoint_ } from '../assets/icons/VF_cash_point_rgb.svg';
export const IconCashPoint = styled(IconCashPoint_, defaultCss);
import { default as IconCementMixer_ } from '../assets/icons/VF_cement_mixer_rgb.svg';
export const IconCementMixer = styled(IconCementMixer_, defaultCss);
import { default as IconCentralHeating_ } from '../assets/icons/VF_central_heating_rgb.svg';
export const IconCentralHeating = styled(IconCentralHeating_, defaultCss);
import { default as IconCertificate_ } from '../assets/icons/VF_certificate_rgb.svg';
export const IconCertificate = styled(IconCertificate_, defaultCss);
import { default as IconChademoConnector_ } from '../assets/icons/VF_chademo_connector_rgb.svg';
export const IconChademoConnector = styled(IconChademoConnector_, defaultCss);
import { default as IconChangeAvailability_ } from '../assets/icons/VF_change_availability_rgb.svg';
export const IconChangeAvailability = styled(IconChangeAvailability_, defaultCss);
import { default as IconChargeCard_ } from '../assets/icons/VF_charge_card_rgb.svg';
export const IconChargeCard = styled(IconChargeCard_, defaultCss);
import { default as IconChargeSmartAndSafeAtHome_ } from '../assets/icons/VF_charge_smart_and_safe_at_home_rgb.svg';
export const IconChargeSmartAndSafeAtHome = styled(IconChargeSmartAndSafeAtHome_, defaultCss);
import { default as IconChargeUpMultihome_ } from '../assets/icons/VF_charge_up_multihome_rgb.svg';
export const IconChargeUpMultihome = styled(IconChargeUpMultihome_, defaultCss);
import { default as IconChargeUpYourBusiness_ } from '../assets/icons/VF_charge_up_your_business_rgb.svg';
export const IconChargeUpYourBusiness = styled(IconChargeUpYourBusiness_, defaultCss);
import { default as IconChargingWallBox_ } from '../assets/icons/VF_charging_wall_box_rgb.svg';
export const IconChargingWallBox = styled(IconChargingWallBox_, defaultCss);
import { default as IconChart_ } from '../assets/icons/VF_chart_rgb.svg';
export const IconChart = styled(IconChart_, defaultCss);
import { default as IconChat_ } from '../assets/icons/VF_chat_rgb.svg';
export const IconChat = styled(IconChat_, defaultCss);
import { default as IconCheckbox_ } from '../assets/icons/VF_checkbox_rgb.svg';
export const IconCheckbox = styled(IconCheckbox_, defaultCss);
import { default as IconCheck_ } from '../assets/icons/VF_check_rgb.svg';
export const IconCheck = styled(IconCheck_, defaultCss);
import { default as IconChemicalStructure_ } from '../assets/icons/VF_chemical_structure_rgb.svg';
export const IconChemicalStructure = styled(IconChemicalStructure_, defaultCss);
import { default as IconChemistry_ } from '../assets/icons/VF_chemistry_rgb.svg';
export const IconChemistry = styled(IconChemistry_, defaultCss);
import { default as IconChemistryTwo_ } from '../assets/icons/VF_chemistry_two_rgb.svg';
export const IconChemistryTwo = styled(IconChemistryTwo_, defaultCss);
import { default as IconCity1Svg_ } from '../assets/icons/VF_city_rgb-1.svg';
export const IconCity1Svg = styled(IconCity1Svg_, defaultCss);
import { default as IconCity_ } from '../assets/icons/VF_city_rgb.svg';
export const IconCity = styled(IconCity_, defaultCss);
import { default as IconClimateMix_ } from '../assets/icons/VF_climate_mix_rgb.svg';
export const IconClimateMix = styled(IconClimateMix_, defaultCss);
import { default as IconClimateNeutral_ } from '../assets/icons/VF_climate_neutral_rgb.svg';
export const IconClimateNeutral = styled(IconClimateNeutral_, defaultCss);
import { default as IconClimateQuarter_ } from '../assets/icons/VF_climate_quarter_rgb.svg';
export const IconClimateQuarter = styled(IconClimateQuarter_, defaultCss);
import { default as IconCloakroom_ } from '../assets/icons/VF_cloakroom_rgb.svg';
export const IconCloakroom = styled(IconCloakroom_, defaultCss);
import { default as IconClock_ } from '../assets/icons/VF_clock_rgb.svg';
export const IconClock = styled(IconClock_, defaultCss);
import { default as IconClose_ } from '../assets/icons/VF_close_rgb.svg';
export const IconClose = styled(IconClose_, defaultCss);
import { default as IconCloudQuarter_ } from '../assets/icons/VF_cloud_quarter_rgb.svg';
export const IconCloudQuarter = styled(IconCloudQuarter_, defaultCss);
import { default as IconCloudStorage_ } from '../assets/icons/VF_cloud_storage_rgb.svg';
export const IconCloudStorage = styled(IconCloudStorage_, defaultCss);
import { default as IconCloudy_ } from '../assets/icons/VF_cloudy_rgb.svg';
export const IconCloudy = styled(IconCloudy_, defaultCss);
import { default as IconCo2Footprint_ } from '../assets/icons/VF_CO2_footprint_rgb.svg';
export const IconCo2Footprint = styled(IconCo2Footprint_, defaultCss);
import { default as IconCo2Reduction_ } from '../assets/icons/VF_CO2_reduction_rgb.svg';
export const IconCo2Reduction = styled(IconCo2Reduction_, defaultCss);
import { default as IconCo2_ } from '../assets/icons/VF_co2_rgb.svg';
export const IconCo2 = styled(IconCo2_, defaultCss);
import { default as IconCoal_ } from '../assets/icons/VF_coal_rgb.svg';
export const IconCoal = styled(IconCoal_, defaultCss);
import { default as IconCockpit_ } from '../assets/icons/VF_cockpit_rgb.svg';
export const IconCockpit = styled(IconCockpit_, defaultCss);
import { default as IconCoffeeMaker_ } from '../assets/icons/VF_coffee maker_rgb.svg';
export const IconCoffeeMaker = styled(IconCoffeeMaker_, defaultCss);
import { default as IconCoffee_ } from '../assets/icons/VF_coffee_rgb.svg';
export const IconCoffee = styled(IconCoffee_, defaultCss);
import { default as IconCold_ } from '../assets/icons/VF_cold_rgb.svg';
export const IconCold = styled(IconCold_, defaultCss);
import { default as IconCombinedHeatAndPower_ } from '../assets/icons/VF_combined_heat_and_power_rgb.svg';
export const IconCombinedHeatAndPower = styled(IconCombinedHeatAndPower_, defaultCss);
import { default as IconConclusion_ } from '../assets/icons/VF_conclusion_rgb.svg';
export const IconConclusion = styled(IconConclusion_, defaultCss);
import { default as IconConfidentialWaste_ } from '../assets/icons/VF_confidential_waste_rgb.svg';
export const IconConfidentialWaste = styled(IconConfidentialWaste_, defaultCss);
import { default as IconConsideration_ } from '../assets/icons/VF_consideration_rgb.svg';
export const IconConsideration = styled(IconConsideration_, defaultCss);
import { default as IconConsole_ } from '../assets/icons/VF_console_rgb.svg';
export const IconConsole = styled(IconConsole_, defaultCss);
import { default as IconConsumption_ } from '../assets/icons/VF_consumption_rgb.svg';
export const IconConsumption = styled(IconConsumption_, defaultCss);
import { default as IconContractDetails_ } from '../assets/icons/VF_contract_details_rgb.svg';
export const IconContractDetails = styled(IconContractDetails_, defaultCss);
import { default as IconCooperationArea_ } from '../assets/icons/VF_cooperation area_rgb.svg';
export const IconCooperationArea = styled(IconCooperationArea_, defaultCss);
import { default as IconCopy_ } from '../assets/icons/VF_copy_rgb.svg';
export const IconCopy = styled(IconCopy_, defaultCss);
import { default as IconCopyStation_ } from '../assets/icons/VF_copy_station_rgb.svg';
export const IconCopyStation = styled(IconCopyStation_, defaultCss);
import { default as IconCostSaving_ } from '../assets/icons/VF_cost_saving_rgb.svg';
export const IconCostSaving = styled(IconCostSaving_, defaultCss);
import { default as IconCottage_ } from '../assets/icons/VF_cottage_rgb.svg';
export const IconCottage = styled(IconCottage_, defaultCss);
import { default as IconCreditInvoice_ } from '../assets/icons/VF_credit_invoice_rgb.svg';
export const IconCreditInvoice = styled(IconCreditInvoice_, defaultCss);
import { default as IconCross_ } from '../assets/icons/VF_cross_rgb.svg';
export const IconCross = styled(IconCross_, defaultCss);
import { default as IconCssConnector_ } from '../assets/icons/VF_CSS_connector_rgb.svg';
export const IconCssConnector = styled(IconCssConnector_, defaultCss);
import { default as IconCurrentLocation_ } from '../assets/icons/VF_current_location_rgb.svg';
export const IconCurrentLocation = styled(IconCurrentLocation_, defaultCss);
import { default as IconCyberSecurity_ } from '../assets/icons/VF_cyber_security_rgb.svg';
export const IconCyberSecurity = styled(IconCyberSecurity_, defaultCss);
import { default as IconDefence_ } from '../assets/icons/VF_defence_rgb.svg';
export const IconDefence = styled(IconDefence_, defaultCss);
import { default as IconDefenceSoldier_ } from '../assets/icons/VF_defence_soldier_rgb.svg';
export const IconDefenceSoldier = styled(IconDefenceSoldier_, defaultCss);
import { default as IconDelivery_ } from '../assets/icons/VF_delivery_rgb.svg';
export const IconDelivery = styled(IconDelivery_, defaultCss);
import { default as IconDesklamp_ } from '../assets/icons/VF_desklamp_rgb.svg';
export const IconDesklamp = styled(IconDesklamp_, defaultCss);
import { default as IconDesktopComputer_ } from '../assets/icons/VF_desktop_computer_rgb.svg';
export const IconDesktopComputer = styled(IconDesktopComputer_, defaultCss);
import { default as IconDining_ } from '../assets/icons/VF_dining_rgb.svg';
export const IconDining = styled(IconDining_, defaultCss);
import { default as IconDirections_ } from '../assets/icons/VF_directions_rgb.svg';
export const IconDirections = styled(IconDirections_, defaultCss);
import { default as IconDiscussion_ } from '../assets/icons/VF_discussion_rgb.svg';
export const IconDiscussion = styled(IconDiscussion_, defaultCss);
import { default as IconDiscussionTwo_ } from '../assets/icons/VF_discussion_two_rgb.svg';
export const IconDiscussionTwo = styled(IconDiscussionTwo_, defaultCss);
import { default as IconDishwasher_ } from '../assets/icons/VF_dishwasher_rgb.svg';
export const IconDishwasher = styled(IconDishwasher_, defaultCss);
import { default as IconDistribution_ } from '../assets/icons/VF_distribution_rgb.svg';
export const IconDistribution = styled(IconDistribution_, defaultCss);
import { default as IconDistrictCooling_ } from '../assets/icons/VF_district_cooling_rgb.svg';
export const IconDistrictCooling = styled(IconDistrictCooling_, defaultCss);
import { default as IconDistrictHeatAndPower_ } from '../assets/icons/VF_District heat and power_rgb.svg';
export const IconDistrictHeatAndPower = styled(IconDistrictHeatAndPower_, defaultCss);
import { default as IconDistrictHeatAsProduct_ } from '../assets/icons/VF_district_heat_as_product_rgb.svg';
export const IconDistrictHeatAsProduct = styled(IconDistrictHeatAsProduct_, defaultCss);
import { default as IconDistrictHeatingAlreadyConnected_ } from '../assets/icons/VF_district_heating_already_connected_rgb.svg';
export const IconDistrictHeatingAlreadyConnected = styled(IconDistrictHeatingAlreadyConnected_, defaultCss);
import { default as IconDistrictHeatingChanceRegion_ } from '../assets/icons/VF_district_heating_chance_region_rgb.svg';
export const IconDistrictHeatingChanceRegion = styled(IconDistrictHeatingChanceRegion_, defaultCss);
import { default as IconDistrictHeatingNotPossible_ } from '../assets/icons/VF_district_heating_not_possible_rgb.svg';
export const IconDistrictHeatingNotPossible = styled(IconDistrictHeatingNotPossible_, defaultCss);
import { default as IconDistrictHeating_ } from '../assets/icons/VF_district_heating_rgb.svg';
export const IconDistrictHeating = styled(IconDistrictHeating_, defaultCss);
import { default as IconDistrictHeatingTwo_ } from '../assets/icons/VF_district_heating_two_rgb.svg';
export const IconDistrictHeatingTwo = styled(IconDistrictHeatingTwo_, defaultCss);
import { default as IconDiversity_ } from '../assets/icons/VF_diversity_rgb.svg';
export const IconDiversity = styled(IconDiversity_, defaultCss);
import { default as IconDocumentation_ } from '../assets/icons/VF_documentation_rgb.svg';
export const IconDocumentation = styled(IconDocumentation_, defaultCss);
import { default as IconDogArea_ } from '../assets/icons/VF_dog_area_rgb.svg';
export const IconDogArea = styled(IconDogArea_, defaultCss);
import { default as IconDoingGood_ } from '../assets/icons/VF_doing_good_rgb.svg';
export const IconDoingGood = styled(IconDoingGood_, defaultCss);
import { default as IconDont_ } from '../assets/icons/VF_dont_rgb.svg';
export const IconDont = styled(IconDont_, defaultCss);
import { default as IconDoorClosed_ } from '../assets/icons/VF_door_closed_rgb.svg';
export const IconDoorClosed = styled(IconDoorClosed_, defaultCss);
import { default as IconDoorOpen_ } from '../assets/icons/VF_door_open_rgb.svg';
export const IconDoorOpen = styled(IconDoorOpen_, defaultCss);
import { default as IconDoubleElectricityMeter_ } from '../assets/icons/VF_double_electricity_meter_rgb.svg';
export const IconDoubleElectricityMeter = styled(IconDoubleElectricityMeter_, defaultCss);
import { default as IconDownloadPdf_ } from '../assets/icons/VF_download_pdf_rgb.svg';
export const IconDownloadPdf = styled(IconDownloadPdf_, defaultCss);
import { default as IconDownload_ } from '../assets/icons/VF_download_rgb.svg';
export const IconDownload = styled(IconDownload_, defaultCss);
import { default as IconDown_ } from '../assets/icons/VF_down_rgb.svg';
export const IconDown = styled(IconDown_, defaultCss);
import { default as IconDropbox_ } from '../assets/icons/VF_dropbox_rgb.svg';
export const IconDropbox = styled(IconDropbox_, defaultCss);
import { default as IconDryer_ } from '../assets/icons/VF_dryer_rgb.svg';
export const IconDryer = styled(IconDryer_, defaultCss);
import { default as IconDryingRoom_ } from '../assets/icons/VF_drying_room_rgb.svg';
export const IconDryingRoom = styled(IconDryingRoom_, defaultCss);
import { default as IconEBike_ } from '../assets/icons/VF_e-bike_rgb.svg';
export const IconEBike = styled(IconEBike_, defaultCss);
import { default as IconEconomize_ } from '../assets/icons/VF_economize_rgb.svg';
export const IconEconomize = styled(IconEconomize_, defaultCss);
import { default as IconEdit_ } from '../assets/icons/VF_edit_rgb.svg';
export const IconEdit = styled(IconEdit_, defaultCss);
import { default as IconEkoHourlySpot_ } from '../assets/icons/VF_eko_hourly_spot_rgb.svg';
export const IconEkoHourlySpot = styled(IconEkoHourlySpot_, defaultCss);
import { default as IconElectricCrane_ } from '../assets/icons/VF_electric_crane_rgb.svg';
export const IconElectricCrane = styled(IconElectricCrane_, defaultCss);
import { default as IconElectricExcavator_ } from '../assets/icons/VF_electric_excavator_rgb.svg';
export const IconElectricExcavator = styled(IconElectricExcavator_, defaultCss);
import { default as IconElectricExcavatorSmall_ } from '../assets/icons/VF_electric_excavator_small_rgb.svg';
export const IconElectricExcavatorSmall = styled(IconElectricExcavatorSmall_, defaultCss);
import { default as IconElectricityGas_ } from '../assets/icons/VF_electricity_gas_rgb.svg';
export const IconElectricityGas = styled(IconElectricityGas_, defaultCss);
import { default as IconElectricity_ } from '../assets/icons/VF_electricity_rgb.svg';
export const IconElectricity = styled(IconElectricity_, defaultCss);
import { default as IconElectricKettle_ } from '../assets/icons/VF_electric_kettle_rgb.svg';
export const IconElectricKettle = styled(IconElectricKettle_, defaultCss);
import { default as IconElectricLoader_ } from '../assets/icons/VF_electric_loader_rgb.svg';
export const IconElectricLoader = styled(IconElectricLoader_, defaultCss);
import { default as IconElectricStove_ } from '../assets/icons/VF_electric_stove_rgb.svg';
export const IconElectricStove = styled(IconElectricStove_, defaultCss);
import { default as IconElectricTipper_ } from '../assets/icons/VF_electric_tipper_rgb.svg';
export const IconElectricTipper = styled(IconElectricTipper_, defaultCss);
import { default as IconElectricToothbrush_ } from '../assets/icons/VF_electric_toothbrush_rgb.svg';
export const IconElectricToothbrush = styled(IconElectricToothbrush_, defaultCss);
import { default as IconElectricTractor_ } from '../assets/icons/VF_electric_tractor_rgb.svg';
export const IconElectricTractor = styled(IconElectricTractor_, defaultCss);
import { default as IconElectricTruck_ } from '../assets/icons/VF_electric_truck_rgb.svg';
export const IconElectricTruck = styled(IconElectricTruck_, defaultCss);
import { default as IconElectrolyser_ } from '../assets/icons/VF_electrolyser_rgb.svg';
export const IconElectrolyser = styled(IconElectrolyser_, defaultCss);
import { default as IconElectronicPaymentEuro_ } from '../assets/icons/VF_electronic_payment_euro_rgb.svg';
export const IconElectronicPaymentEuro = styled(IconElectronicPaymentEuro_, defaultCss);
import { default as IconElevator_ } from '../assets/icons/VF_elevator_rgb.svg';
export const IconElevator = styled(IconElevator_, defaultCss);
import { default as IconElipsis_ } from '../assets/icons/VF_elipsis_rgb.svg';
export const IconElipsis = styled(IconElipsis_, defaultCss);
import { default as IconEnergyMix_ } from '../assets/icons/VF_energy_mix_rgb.svg';
export const IconEnergyMix = styled(IconEnergyMix_, defaultCss);
import { default as IconEnergyServices_ } from '../assets/icons/VF_energy_services_rgb.svg';
export const IconEnergyServices = styled(IconEnergyServices_, defaultCss);
import { default as IconEnergySolutions_ } from '../assets/icons/VF_energy_solutions_rgb.svg';
export const IconEnergySolutions = styled(IconEnergySolutions_, defaultCss);
import { default as IconEspressoMachine_ } from '../assets/icons/VF_espresso_machine_rgb.svg';
export const IconEspressoMachine = styled(IconEspressoMachine_, defaultCss);
import { default as IconEucalyptus_ } from '../assets/icons/VF_eucalyptus_rgb.svg';
export const IconEucalyptus = styled(IconEucalyptus_, defaultCss);
import { default as IconEuro_ } from '../assets/icons/VF_euro_rgb.svg';
export const IconEuro = styled(IconEuro_, defaultCss);
import { default as IconEuros_ } from '../assets/icons/VF_euros_rgb.svg';
export const IconEuros = styled(IconEuros_, defaultCss);
import { default as IconEvaluation_ } from '../assets/icons/VF_evaluation_rgb.svg';
export const IconEvaluation = styled(IconEvaluation_, defaultCss);
import { default as IconEventSecurity_ } from '../assets/icons/VF_event_security_rgb.svg';
export const IconEventSecurity = styled(IconEventSecurity_, defaultCss);
import { default as IconExecutiveSecurity_ } from '../assets/icons/VF_executive_security_rgb.svg';
export const IconExecutiveSecurity = styled(IconExecutiveSecurity_, defaultCss);
import { default as IconExtractorHood_ } from '../assets/icons/VF_extractor_hood_rgb.svg';
export const IconExtractorHood = styled(IconExtractorHood_, defaultCss);
import { default as IconEyeClosed_ } from '../assets/icons/VF_eye_closed_rgb.svg';
export const IconEyeClosed = styled(IconEyeClosed_, defaultCss);
import { default as IconEye_ } from '../assets/icons/VF_eye_rgb.svg';
export const IconEye = styled(IconEye_, defaultCss);
import { default as IconFacebook_ } from '../assets/icons/VF_facebook_rgb.svg';
export const IconFacebook = styled(IconFacebook_, defaultCss);
import { default as IconFaceMask_ } from '../assets/icons/VF_face_mask_rgb.svg';
export const IconFaceMask = styled(IconFaceMask_, defaultCss);
import { default as IconFairyLights_ } from '../assets/icons/VF_fairy_lights_rgb.svg';
export const IconFairyLights = styled(IconFairyLights_, defaultCss);
import { default as IconFarm_ } from '../assets/icons/VF_farm_rgb.svg';
export const IconFarm = styled(IconFarm_, defaultCss);
import { default as IconFastCharge_ } from '../assets/icons/VF_fast_charge_rgb.svg';
export const IconFastCharge = styled(IconFastCharge_, defaultCss);
import { default as IconFavourite_ } from '../assets/icons/VF_favourite_rgb.svg';
export const IconFavourite = styled(IconFavourite_, defaultCss);
import { default as IconFfp2_ } from '../assets/icons/VF_FFP2_rgb.svg';
export const IconFfp2 = styled(IconFfp2_, defaultCss);
import { default as IconFilter_ } from '../assets/icons/VF_filter_rgb.svg';
export const IconFilter = styled(IconFilter_, defaultCss);
import { default as IconFirstAid_ } from '../assets/icons/VF_first_aid_rgb.svg';
export const IconFirstAid = styled(IconFirstAid_, defaultCss);
import { default as IconFixedPriceFossilFree_ } from '../assets/icons/VF_fixed_price_fossil_free_rgb.svg';
export const IconFixedPriceFossilFree = styled(IconFixedPriceFossilFree_, defaultCss);
import { default as IconFixed_ } from '../assets/icons/VF_fixed_rgb.svg';
export const IconFixed = styled(IconFixed_, defaultCss);
import { default as IconFlashlight_ } from '../assets/icons/VF_flashlight_rgb.svg';
export const IconFlashlight = styled(IconFlashlight_, defaultCss);
import { default as IconFlexible_ } from '../assets/icons/VF_flexible_rgb.svg';
export const IconFlexible = styled(IconFlexible_, defaultCss);
import { default as IconFlickr_ } from '../assets/icons/VF_flickr_rgb.svg';
export const IconFlickr = styled(IconFlickr_, defaultCss);
import { default as IconFoodWaste_ } from '../assets/icons/VF_food_waste_rgb.svg';
export const IconFoodWaste = styled(IconFoodWaste_, defaultCss);
import { default as IconFreezer_ } from '../assets/icons/VF_freezer_rgb.svg';
export const IconFreezer = styled(IconFreezer_, defaultCss);
import { default as IconFridgeFreezer_ } from '../assets/icons/VF_fridge_freezer_rgb.svg';
export const IconFridgeFreezer = styled(IconFridgeFreezer_, defaultCss);
import { default as IconFridge_ } from '../assets/icons/VF_fridge_rgb.svg';
export const IconFridge = styled(IconFridge_, defaultCss);
import { default as IconFruitAndVegetables02_ } from '../assets/icons/VF_fruit_and_vegetables_02_rgb.svg';
export const IconFruitAndVegetables02 = styled(IconFruitAndVegetables02_, defaultCss);
import { default as IconFruitAndVegetables_ } from '../assets/icons/VF_fruit_and_vegetables_rgb.svg';
export const IconFruitAndVegetables = styled(IconFruitAndVegetables_, defaultCss);
import { default as IconFullWaterQuartal_ } from '../assets/icons/VF_full_water_quartal_rgb.svg';
export const IconFullWaterQuartal = styled(IconFullWaterQuartal_, defaultCss);
import { default as IconFuseBox_ } from '../assets/icons/VF_fuse_box_rgb.svg';
export const IconFuseBox = styled(IconFuseBox_, defaultCss);
import { default as IconGallery_ } from '../assets/icons/VF_gallery_rgb.svg';
export const IconGallery = styled(IconGallery_, defaultCss);
import { default as IconGas_ } from '../assets/icons/VF_gas_rgb.svg';
export const IconGas = styled(IconGas_, defaultCss);
import { default as IconGasStove_ } from '../assets/icons/VF_gas_stove_rgb.svg';
export const IconGasStove = styled(IconGasStove_, defaultCss);
import { default as IconGeothermalHeatPump_ } from '../assets/icons/VF_geothermal_heat_pump_rgb.svg';
export const IconGeothermalHeatPump = styled(IconGeothermalHeatPump_, defaultCss);
import { default as IconGlobe1Svg_ } from '../assets/icons/VF_globe_rgb-1.svg';
export const IconGlobe1Svg = styled(IconGlobe1Svg_, defaultCss);
import { default as IconGlobe_ } from '../assets/icons/VF_globe_rgb.svg';
export const IconGlobe = styled(IconGlobe_, defaultCss);
import { default as IconGoingOut_ } from '../assets/icons/VF_going_out_rgb.svg';
export const IconGoingOut = styled(IconGoingOut_, defaultCss);
import { default as IconGps_ } from '../assets/icons/VF_gps_rgb.svg';
export const IconGps = styled(IconGps_, defaultCss);
import { default as IconGroupDiscussion_ } from '../assets/icons/VF_group_discussion_rgb.svg';
export const IconGroupDiscussion = styled(IconGroupDiscussion_, defaultCss);
import { default as IconGuide_ } from '../assets/icons/VF_guide_rgb.svg';
export const IconGuide = styled(IconGuide_, defaultCss);
import { default as IconGym_ } from '../assets/icons/VF_gym_rgb.svg';
export const IconGym = styled(IconGym_, defaultCss);
import { default as IconHairdryer_ } from '../assets/icons/VF_hairdryer_rgb.svg';
export const IconHairdryer = styled(IconHairdryer_, defaultCss);
import { default as IconHairStraightener_ } from '../assets/icons/VF_hair_straightener_rgb.svg';
export const IconHairStraightener = styled(IconHairStraightener_, defaultCss);
import { default as IconHandshake_ } from '../assets/icons/VF_handshake_rgb.svg';
export const IconHandshake = styled(IconHandshake_, defaultCss);
import { default as IconHeating_ } from '../assets/icons/VF_heating_rgb.svg';
export const IconHeating = styled(IconHeating_, defaultCss);
import { default as IconHeatLoadCurve_ } from '../assets/icons/VF_heat_load_curve_rgb.svg';
export const IconHeatLoadCurve = styled(IconHeatLoadCurve_, defaultCss);
import { default as IconHeatPump_ } from '../assets/icons/VF_heat_pump_rgb.svg';
export const IconHeatPump = styled(IconHeatPump_, defaultCss);
import { default as IconHelp_ } from '../assets/icons/VF_help_rgb.svg';
export const IconHelp = styled(IconHelp_, defaultCss);
import { default as IconHistoryOfChargeSession_ } from '../assets/icons/VF_history_of_charge_session_rgb.svg';
export const IconHistoryOfChargeSession = styled(IconHistoryOfChargeSession_, defaultCss);
import { default as IconHomeGarden_ } from '../assets/icons/VF_home_garden_rgb.svg';
export const IconHomeGarden = styled(IconHomeGarden_, defaultCss);
import { default as IconHomeSecurity_ } from '../assets/icons/VF_home_security_rgb.svg';
export const IconHomeSecurity = styled(IconHomeSecurity_, defaultCss);
import { default as IconHot_ } from '../assets/icons/VF_hot_rgb.svg';
export const IconHot = styled(IconHot_, defaultCss);
import { default as IconHourlySpotMix_ } from '../assets/icons/VF_hourly_spot_mix_rgb.svg';
export const IconHourlySpotMix = styled(IconHourlySpotMix_, defaultCss);
import { default as IconHouse1Svg_ } from '../assets/icons/VF_house_rgb-1.svg';
export const IconHouse1Svg = styled(IconHouse1Svg_, defaultCss);
import { default as IconHouse_ } from '../assets/icons/VF_house_rgb.svg';
export const IconHouse = styled(IconHouse_, defaultCss);
import { default as IconHydrogenFuelStation_ } from '../assets/icons/VF_hydrogen_fuel_station_rgb.svg';
export const IconHydrogenFuelStation = styled(IconHydrogenFuelStation_, defaultCss);
import { default as IconHydro_ } from '../assets/icons/VF_hydro_rgb.svg';
export const IconHydro = styled(IconHydro_, defaultCss);
import { default as IconInductionStove_ } from '../assets/icons/VF_induction_stove_rgb.svg';
export const IconInductionStove = styled(IconInductionStove_, defaultCss);
import { default as IconIndustrialHeatPump_ } from '../assets/icons/VF_industrial_heat_pump_rgb.svg';
export const IconIndustrialHeatPump = styled(IconIndustrialHeatPump_, defaultCss);
import { default as IconIndustrialWasteHeat_ } from '../assets/icons/VF_industrial_waste_heat_rgb.svg';
export const IconIndustrialWasteHeat = styled(IconIndustrialWasteHeat_, defaultCss);
import { default as IconIndustrialWasteHeatTwo_ } from '../assets/icons/VF_industrial_waste_heat_two_rgb.svg';
export const IconIndustrialWasteHeatTwo = styled(IconIndustrialWasteHeatTwo_, defaultCss);
import { default as IconIndustry_ } from '../assets/icons/VF_industry_rgb.svg';
export const IconIndustry = styled(IconIndustry_, defaultCss);
import { default as IconInformation_ } from '../assets/icons/VF_information_rgb.svg';
export const IconInformation = styled(IconInformation_, defaultCss);
import { default as IconInformationSecurity_ } from '../assets/icons/VF_information_security_rgb.svg';
export const IconInformationSecurity = styled(IconInformationSecurity_, defaultCss);
import { default as IconInfrastructureService_ } from '../assets/icons/VF_infrastructure_service_rgb.svg';
export const IconInfrastructureService = styled(IconInfrastructureService_, defaultCss);
import { default as IconInProgress_ } from '../assets/icons/VF_in_progress_rgb.svg';
export const IconInProgress = styled(IconInProgress_, defaultCss);
import { default as IconInQueue_ } from '../assets/icons/VF_in_queue_rgb.svg';
export const IconInQueue = styled(IconInQueue_, defaultCss);
import { default as IconInsight_ } from '../assets/icons/VF_insight_rgb.svg';
export const IconInsight = styled(IconInsight_, defaultCss);
import { default as IconInstagram_ } from '../assets/icons/VF_instagram_rgb.svg';
export const IconInstagram = styled(IconInstagram_, defaultCss);
import { default as IconInsulation_ } from '../assets/icons/VF_insulation_rgb.svg';
export const IconInsulation = styled(IconInsulation_, defaultCss);
import { default as IconInternet_ } from '../assets/icons/VF_internet_rgb.svg';
export const IconInternet = styled(IconInternet_, defaultCss);
import { default as IconInvoiceEuro_ } from '../assets/icons/VF_invoice_euro_rgb.svg';
export const IconInvoiceEuro = styled(IconInvoiceEuro_, defaultCss);
import { default as IconInvoicePound_ } from '../assets/icons/VF_invoice_pound_rgb.svg';
export const IconInvoicePound = styled(IconInvoicePound_, defaultCss);
import { default as IconKettle_ } from '../assets/icons/VF_kettle_rgb.svg';
export const IconKettle = styled(IconKettle_, defaultCss);
import { default as IconKidsToys_ } from '../assets/icons/VF_kids_toys_rgb.svg';
export const IconKidsToys = styled(IconKidsToys_, defaultCss);
import { default as IconKitchenMachine_ } from '../assets/icons/VF_kitchen_machine_rgb.svg';
export const IconKitchenMachine = styled(IconKitchenMachine_, defaultCss);
import { default as IconKnowledge_ } from '../assets/icons/VF_knowledge_rgb.svg';
export const IconKnowledge = styled(IconKnowledge_, defaultCss);
import { default as IconLaptop_ } from '../assets/icons/VF_laptop_rgb.svg';
export const IconLaptop = styled(IconLaptop_, defaultCss);
import { default as IconLargeBattery_ } from '../assets/icons/VF_large_battery_rgb.svg';
export const IconLargeBattery = styled(IconLargeBattery_, defaultCss);
import { default as IconLayers_ } from '../assets/icons/VF_layers_rgb.svg';
export const IconLayers = styled(IconLayers_, defaultCss);
import { default as IconLecture_ } from '../assets/icons/VF_lecture_rgb.svg';
export const IconLecture = styled(IconLecture_, defaultCss);
import { default as IconLeft_ } from '../assets/icons/VF_left_rgb.svg';
export const IconLeft = styled(IconLeft_, defaultCss);
import { default as IconLessInfo_ } from '../assets/icons/VF_less_info_rgb.svg';
export const IconLessInfo = styled(IconLessInfo_, defaultCss);
import { default as IconLifeEvents_ } from '../assets/icons/VF_life_events_rgb.svg';
export const IconLifeEvents = styled(IconLifeEvents_, defaultCss);
import { default as IconLightbulb_ } from '../assets/icons/VF_lightbulb_rgb.svg';
export const IconLightbulb = styled(IconLightbulb_, defaultCss);
import { default as IconLightbulbStep1_ } from '../assets/icons/VF_lightbulb_step_1_rgb.svg';
export const IconLightbulbStep1 = styled(IconLightbulbStep1_, defaultCss);
import { default as IconLightbulbStep2_ } from '../assets/icons/VF_lightbulb_step_2_rgb.svg';
export const IconLightbulbStep2 = styled(IconLightbulbStep2_, defaultCss);
import { default as IconLightbulbStep3_ } from '../assets/icons/VF_lightbulb_step_3_rgb.svg';
export const IconLightbulbStep3 = styled(IconLightbulbStep3_, defaultCss);
import { default as IconLightbulbStep4_ } from '../assets/icons/VF_lightbulb_step_4_rgb.svg';
export const IconLightbulbStep4 = styled(IconLightbulbStep4_, defaultCss);
import { default as IconLightbulbStep5_ } from '../assets/icons/VF_lightbulb_step_5_rgb.svg';
export const IconLightbulbStep5 = styled(IconLightbulbStep5_, defaultCss);
import { default as IconLightSwitch_ } from '../assets/icons/VF_light_switch_rgb.svg';
export const IconLightSwitch = styled(IconLightSwitch_, defaultCss);
import { default as IconLinkedin_ } from '../assets/icons/VF_linkedin_rgb.svg';
export const IconLinkedin = styled(IconLinkedin_, defaultCss);
import { default as IconLink_ } from '../assets/icons/VF_link_rgb.svg';
export const IconLink = styled(IconLink_, defaultCss);
import { default as IconListView_ } from '../assets/icons/VF_list_view_rgb.svg';
export const IconListView = styled(IconListView_, defaultCss);
import { default as IconLivestock_ } from '../assets/icons/VF_livestock_rgb.svg';
export const IconLivestock = styled(IconLivestock_, defaultCss);
import { default as IconLocalMarketKnowledge_ } from '../assets/icons/VF_local_market_knowledge_rgb.svg';
export const IconLocalMarketKnowledge = styled(IconLocalMarketKnowledge_, defaultCss);
import { default as IconLocked_ } from '../assets/icons/VF_locked_rgb.svg';
export const IconLocked = styled(IconLocked_, defaultCss);
import { default as IconLostCard_ } from '../assets/icons/VF_lost_card_rgb.svg';
export const IconLostCard = styled(IconLostCard_, defaultCss);
import { default as IconLounge_ } from '../assets/icons/VF_lounge_rgb.svg';
export const IconLounge = styled(IconLounge_, defaultCss);
import { default as IconLte_ } from '../assets/icons/VF_LTE_rgb.svg';
export const IconLte = styled(IconLte_, defaultCss);
import { default as IconLuggage_ } from '../assets/icons/VF_luggage_rgb.svg';
export const IconLuggage = styled(IconLuggage_, defaultCss);
import { default as IconMachine_ } from '../assets/icons/VF_machine_rgb.svg';
export const IconMachine = styled(IconMachine_, defaultCss);
import { default as IconMail_ } from '../assets/icons/VF_mail_rgb.svg';
export const IconMail = styled(IconMail_, defaultCss);
import { default as IconManageMyTeam_ } from '../assets/icons/VF_manage_my_team_rgb.svg';
export const IconManageMyTeam = styled(IconManageMyTeam_, defaultCss);
import { default as IconMap_ } from '../assets/icons/VF_map_rgb.svg';
export const IconMap = styled(IconMap_, defaultCss);
import { default as IconMeat_ } from '../assets/icons/VF_meat_rgb.svg';
export const IconMeat = styled(IconMeat_, defaultCss);
import { default as IconMedicalEmergency_ } from '../assets/icons/VF_medical_emergency_rgb.svg';
export const IconMedicalEmergency = styled(IconMedicalEmergency_, defaultCss);
import { default as IconMenu_ } from '../assets/icons/VF_menu_rgb.svg';
export const IconMenu = styled(IconMenu_, defaultCss);
import { default as IconMeter_ } from '../assets/icons/VF_meter_rgb.svg';
export const IconMeter = styled(IconMeter_, defaultCss);
import { default as IconMicrowave_ } from '../assets/icons/VF_microwave_rgb.svg';
export const IconMicrowave = styled(IconMicrowave_, defaultCss);
import { default as IconMinerals_ } from '../assets/icons/VF_minerals_rgb.svg';
export const IconMinerals = styled(IconMinerals_, defaultCss);
import { default as IconMobility_ } from '../assets/icons/VF_mobility_rgb.svg';
export const IconMobility = styled(IconMobility_, defaultCss);
import { default as IconModules_ } from '../assets/icons/VF_modules_rgb.svg';
export const IconModules = styled(IconModules_, defaultCss);
import { default as IconMoon_ } from '../assets/icons/VF_moon_rgb.svg';
export const IconMoon = styled(IconMoon_, defaultCss);
import { default as IconMore_ } from '../assets/icons/VF_more_rgb.svg';
export const IconMore = styled(IconMore_, defaultCss);
import { default as IconMoveWithExistingContract_ } from '../assets/icons/VF_move_with_existing_contract_rgb.svg';
export const IconMoveWithExistingContract = styled(IconMoveWithExistingContract_, defaultCss);
import { default as IconMoveWithNewContract_ } from '../assets/icons/VF_move_with_new_contract_rgb.svg';
export const IconMoveWithNewContract = styled(IconMoveWithNewContract_, defaultCss);
import { default as IconMovieMusicTheatre_ } from '../assets/icons/VF_movie_music_theatre_rgb.svg';
export const IconMovieMusicTheatre = styled(IconMovieMusicTheatre_, defaultCss);
import { default as IconMultiHomeBox_ } from '../assets/icons/VF_multi_home_box_rgb.svg';
export const IconMultiHomeBox = styled(IconMultiHomeBox_, defaultCss);
import { default as IconMyCases_ } from '../assets/icons/VF_my_cases_rgb.svg';
export const IconMyCases = styled(IconMyCases_, defaultCss);
import { default as IconMyDocuments_ } from '../assets/icons/VF_my_documents_rgb.svg';
export const IconMyDocuments = styled(IconMyDocuments_, defaultCss);
import { default as IconMysteryCoffee_ } from '../assets/icons/VF_mystery_coffee_rgb.svg';
export const IconMysteryCoffee = styled(IconMysteryCoffee_, defaultCss);
import { default as IconNatureAndBiodiversity_ } from '../assets/icons/VF_nature_and_biodiversity_rgb.svg';
export const IconNatureAndBiodiversity = styled(IconNatureAndBiodiversity_, defaultCss);
import { default as IconNeed_ } from '../assets/icons/VF_need_rgb.svg';
export const IconNeed = styled(IconNeed_, defaultCss);
import { default as IconNetworkOperator_ } from '../assets/icons/VF_network_operator_rgb.svg';
export const IconNetworkOperator = styled(IconNetworkOperator_, defaultCss);
import { default as IconNews_ } from '../assets/icons/VF_news_rgb.svg';
export const IconNews = styled(IconNews_, defaultCss);
import { default as IconNighttimeElectricityMetering_ } from '../assets/icons/VF_nighttime_electricity_metering_rgb.svg';
export const IconNighttimeElectricityMetering = styled(IconNighttimeElectricityMetering_, defaultCss);
import { default as IconNoAlcohol_ } from '../assets/icons/VF_no_alcohol_rgb.svg';
export const IconNoAlcohol = styled(IconNoAlcohol_, defaultCss);
import { default as IconNoAnimals_ } from '../assets/icons/VF_no_animals_rgb.svg';
export const IconNoAnimals = styled(IconNoAnimals_, defaultCss);
import { default as IconNoCctv_ } from '../assets/icons/VF_no_cctv_rgb.svg';
export const IconNoCctv = styled(IconNoCctv_, defaultCss);
import { default as IconNoFood_ } from '../assets/icons/VF_no_food_rgb.svg';
export const IconNoFood = styled(IconNoFood_, defaultCss);
import { default as IconNoFoodTwo_ } from '../assets/icons/VF_no_food_two_rgb.svg';
export const IconNoFoodTwo = styled(IconNoFoodTwo_, defaultCss);
import { default as IconNoMeter_ } from '../assets/icons/VF_no_meter_rgb.svg';
export const IconNoMeter = styled(IconNoMeter_, defaultCss);
import { default as IconNoParkingTrucks_ } from '../assets/icons/VF_no_parking_trucks_rgb.svg';
export const IconNoParkingTrucks = styled(IconNoParkingTrucks_, defaultCss);
import { default as IconNoPhotography_ } from '../assets/icons/VF_no_photography_rgb.svg';
export const IconNoPhotography = styled(IconNoPhotography_, defaultCss);
import { default as IconNoSmoking_ } from '../assets/icons/VF_no_smoking_rgb.svg';
export const IconNoSmoking = styled(IconNoSmoking_, defaultCss);
import { default as IconNotVisible_ } from '../assets/icons/VF_not_visible_rgb.svg';
export const IconNotVisible = styled(IconNotVisible_, defaultCss);
import { default as IconNuclear_ } from '../assets/icons/VF_nuclear_rgb.svg';
export const IconNuclear = styled(IconNuclear_, defaultCss);
import { default as IconOceanEnergy_ } from '../assets/icons/VF_ocean_energy_rgb.svg';
export const IconOceanEnergy = styled(IconOceanEnergy_, defaultCss);
import { default as IconOfficeStorage_ } from '../assets/icons/VF_office_storage_rgb.svg';
export const IconOfficeStorage = styled(IconOfficeStorage_, defaultCss);
import { default as IconOff_ } from '../assets/icons/VF_off_rgb.svg';
export const IconOff = styled(IconOff_, defaultCss);
import { default as IconOffshoreCableA_ } from '../assets/icons/VF_offshore_cable_a_rgb.svg';
export const IconOffshoreCableA = styled(IconOffshoreCableA_, defaultCss);
import { default as IconOffshoreCableB_ } from '../assets/icons/VF_offshore_cable_b_rgb.svg';
export const IconOffshoreCableB = styled(IconOffshoreCableB_, defaultCss);
import { default as IconOffshore_ } from '../assets/icons/VF_offshore_rgb.svg';
export const IconOffshore = styled(IconOffshore_, defaultCss);
import { default as IconOilCondesingGasTurbine_ } from '../assets/icons/VF_oil condesing_gas turbine_rgb.svg';
export const IconOilCondesingGasTurbine = styled(IconOilCondesingGasTurbine_, defaultCss);
import { default as IconOnshoreCableA_ } from '../assets/icons/VF_onshore_cable_a_rgb.svg';
export const IconOnshoreCableA = styled(IconOnshoreCableA_, defaultCss);
import { default as IconOnshoreCableB_ } from '../assets/icons/VF_onshore_cable_b_rgb.svg';
export const IconOnshoreCableB = styled(IconOnshoreCableB_, defaultCss);
import { default as IconOpenArea_ } from '../assets/icons/VF_open area_rgb.svg';
export const IconOpenArea = styled(IconOpenArea_, defaultCss);
import { default as IconOpenNewWindow_ } from '../assets/icons/VF_open_new_window_rgb.svg';
export const IconOpenNewWindow = styled(IconOpenNewWindow_, defaultCss);
import { default as IconOperationCentral_ } from '../assets/icons/VF_operation_central_rgb.svg';
export const IconOperationCentral = styled(IconOperationCentral_, defaultCss);
import { default as IconOperationCentralThree_ } from '../assets/icons/VF_operation_central_three_rgb.svg';
export const IconOperationCentralThree = styled(IconOperationCentralThree_, defaultCss);
import { default as IconOperationCentralTwo_ } from '../assets/icons/VF_operation_central_two_rgb.svg';
export const IconOperationCentralTwo = styled(IconOperationCentralTwo_, defaultCss);
import { default as IconOtSecurity_ } from '../assets/icons/VF_OT_security_rgb.svg';
export const IconOtSecurity = styled(IconOtSecurity_, defaultCss);
import { default as IconOutdoorLamp_ } from '../assets/icons/VF_outdoor_lamp_rgb.svg';
export const IconOutdoorLamp = styled(IconOutdoorLamp_, defaultCss);
import { default as IconParkingCars_ } from '../assets/icons/VF_parking_cars_rgb.svg';
export const IconParkingCars = styled(IconParkingCars_, defaultCss);
import { default as IconParkingMeter_ } from '../assets/icons/VF_parking_meter_rgb.svg';
export const IconParkingMeter = styled(IconParkingMeter_, defaultCss);
import { default as IconParking_ } from '../assets/icons/VF_parking_rgb.svg';
export const IconParking = styled(IconParking_, defaultCss);
import { default as IconParkingTrucks_ } from '../assets/icons/VF_parking_trucks_rgb.svg';
export const IconParkingTrucks = styled(IconParkingTrucks_, defaultCss);
import { default as IconPartner_ } from '../assets/icons/VF_partner_rgb.svg';
export const IconPartner = styled(IconPartner_, defaultCss);
import { default as IconPatioHeater_ } from '../assets/icons/VF_patio_heater_rgb.svg';
export const IconPatioHeater = styled(IconPatioHeater_, defaultCss);
import { default as IconPause_ } from '../assets/icons/VF_pause_rgb.svg';
export const IconPause = styled(IconPause_, defaultCss);
import { default as IconPeas_ } from '../assets/icons/VF_peas_rgb.svg';
export const IconPeas = styled(IconPeas_, defaultCss);
import { default as IconPeat_ } from '../assets/icons/VF_peat_rgb.svg';
export const IconPeat = styled(IconPeat_, defaultCss);
import { default as IconPersonnelSecurity_ } from '../assets/icons/VF_personnel_security_rgb.svg';
export const IconPersonnelSecurity = styled(IconPersonnelSecurity_, defaultCss);
import { default as IconPhoneCharging_ } from '../assets/icons/VF_phone_charging_rgb.svg';
export const IconPhoneCharging = styled(IconPhoneCharging_, defaultCss);
import { default as IconPhone_ } from '../assets/icons/VF_phone_rgb.svg';
export const IconPhone = styled(IconPhone_, defaultCss);
import { default as IconPhoneSoundOff_ } from '../assets/icons/VF_phone-sound off_rgb.svg';
export const IconPhoneSoundOff = styled(IconPhoneSoundOff_, defaultCss);
import { default as IconPineapple_ } from '../assets/icons/VF_pineapple_rgb.svg';
export const IconPineapple = styled(IconPineapple_, defaultCss);
import { default as IconPin_ } from '../assets/icons/VF_pin_rgb.svg';
export const IconPin = styled(IconPin_, defaultCss);
import { default as IconPinterest_ } from '../assets/icons/VF_pinterest_rgb.svg';
export const IconPinterest = styled(IconPinterest_, defaultCss);
import { default as IconPinTwo_ } from '../assets/icons/VF_pin_two_rgb.svg';
export const IconPinTwo = styled(IconPinTwo_, defaultCss);
import { default as IconPlanets_ } from '../assets/icons/VF_planets_rgb.svg';
export const IconPlanets = styled(IconPlanets_, defaultCss);
import { default as IconPlannedOutage_ } from '../assets/icons/VF_planned_outage_rgb.svg';
export const IconPlannedOutage = styled(IconPlannedOutage_, defaultCss);
import { default as IconPlasticBottle_ } from '../assets/icons/VF_plastic_bottle_rgb.svg';
export const IconPlasticBottle = styled(IconPlasticBottle_, defaultCss);
import { default as IconPlasticWasteA_ } from '../assets/icons/VF_plastic_waste_a_rgb.svg';
export const IconPlasticWasteA = styled(IconPlasticWasteA_, defaultCss);
import { default as IconPlasticWasteB_ } from '../assets/icons/VF_plastic_waste_b_rgb.svg';
export const IconPlasticWasteB = styled(IconPlasticWasteB_, defaultCss);
import { default as IconPlay_ } from '../assets/icons/VF_play_rgb.svg';
export const IconPlay = styled(IconPlay_, defaultCss);
import { default as IconPolicies_ } from '../assets/icons/VF_policies_rgb.svg';
export const IconPolicies = styled(IconPolicies_, defaultCss);
import { default as IconPowerGas_ } from '../assets/icons/VF_power_gas_rgb.svg';
export const IconPowerGas = styled(IconPowerGas_, defaultCss);
import { default as IconPowerGrid_ } from '../assets/icons/VF_power_grid_rgb.svg';
export const IconPowerGrid = styled(IconPowerGrid_, defaultCss);
import { default as IconPowerOutage_ } from '../assets/icons/VF_power_outage_rgb.svg';
export const IconPowerOutage = styled(IconPowerOutage_, defaultCss);
import { default as IconPowerPlantHeatAndPower_ } from '../assets/icons/VF_power_plant_heat_and_power_rgb.svg';
export const IconPowerPlantHeatAndPower = styled(IconPowerPlantHeatAndPower_, defaultCss);
import { default as IconPowerPlant_ } from '../assets/icons/VF_power_plant_rgb.svg';
export const IconPowerPlant = styled(IconPowerPlant_, defaultCss);
import { default as IconPowerPurchaseAgreement_ } from '../assets/icons/VF_power_purchase_agreement_rgb.svg';
export const IconPowerPurchaseAgreement = styled(IconPowerPurchaseAgreement_, defaultCss);
import { default as IconPreference_ } from '../assets/icons/VF_preference_rgb.svg';
export const IconPreference = styled(IconPreference_, defaultCss);
import { default as IconPremium_ } from '../assets/icons/VF_premium_rgb.svg';
export const IconPremium = styled(IconPremium_, defaultCss);
import { default as IconPresentation_ } from '../assets/icons/VF_presentation_rgb.svg';
export const IconPresentation = styled(IconPresentation_, defaultCss);
import { default as IconPresent_ } from '../assets/icons/VF_present_rgb.svg';
export const IconPresent = styled(IconPresent_, defaultCss);
import { default as IconPressingIron_ } from '../assets/icons/VF_pressing_iron_rgb.svg';
export const IconPressingIron = styled(IconPressingIron_, defaultCss);
import { default as IconPressureStorage_ } from '../assets/icons/VF_pressure_storage_rgb.svg';
export const IconPressureStorage = styled(IconPressureStorage_, defaultCss);
import { default as IconPricesEqualAlt_ } from '../assets/icons/VF_prices_equal_alt_rgb.svg';
export const IconPricesEqualAlt = styled(IconPricesEqualAlt_, defaultCss);
import { default as IconPricesEqual_ } from '../assets/icons/VF_prices_equal_rgb.svg';
export const IconPricesEqual = styled(IconPricesEqual_, defaultCss);
import { default as IconPricesFallAlt_ } from '../assets/icons/VF_prices_fall_alt_rgb.svg';
export const IconPricesFallAlt = styled(IconPricesFallAlt_, defaultCss);
import { default as IconPricesFall_ } from '../assets/icons/VF_prices_fall_rgb.svg';
export const IconPricesFall = styled(IconPricesFall_, defaultCss);
import { default as IconPricesRiseAlt_ } from '../assets/icons/VF_prices_rise_alt_rgb.svg';
export const IconPricesRiseAlt = styled(IconPricesRiseAlt_, defaultCss);
import { default as IconPricesRise_ } from '../assets/icons/VF_prices_rise_rgb.svg';
export const IconPricesRise = styled(IconPricesRise_, defaultCss);
import { default as IconPrint_ } from '../assets/icons/VF_print_rgb.svg';
export const IconPrint = styled(IconPrint_, defaultCss);
import { default as IconPrivacy_ } from '../assets/icons/VF_privacy_rgb.svg';
export const IconPrivacy = styled(IconPrivacy_, defaultCss);
import { default as IconPrivateCharging_ } from '../assets/icons/VF_private_charging_rgb.svg';
export const IconPrivateCharging = styled(IconPrivateCharging_, defaultCss);
import { default as IconPrivateEndUser_ } from '../assets/icons/VF_private_end_user_rgb.svg';
export const IconPrivateEndUser = styled(IconPrivateEndUser_, defaultCss);
import { default as IconPublicBusinessCharging_ } from '../assets/icons/VF_public_business_charging_rgb.svg';
export const IconPublicBusinessCharging = styled(IconPublicBusinessCharging_, defaultCss);
import { default as IconPublicCharging_ } from '../assets/icons/VF_public_charging_rgb.svg';
export const IconPublicCharging = styled(IconPublicCharging_, defaultCss);
import { default as IconPublicTransport_ } from '../assets/icons/VF_public_transport_rgb.svg';
export const IconPublicTransport = styled(IconPublicTransport_, defaultCss);
import { default as IconPurposeProfit_ } from '../assets/icons/VF_purpose_profit_rgb.svg';
export const IconPurposeProfit = styled(IconPurposeProfit_, defaultCss);
import { default as IconQrCode_ } from '../assets/icons/VF_qr_code_rgb.svg';
export const IconQrCode = styled(IconQrCode_, defaultCss);
import { default as IconQuote_ } from '../assets/icons/VF_quote_rgb.svg';
export const IconQuote = styled(IconQuote_, defaultCss);
import { default as IconRadiator_ } from '../assets/icons/VF_radiator_rgb.svg';
export const IconRadiator = styled(IconRadiator_, defaultCss);
import { default as IconRain_ } from '../assets/icons/VF_rain_rgb.svg';
export const IconRain = styled(IconRain_, defaultCss);
import { default as IconReach_ } from '../assets/icons/VF_reach_rgb.svg';
export const IconReach = styled(IconReach_, defaultCss);
import { default as IconRecommendation_ } from '../assets/icons/VF_recommendation_rgb.svg';
export const IconRecommendation = styled(IconRecommendation_, defaultCss);
import { default as IconRecycling_ } from '../assets/icons/VF_recycling_rgb.svg';
export const IconRecycling = styled(IconRecycling_, defaultCss);
import { default as IconRefresh_ } from '../assets/icons/VF_refresh_rgb.svg';
export const IconRefresh = styled(IconRefresh_, defaultCss);
import { default as IconReimburse_ } from '../assets/icons/VF_reimburse_rgb.svg';
export const IconReimburse = styled(IconReimburse_, defaultCss);
import { default as IconVfRemoveCard_ } from '../assets/icons/Vf_remove_card_rgb.svg';
export const IconVfRemoveCard = styled(IconVfRemoveCard_, defaultCss);
import { default as IconRenewableEnergy_ } from '../assets/icons/VF_renewable_energy_rgb.svg';
export const IconRenewableEnergy = styled(IconRenewableEnergy_, defaultCss);
import { default as IconRequest_ } from '../assets/icons/VF_request_rgb.svg';
export const IconRequest = styled(IconRequest_, defaultCss);
import { default as IconResilience_ } from '../assets/icons/VF_resilience_rgb.svg';
export const IconResilience = styled(IconResilience_, defaultCss);
import { default as IconRestart_ } from '../assets/icons/VF_restart_rgb.svg';
export const IconRestart = styled(IconRestart_, defaultCss);
import { default as IconRestingRoom_ } from '../assets/icons/VF_resting_room_rgb.svg';
export const IconRestingRoom = styled(IconRestingRoom_, defaultCss);
import { default as IconRestroomDisabled_ } from '../assets/icons/VF_restroom_disabled_rgb.svg';
export const IconRestroomDisabled = styled(IconRestroomDisabled_, defaultCss);
import { default as IconRestroomsAlt_ } from '../assets/icons/VF_restrooms_alt_rgb.svg';
export const IconRestroomsAlt = styled(IconRestroomsAlt_, defaultCss);
import { default as IconRestroomsFemaleAlt_ } from '../assets/icons/VF_restrooms_female_alt_rgb.svg';
export const IconRestroomsFemaleAlt = styled(IconRestroomsFemaleAlt_, defaultCss);
import { default as IconRestroomsFemale_ } from '../assets/icons/VF_restrooms_female_rgb.svg';
export const IconRestroomsFemale = styled(IconRestroomsFemale_, defaultCss);
import { default as IconRestroomsMale_ } from '../assets/icons/VF_restrooms_male_rgb.svg';
export const IconRestroomsMale = styled(IconRestroomsMale_, defaultCss);
import { default as IconRestrooms_ } from '../assets/icons/VF_restrooms_rgb.svg';
export const IconRestrooms = styled(IconRestrooms_, defaultCss);
import { default as IconRice_ } from '../assets/icons/VF_rice_rgb.svg';
export const IconRice = styled(IconRice_, defaultCss);
import { default as IconRight_ } from '../assets/icons/VF_right_rgb.svg';
export const IconRight = styled(IconRight_, defaultCss);
import { default as IconRoad_ } from '../assets/icons/VF_road_rgb.svg';
export const IconRoad = styled(IconRoad_, defaultCss);
import { default as IconRouter_ } from '../assets/icons/VF_router_rgb.svg';
export const IconRouter = styled(IconRouter_, defaultCss);
import { default as IconRss_ } from '../assets/icons/VF_rss_rgb.svg';
export const IconRss = styled(IconRss_, defaultCss);
import { default as IconSalaryTimeAndBenefits_ } from '../assets/icons/VF_salary_time_and_benefits_rgb.svg';
export const IconSalaryTimeAndBenefits = styled(IconSalaryTimeAndBenefits_, defaultCss);
import { default as IconSalesOfElectricHeatGas_ } from '../assets/icons/VF_sales_of_electric_heat_gas_rgb.svg';
export const IconSalesOfElectricHeatGas = styled(IconSalesOfElectricHeatGas_, defaultCss);
import { default as IconSatellite_ } from '../assets/icons/VF_satellite_rgb.svg';
export const IconSatellite = styled(IconSatellite_, defaultCss);
import { default as IconSavings_ } from '../assets/icons/VF_savings_rgb.svg';
export const IconSavings = styled(IconSavings_, defaultCss);
import { default as IconSchukoConnector_ } from '../assets/icons/VF_schuko_connector_rgb.svg';
export const IconSchukoConnector = styled(IconSchukoConnector_, defaultCss);
import { default as IconScooter2_ } from '../assets/icons/VF_scooter_2_rgb.svg';
export const IconScooter2 = styled(IconScooter2_, defaultCss);
import { default as IconScooter_ } from '../assets/icons/VF_scooter_rgb.svg';
export const IconScooter = styled(IconScooter_, defaultCss);
import { default as IconSearch_ } from '../assets/icons/VF_search_rgb.svg';
export const IconSearch = styled(IconSearch_, defaultCss);
import { default as IconSecurity_ } from '../assets/icons/VF_security_rgb.svg';
export const IconSecurity = styled(IconSecurity_, defaultCss);
import { default as IconSecurityRisk_ } from '../assets/icons/VF_security_risk_rgb.svg';
export const IconSecurityRisk = styled(IconSecurityRisk_, defaultCss);
import { default as IconSemiDetachedHouse_ } from '../assets/icons/VF_semi_detached_house_rgb.svg';
export const IconSemiDetachedHouse = styled(IconSemiDetachedHouse_, defaultCss);
import { default as IconSend_ } from '../assets/icons/VF_send_rgb.svg';
export const IconSend = styled(IconSend_, defaultCss);
import { default as IconSensor_ } from '../assets/icons/VF_sensor_rgb.svg';
export const IconSensor = styled(IconSensor_, defaultCss);
import { default as IconServerRoom_ } from '../assets/icons/VF_server_room_rgb.svg';
export const IconServerRoom = styled(IconServerRoom_, defaultCss);
import { default as IconService_ } from '../assets/icons/VF_service_rgb.svg';
export const IconService = styled(IconService_, defaultCss);
import { default as IconSettings_ } from '../assets/icons/VF_settings_rgb.svg';
export const IconSettings = styled(IconSettings_, defaultCss);
import { default as IconShare_ } from '../assets/icons/VF_share_rgb.svg';
export const IconShare = styled(IconShare_, defaultCss);
import { default as IconShipDocking_ } from '../assets/icons/VF_ship_docking_rgb.svg';
export const IconShipDocking = styled(IconShipDocking_, defaultCss);
import { default as IconShop_ } from '../assets/icons/VF_shop_rgb.svg';
export const IconShop = styled(IconShop_, defaultCss);
import { default as IconShower_ } from '../assets/icons/VF_shower_rgb.svg';
export const IconShower = styled(IconShower_, defaultCss);
import { default as IconSignIn_ } from '../assets/icons/VF_sign_in_rgb.svg';
export const IconSignIn = styled(IconSignIn_, defaultCss);
import { default as IconSignOut_ } from '../assets/icons/VF_sign_out_rgb.svg';
export const IconSignOut = styled(IconSignOut_, defaultCss);
import { default as IconSilentArea_ } from '../assets/icons/VF_silent area_rgb.svg';
export const IconSilentArea = styled(IconSilentArea_, defaultCss);
import { default as IconSimcard_ } from '../assets/icons/VF_simcard_rgb.svg';
export const IconSimcard = styled(IconSimcard_, defaultCss);
import { default as IconSkype_ } from '../assets/icons/VF_skype_rgb.svg';
export const IconSkype = styled(IconSkype_, defaultCss);
import { default as IconSlideshare_ } from '../assets/icons/VF_slideshare_rgb.svg';
export const IconSlideshare = styled(IconSlideshare_, defaultCss);
import { default as IconSlowCharge_ } from '../assets/icons/VF_slow_charge_rgb.svg';
export const IconSlowCharge = styled(IconSlowCharge_, defaultCss);
import { default as IconSmartChargingPoles_ } from '../assets/icons/VF_smart_charging_poles_rgb.svg';
export const IconSmartChargingPoles = styled(IconSmartChargingPoles_, defaultCss);
import { default as IconSmartHome_ } from '../assets/icons/VF_smart_home_rgb.svg';
export const IconSmartHome = styled(IconSmartHome_, defaultCss);
import { default as IconSmartMeter_ } from '../assets/icons/VF_smart_meter_rgb.svg';
export const IconSmartMeter = styled(IconSmartMeter_, defaultCss);
import { default as IconSmartphoneText_ } from '../assets/icons/VF_smartphone_text_rgb.svg';
export const IconSmartphoneText = styled(IconSmartphoneText_, defaultCss);
import { default as IconSmartPlug_ } from '../assets/icons/VF_smart_plug_rgb.svg';
export const IconSmartPlug = styled(IconSmartPlug_, defaultCss);
import { default as IconSmartThermostat_ } from '../assets/icons/VF_smart_thermostat_rgb.svg';
export const IconSmartThermostat = styled(IconSmartThermostat_, defaultCss);
import { default as IconSmileyNeutral_ } from '../assets/icons/VF_smiley_neutral_rgb.svg';
export const IconSmileyNeutral = styled(IconSmileyNeutral_, defaultCss);
import { default as IconSmiley_ } from '../assets/icons/VF_smiley_rgb.svg';
export const IconSmiley = styled(IconSmiley_, defaultCss);
import { default as IconSmileySad_ } from '../assets/icons/VF_smiley_sad_rgb.svg';
export const IconSmileySad = styled(IconSmileySad_, defaultCss);
import { default as IconSmokeAlarm_ } from '../assets/icons/VF_smoke_alarm_rgb.svg';
export const IconSmokeAlarm = styled(IconSmokeAlarm_, defaultCss);
import { default as IconSmokingArea_ } from '../assets/icons/VF_smoking_area_rgb.svg';
export const IconSmokingArea = styled(IconSmokingArea_, defaultCss);
import { default as IconSnapchat_ } from '../assets/icons/VF_snapchat_rgb.svg';
export const IconSnapchat = styled(IconSnapchat_, defaultCss);
import { default as IconSnowflake_ } from '../assets/icons/VF_snowflake_rgb.svg';
export const IconSnowflake = styled(IconSnowflake_, defaultCss);
import { default as IconSnow_ } from '../assets/icons/VF_snow_rgb.svg';
export const IconSnow = styled(IconSnow_, defaultCss);
import { default as IconSolarBack_ } from '../assets/icons/VF_solar_back_rgb.svg';
export const IconSolarBack = styled(IconSolarBack_, defaultCss);
import { default as IconSolarPanel_ } from '../assets/icons/VF_solar_panel_rgb.svg';
export const IconSolarPanel = styled(IconSolarPanel_, defaultCss);
import { default as IconSort_ } from '../assets/icons/VF_sort_rgb.svg';
export const IconSort = styled(IconSort_, defaultCss);
import { default as IconSoy_ } from '../assets/icons/VF_soy_rgb.svg';
export const IconSoy = styled(IconSoy_, defaultCss);
import { default as IconSpeaker_ } from '../assets/icons/VF_speaker_rgb.svg';
export const IconSpeaker = styled(IconSpeaker_, defaultCss);
import { default as IconSpreadPuchases_ } from '../assets/icons/VF_spread_puchases_rgb.svg';
export const IconSpreadPuchases = styled(IconSpreadPuchases_, defaultCss);
import { default as IconStaircase_ } from '../assets/icons/VF_staircase_rgb.svg';
export const IconStaircase = styled(IconStaircase_, defaultCss);
import { default as IconStandardContractContinuos_ } from '../assets/icons/VF_standard_contract_continuos_rgb.svg';
export const IconStandardContractContinuos = styled(IconStandardContractContinuos_, defaultCss);
import { default as IconStandardEnergy_ } from '../assets/icons/VF_standard_energy_rgb.svg';
export const IconStandardEnergy = styled(IconStandardEnergy_, defaultCss);
import { default as IconSteel_ } from '../assets/icons/VF_steel_rgb.svg';
export const IconSteel = styled(IconSteel_, defaultCss);
import { default as IconSterilisation_ } from '../assets/icons/VF_sterilisation_rgb.svg';
export const IconSterilisation = styled(IconSterilisation_, defaultCss);
import { default as IconStoveWithGrill_ } from '../assets/icons/VF_stove_with_grill_rgb.svg';
export const IconStoveWithGrill = styled(IconStoveWithGrill_, defaultCss);
import { default as IconStraightEdge_ } from '../assets/icons/VF_straight_edge_rgb.svg';
export const IconStraightEdge = styled(IconStraightEdge_, defaultCss);
import { default as IconStrategy_ } from '../assets/icons/VF_strategy_rgb.svg';
export const IconStrategy = styled(IconStrategy_, defaultCss);
import { default as IconStreetLight_ } from '../assets/icons/VF_street_light_rgb.svg';
export const IconStreetLight = styled(IconStreetLight_, defaultCss);
import { default as IconSubscriptionInvoice_ } from '../assets/icons/VF_subscription_invoice_rgb.svg';
export const IconSubscriptionInvoice = styled(IconSubscriptionInvoice_, defaultCss);
import { default as IconSummary_ } from '../assets/icons/VF_summary_rgb.svg';
export const IconSummary = styled(IconSummary_, defaultCss);
import { default as IconSun_ } from '../assets/icons/VF_sun_rgb.svg';
export const IconSun = styled(IconSun_, defaultCss);
import { default as IconSunTwo_ } from '../assets/icons/VF_sun_two_rgb.svg';
export const IconSunTwo = styled(IconSunTwo_, defaultCss);
import { default as IconSupplierRelationship_ } from '../assets/icons/VF_supplier_relationship_rgb.svg';
export const IconSupplierRelationship = styled(IconSupplierRelationship_, defaultCss);
import { default as IconSupport_ } from '../assets/icons/VF_support_rgb.svg';
export const IconSupport = styled(IconSupport_, defaultCss);
import { default as IconSurveillance_ } from '../assets/icons/VF_surveillance_rgb.svg';
export const IconSurveillance = styled(IconSurveillance_, defaultCss);
import { default as IconSustainableUseOfResources_ } from '../assets/icons/VF_sustainable_use_of_resources_rgb.svg';
export const IconSustainableUseOfResources = styled(IconSustainableUseOfResources_, defaultCss);
import { default as IconSustainablyGeneratedEnergy_ } from '../assets/icons/VF_sustainably_generated_energy_rgb.svg';
export const IconSustainablyGeneratedEnergy = styled(IconSustainablyGeneratedEnergy_, defaultCss);
import { default as IconTableLamp_ } from '../assets/icons/VF_table_lamp_rgb.svg';
export const IconTableLamp = styled(IconTableLamp_, defaultCss);
import { default as IconTableLampTwo_ } from '../assets/icons/VF_table_lamp_two_rgb.svg';
export const IconTableLampTwo = styled(IconTableLampTwo_, defaultCss);
import { default as IconTag_ } from '../assets/icons/VF_tag_rgb.svg';
export const IconTag = styled(IconTag_, defaultCss);
import { default as IconTapWaterCold_ } from '../assets/icons/VF_tap_water_cold_rgb.svg';
export const IconTapWaterCold = styled(IconTapWaterCold_, defaultCss);
import { default as IconTapWaterWarm_ } from '../assets/icons/VF_tap_water_warm_rgb.svg';
export const IconTapWaterWarm = styled(IconTapWaterWarm_, defaultCss);
import { default as IconTemperature_ } from '../assets/icons/VF_temperature_rgb.svg';
export const IconTemperature = styled(IconTemperature_, defaultCss);
import { default as IconTenderMultiple_ } from '../assets/icons/VF_tender_multiple_rgb.svg';
export const IconTenderMultiple = styled(IconTenderMultiple_, defaultCss);
import { default as IconTeuContainer_ } from '../assets/icons/VF_TEU_container_rgb.svg';
export const IconTeuContainer = styled(IconTeuContainer_, defaultCss);
import { default as IconThreatAssessment_ } from '../assets/icons/VF_threat_assessment_rgb.svg';
export const IconThreatAssessment = styled(IconThreatAssessment_, defaultCss);
import { default as IconThreats_ } from '../assets/icons/VF_threats_rgb.svg';
export const IconThreats = styled(IconThreats_, defaultCss);
import { default as IconThumbsDown_ } from '../assets/icons/VF_thumbs_down_rgb.svg';
export const IconThumbsDown = styled(IconThumbsDown_, defaultCss);
import { default as IconThumbsUp_ } from '../assets/icons/VF_thumbs_up_rgb.svg';
export const IconThumbsUp = styled(IconThumbsUp_, defaultCss);
import { default as IconThunder_ } from '../assets/icons/VF_thunder_rgb.svg';
export const IconThunder = styled(IconThunder_, defaultCss);
import { default as IconToDos_ } from '../assets/icons/VF_To_dos_rgb.svg';
export const IconToDos = styled(IconToDos_, defaultCss);
import { default as IconTool_ } from '../assets/icons/VF_tool_rgb.svg';
export const IconTool = styled(IconTool_, defaultCss);
import { default as IconTrain_ } from '../assets/icons/VF_train_rgb.svg';
export const IconTrain = styled(IconTrain_, defaultCss);
import { default as IconTransformer_ } from '../assets/icons/VF_transformer_rgb.svg';
export const IconTransformer = styled(IconTransformer_, defaultCss);
import { default as IconTrashAlt02_ } from '../assets/icons/VF_trash_alt02_rgb.svg';
export const IconTrashAlt02 = styled(IconTrashAlt02_, defaultCss);
import { default as IconTShirt_ } from '../assets/icons/VF_t_shirt_rgb.svg';
export const IconTShirt = styled(IconTShirt_, defaultCss);
import { default as IconTurnDevice_ } from '../assets/icons/VF_turn_device_rgb.svg';
export const IconTurnDevice = styled(IconTurnDevice_, defaultCss);
import { default as IconTvRemote_ } from '../assets/icons/VF_tv_remote_rgb.svg';
export const IconTvRemote = styled(IconTvRemote_, defaultCss);
import { default as IconTv_ } from '../assets/icons/VF_tv_rgb.svg';
export const IconTv = styled(IconTv_, defaultCss);
import { default as IconTwitter_ } from '../assets/icons/VF_twitter_rgb.svg';
export const IconTwitter = styled(IconTwitter_, defaultCss);
import { default as IconType2Connector_ } from '../assets/icons/VF_type_2_connector_rgb.svg';
export const IconType2Connector = styled(IconType2Connector_, defaultCss);
import { default as IconUnaidedAwareness_ } from '../assets/icons/VF_unaided_awareness_rgb.svg';
export const IconUnaidedAwareness = styled(IconUnaidedAwareness_, defaultCss);
import { default as IconUndergroundStorage_ } from '../assets/icons/VF_underground_storage_rgb.svg';
export const IconUndergroundStorage = styled(IconUndergroundStorage_, defaultCss);
import { default as IconUnlocked_ } from '../assets/icons/VF_unlocked_rgb.svg';
export const IconUnlocked = styled(IconUnlocked_, defaultCss);
import { default as IconUp_ } from '../assets/icons/VF_up_rgb.svg';
export const IconUp = styled(IconUp_, defaultCss);
import { default as IconUser_ } from '../assets/icons/VF_user_rgb.svg';
export const IconUser = styled(IconUser_, defaultCss);
import { default as IconUTurn_ } from '../assets/icons/VF_u_turn_rgb.svg';
export const IconUTurn = styled(IconUTurn_, defaultCss);
import { default as IconVacuumCleaner_ } from '../assets/icons/VF_vacuum_cleaner_rgb.svg';
export const IconVacuumCleaner = styled(IconVacuumCleaner_, defaultCss);
import { default as IconVariable_ } from '../assets/icons/VF_variable_rgb.svg';
export const IconVariable = styled(IconVariable_, defaultCss);
import { default as IconVentilation_ } from '../assets/icons/VF_ventilation_rgb.svg';
export const IconVentilation = styled(IconVentilation_, defaultCss);
import { default as IconVentilator_ } from '../assets/icons/VF_ventilator_rgb.svg';
export const IconVentilator = styled(IconVentilator_, defaultCss);
import { default as IconVideoChat_ } from '../assets/icons/VF_video_chat_rgb.svg';
export const IconVideoChat = styled(IconVideoChat_, defaultCss);
import { default as IconVimeo_ } from '../assets/icons/VF_vimeo_rgb.svg';
export const IconVimeo = styled(IconVimeo_, defaultCss);
import { default as IconVoiceSearch_ } from '../assets/icons/VF_voice_search_rgb.svg';
export const IconVoiceSearch = styled(IconVoiceSearch_, defaultCss);
import { default as IconVulnerability_ } from '../assets/icons/VF_vulnerability_rgb.svg';
export const IconVulnerability = styled(IconVulnerability_, defaultCss);
import { default as IconWaitingRoom_ } from '../assets/icons/VF_waiting_room_rgb.svg';
export const IconWaitingRoom = styled(IconWaitingRoom_, defaultCss);
import { default as IconWalkway_ } from '../assets/icons/VF_walkway_rgb.svg';
export const IconWalkway = styled(IconWalkway_, defaultCss);
import { default as IconWashingMachine_ } from '../assets/icons/VF_washing_machine_rgb.svg';
export const IconWashingMachine = styled(IconWashingMachine_, defaultCss);
import { default as IconWaterBoiler_ } from '../assets/icons/VF_water_boiler_rgb.svg';
export const IconWaterBoiler = styled(IconWaterBoiler_, defaultCss);
import { default as IconWaterGlas_ } from '../assets/icons/VF_water_glas_rgb.svg';
export const IconWaterGlas = styled(IconWaterGlas_, defaultCss);
import { default as IconWcD_ } from '../assets/icons/VF_wc_d_rgb.svg';
export const IconWcD = styled(IconWcD_, defaultCss);
import { default as IconWcM_ } from '../assets/icons/VF_wc_m_rgb.svg';
export const IconWcM = styled(IconWcM_, defaultCss);
import { default as IconWcW_ } from '../assets/icons/VF_wc_w_rgb.svg';
export const IconWcW = styled(IconWcW_, defaultCss);
import { default as IconWeight_ } from '../assets/icons/VF_weight_rgb.svg';
export const IconWeight = styled(IconWeight_, defaultCss);
import { default as IconWhatsapp_ } from '../assets/icons/VF_whatsapp_rgb.svg';
export const IconWhatsapp = styled(IconWhatsapp_, defaultCss);
import { default as IconWhistleblower_ } from '../assets/icons/VF_whistleblower_rgb.svg';
export const IconWhistleblower = styled(IconWhistleblower_, defaultCss);
import { default as IconWhistleblowingCoordinator_ } from '../assets/icons/VF_whistleblowing_coordinator_rgb.svg';
export const IconWhistleblowingCoordinator = styled(IconWhistleblowingCoordinator_, defaultCss);
import { default as IconWifi_ } from '../assets/icons/VF_wifi_rgb.svg';
export const IconWifi = styled(IconWifi_, defaultCss);
import { default as IconWindowBlinds_ } from '../assets/icons/VF_window_blinds_rgb.svg';
export const IconWindowBlinds = styled(IconWindowBlinds_, defaultCss);
import { default as IconWindowClosed_ } from '../assets/icons/VF_window_closed_rgb.svg';
export const IconWindowClosed = styled(IconWindowClosed_, defaultCss);
import { default as IconWindowOpen_ } from '../assets/icons/VF_window_open_rgb.svg';
export const IconWindowOpen = styled(IconWindowOpen_, defaultCss);
import { default as IconWindow_ } from '../assets/icons/VF_window_rgb.svg';
export const IconWindow = styled(IconWindow_, defaultCss);
import { default as IconWind_ } from '../assets/icons/VF_wind_rgb.svg';
export const IconWind = styled(IconWind_, defaultCss);
import { default as IconWorkplace02_ } from '../assets/icons/VF_workplace_02_rgb.svg';
export const IconWorkplace02 = styled(IconWorkplace02_, defaultCss);
import { default as IconWorkplace_ } from '../assets/icons/VF_workplace_rgb.svg';
export const IconWorkplace = styled(IconWorkplace_, defaultCss);
import { default as IconXing_ } from '../assets/icons/VF_xing_rgb.svg';
export const IconXing = styled(IconXing_, defaultCss);
import { default as IconYoutube_ } from '../assets/icons/VF_youtube_rgb.svg';
export const IconYoutube = styled(IconYoutube_, defaultCss);
import { default as IconZoomIn_ } from '../assets/icons/VF_zoom_in_rgb.svg';
export const IconZoomIn = styled(IconZoomIn_, defaultCss);
import { default as IconZoomOut_ } from '../assets/icons/VF_zoom_out_rgb.svg';
export const IconZoomOut = styled(IconZoomOut_, defaultCss);

// Fontawesome icons
import { default as IconFACaretDown_ } from '../assets/icons/fa_caret_down.svg';
export const IconFACaretDown = styled(IconFACaretDown_, defaultCss);
import { default as IconFACaretUp_ } from '../assets/icons/fa_caret_up.svg';
export const IconFACaretUp = styled(IconFACaretUp_, defaultCss);
import { default as IconFAEllipsisVertical_ } from '../assets/icons/fa_ellipsis_vertical.svg';
export const IconFAEllipsisVertical = styled(IconFAEllipsisVertical_, defaultCss);
