import classNames from 'classnames';

import { css, DefaultProps, PropertyValue } from '../stitches.config';
import { Axis, HTMLTag } from '../types';
import { mergeCss } from '../util';
import { Box } from './Box';

export type StackProps<C extends HTMLTag = 'div'> = {
  align?: 'start' | 'center' | 'end';
  justify?: 'start' | 'center' | 'end';
  axis?: Axis;
  reverse?: boolean;
  gap?: PropertyValue<'gap'>;
} & DefaultProps<C>;

export const Stack = function <C extends HTMLTag>({
  align,
  axis = 'x',
  className,
  gap = 0,
  justify,
  ...props
}: StackProps<C>) {
  return (
    <Box
      className={classNames(
        css({
          display: 'flex',
          '--flexBasis': 'auto',
          '--flexFr': 0,
          '> *': {
            boxSizing: 'border-box',
            flex: '0 0 auto',
            position: 'relative',
            // minHeight: axis === 'x' ? 0 : undefined,
            // minWidth: axis === 'y' ? 0 : undefined,
          },
          alignItems: align,
          justifyContent: justify,
          gap,
          variants: {
            axis: {
              x: {
                flexDirection: 'row',
              },
              y: {
                flexDirection: 'column',
              },
            },
          },
        })({ axis }),
        className,
      )}
      {...props}
    />
  );
};

export const Gap = ({
  axis = 'x',
  gap = 0,
  ...props
}: { axis?: Axis; gap: PropertyValue<'height'> } & DefaultProps) => (
  <Box css={{ height: axis === 'y' ? gap : '100%', width: axis === 'x' ? gap : '100%' }} {...props} />
);

export const Fluid = ({ axis, css, ...props }: { axis?: Axis } & DefaultProps) => (
  <Box
    css={mergeCss(
      {
        flexGrow: '1',
        '> *': {
          height: axis === 'y' ? '100%' : undefined,
          width: axis === 'x' ? '100%' : undefined,
        },
      },
      css,
    )}
    {...props}
  />
);
