import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { Contract, LocationDTO, SectionDTO } from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

type SectionWithLocation = SectionDTO & { location: LocationDTO };

export const sectionsApi = createApi({
  reducerPath: 'sections',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
  }),
  tagTypes: ['Sections'],
  endpoints: (builder) => ({
    getAllParkSectionsByContractId: builder.query<SectionWithLocation[], Contract['id']>({
      query: (id) => `/api/sections/contract/${id}`,
      providesTags: ['Sections'],
    }),
  }),
});

export const { endpoints, middleware, reducer, reducerPath, useGetAllParkSectionsByContractIdQuery } = sectionsApi;
