import { Col, Grid, IconTrashAlt02, Row, Table, TableColumn, TableDefaultCell, TableDefaultCellProps } from '@rcl/rcl';
import { SuperAdmin, User } from 'markets-lib';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { useGetAllQuery as useGetAllQueryAdmins } from '../../store/admins';
import { useGetAllQuery as useGetAllQueryUsers, useGetMeQuery, useRemoveMutation } from '../../store/users';
import { GraphUsersSelect } from '../GraphUsersSelect';
import { Page } from '../Page';

const UsersTableCellDetails = ({
  onDeleteUser,
  value: id,
  ...props
}: TableDefaultCellProps<User> & {
  onDeleteUser?: (id: User['id']) => void;
}) => {
  const onClickHandler = useCallback(() => {
    onDeleteUser?.(id);
  }, [id, onDeleteUser]);

  return (
    <TableDefaultCell alignHorizontal='right' {...props}>
      <IconTrashAlt02 css={{ cursor: 'pointer' }} onClick={onClickHandler} />
    </TableDefaultCell>
  );
};

export const UsersTable = ({
  data,
  isLoading,
  isRowSelectable = false,
  noHeader = false,
  onDeleteUser,
}: {
  data: User[];
  isLoading?: boolean;
  isRowSelectable?: boolean;
  noHeader?: boolean;
  onDeleteUser?: (id: User['id']) => void;
}) => {
  const { t } = useTranslation();

  const cssNoHeader = { margin: 0 };
  const cssProps = !noHeader
    ? { marginBottom: '5rem', padding: '0 12px' }
    : isRowSelectable
    ? { ...cssNoHeader, 'td:first-of-type': { width: 30 } }
    : cssNoHeader;

  const contractColumns = useMemo(() => {
    const columns = [
      {
        Header: t('usersPage.usersTable'),
        accessor: ({ name }: User) => name,
        alignHorizontal: 'left',
        isSortable: true,
        Cell: (props: TableDefaultCellProps<User>) => {
          const user = props.row.original;

          return <TableDefaultCell title={`${user.name}`} {...props} />;
        },
      },
    ] as TableColumn<User>[];

    columns.push({
      Header: 'Details',
      alignHorizontal: 'right',
      headerHidden: true,
      accessor: ({ id }: User) => id,
      Cell: (props: TableDefaultCellProps<User>) => <UsersTableCellDetails onDeleteUser={onDeleteUser} {...props} />,
    });

    return columns;
  }, [t, onDeleteUser]);

  return (
    <Table
      columns={contractColumns}
      css={cssProps}
      data={data}
      fontSize='md'
      isLoading={isLoading}
      isRowSelectable={isRowSelectable}
      noDataMessage={t('usersPage.noUsers')}
      noHeader={noHeader}
    />
  );
};

export const AdminsTable = ({ data, isLoading }: { data: SuperAdmin[]; isLoading: boolean }) => {
  const { t } = useTranslation();

  const cssProps = { marginBottom: '0.5rem', padding: '0 12px' };

  const contractColumns = useMemo(() => {
    const columns = [
      {
        Header: t('usersPage.adminsTable'),
        accessor: ({ name }: User) => name,
        alignHorizontal: 'left',
        isSortable: true,
        Cell: (props: TableDefaultCellProps<User>) => {
          const user = props.row.original;

          return <TableDefaultCell title={`${user.name}`} {...props} />;
        },
      },
    ] as TableColumn<User>[];

    return columns;
  }, [t]);

  return (
    <Table
      columns={contractColumns}
      css={cssProps}
      data={data}
      fontSize='md'
      isLoading={isLoading}
      noDataMessage={t('usersPage.noUsers')}
    />
  );
};

export const UsersPage = () => {
  const { data: users, isFetching: isFetchingUsers } = useGetAllQueryUsers();
  const { data: admins, isFetching: isFetchingAdmins } = useGetAllQueryAdmins();
  const { data: me, isFetching: isFetchingMe } = useGetMeQuery();
  const [deleteUser, { isLoading: isDeleting }] = useRemoveMutation();
  const { t } = useTranslation();

  const isLoading = isDeleting || isFetchingUsers;

  return isFetchingMe || me?.isAdmin ? (
    <Page>
      <Grid colSpan={12}>
        <Row>
          <Col span={{ xs: 12, md: 9, lg: 8 }}>
            <GraphUsersSelect label={t('usersPage.findUser')} />
          </Col>
        </Row>

        {admins && (
          <Row>
            <Col span={{ xs: 12, md: 9, lg: 8 }}>
              <AdminsTable data={admins} isLoading={isFetchingAdmins} />
            </Col>
          </Row>
        )}

        {users && (
          <Row>
            <Col span={{ xs: 12, md: 9, lg: 8 }}>
              <UsersTable data={users} isLoading={isLoading} onDeleteUser={deleteUser} />
            </Col>
          </Row>
        )}
      </Grid>
    </Page>
  ) : (
    <Redirect to='/' />
  );
};
