import { CSS } from '@stitches/react';
import classNames from 'classnames';
import _ from 'lodash';
import { forwardRef } from 'react';

import { css, keyframes, PropertyValue } from '../stitches.config';
import { theme } from '../theme';
import { HTMLTag } from '../types';
import { Box, BoxProps } from './Box';

export type SpinnerProps<C extends HTMLTag = 'div'> = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  borderWidth?: PropertyValue<'borderWidth'>;
} & BoxProps<C>;

const spinnerRing = keyframes({
  '0%': { transform: 'rotate(0)' },
  '100%': { transform: 'rotate(360deg)' },
});

export const Spinner = forwardRef<HTMLElement, SpinnerProps<HTMLTag>>(function Spinner(
  { as = 'div', borderWidth, size = 'lg', ...props },
  ref,
) {
  return (
    <Box
      as={as}
      className={classNames(
        css({
          variants: {
            size: _.zipObject(
              ['sm', 'md', 'lg', 'xl'],
              _.map<'sm' | 'md' | 'lg' | 'xl', CSS>(['sm', 'md', 'lg', 'xl'], (size) => ({
                width: `calc(${theme.fontSizes[size]} * 4)`,
                height: `calc(${theme.fontSizes[size]} * 4)`,
              })),
            ),
          },
        })({ size }),
      )}
      ref={ref}
      {...props}
    >
      <Box
        css={{
          width: '80%',
          height: '80%',
          border: `${
            borderWidth || (size === 'sm' && '5px') || (size === 'md' && '6px') || '8px'
          } solid rgb(2, 129, 193)`,
          borderRadius: '50%',
          borderColor: '#cef transparent transparent',
          boxSizing: 'border-box',
          animation: `${spinnerRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,

          '&:nth-child(1)': { animationDelay: '-0.45s' },
          '&:nth-child(2)': { animationDelay: '-0.3s' },
          '&:nth-child(3)': { animationDelay: '-0.15s' },
        }}
      />
    </Box>
  );
});
