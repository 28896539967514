"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyStagingDTO = exports.CompanyStaging = void 0;
var zod_1 = require("zod");
var util_1 = require("../util");
var company_1 = require("./company");
exports.CompanyStaging = zod_1.z.object({
    id: util_1.uuid.optional(),
    name: zod_1.z.string().nonempty('customerForm.errors.empty'),
    addressStreetName: zod_1.z.string().nonempty('customerForm.errors.empty'),
    addressHouseNumber: zod_1.z.string().nonempty('customerForm.errors.empty'),
    addressZipCode: zod_1.z
        .string()
        .nonempty('customerForm.errors.empty')
        .regex(new RegExp(/^[0-9]{5}$/), 'customerForm.errors.digitsLength'),
    addressCity: zod_1.z.string().nonempty('customerForm.errors.empty'),
    contactTitle: zod_1.z.enum(company_1.titles),
    contactFirstName: zod_1.z.string().nonempty('customerForm.errors.empty'),
    contactLastName: zod_1.z.string().nonempty('customerForm.errors.empty'),
    contactPhone: zod_1.z
        .string()
        .nonempty('customerForm.errors.empty')
        // eslint-disable-next-line no-useless-escape
        .regex(new RegExp(/^(\(?([\d \-\)\–\+\/\(]+)\)?([ .\-–\/]?)([\d]+))$/), 'customerForm.errors.noLetters'),
    contactEmail: zod_1.z.string().regex(new RegExp(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})((,| ,|, | , |){1}[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/), // https://regex101.com/r/6l030N/1
    'customerForm.errors.email'),
    contactApiAddress: zod_1.z.string().nullable().optional(),
    contactFax: zod_1.z.string().nullable().optional(),
});
exports.CompanyStagingDTO = zod_1.z.object({
    id: util_1.uuid.optional(),
    name: zod_1.z.string().nonempty('customerForm.errors.empty'),
    address: company_1.Address,
    contact: company_1.Contact,
});
